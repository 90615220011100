define(function(require, exports, module) {


module.exports = `Piano 1
[176,0,0]
[176,32,122]
[192,0]
[240,67,115,127,75,17,0,69,127,247]
[240,67,16,76,2,1,0,1,17,247]
[176,91,12]
[240,67,16,76,2,1,64,0,0,247]
[240,67,16,76,8,0,17,127,247]
[176,94,0]
[240,67,115,127,75,17,0,69,0,247]

Piano 2
[176,0,0]
[176,32,112]
[192,0]
[240,67,115,127,75,17,0,69,127,247]
[240,67,16,76,2,1,0,1,17,247]
[176,91,25]
[240,67,16,76,2,1,64,0,0,247]
[240,67,16,76,8,0,17,127,247]
[176,94,0]
[240,67,115,127,75,17,0,69,0,247]
`.match(/\[(.*)\]/g).map(m=>JSON.parse(m));
`
E Piano 1
[176,0,0]
[176,32,122]
[192,5]
[240,67,115,127,75,17,0,69,127,247]
[240,67,16,76,2,1,0,1,16,247]
[176,91,25]
[240,67,16,76,2,1,64,66,16,247]
[240,67,16,76,8,0,17,124,247]
[176,94,20]
[240,67,115,127,75,17,0,69,0,247]

E Piano 2
[176,0,0]
[176,32,122]
[192,4]
[240,67,115,127,75,17,0,69,127,247]
[240,67,16,76,2,1,0,1,16,247]
[176,91,25]
[240,67,16,76,2,1,64,66,16,247]
[240,67,16,76,8,0,17,119,247]
[176,94,25]
[240,67,115,127,75,17,0,69,0,247]

C Organ 1
[176,0,0]
[176,32,123]
[192,19]
[240,67,115,127,75,17,0,69,127,247]
[240,67,16,76,2,1,0,1,17,247]
[176,91,63]
[240,67,16,76,2,1,64,0,0,247]
[240,67,16,76,8,0,17,127,247]
[176,94,0]
[240,67,115,127,75,17,0,69,0,247]

C Organ 2
[176,0,0]
[176,32,122]
[192,19]
[240,67,115,127,75,17,0,69,127,247]
[240,67,16,76,2,1,0,1,17,247]
[176,91,76]
[240,67,16,76,2,1,64,0,0,247]
[240,67,16,76,8,0,17,127,247]
[176,94,0]
[240,67,115,127,75,17,0,69,0,247]

Strings
[176,0,0]
[176,32,122]
[192,48]
[240,67,115,127,75,17,0,69,127,247]
[240,67,16,76,2,1,0,1,17,247]
[176,91,76]
[240,67,16,76,2,1,64,0,0,247]
[240,67,16,76,8,0,17,127,247]
[176,94,0]
[240,67,115,127,75,17,0,69,0,247]`

})
