define(function(require, exports, module) {
const Action = require('src/actions/Action.js')
const MIDI_OUT = require('src/MIDI_OUT.js')
const messageBar = require('src/messageBar.js');

module.exports = new Action({
    name:"undo",type:"button",
    title: "Delete most recent loop, or cancel any currently recording loops.",
	priority:99,
    action:function(value, doubleTap) {
		var deletedLoopChannel = loopController.undoButton();
		// messageBar.setMessage('deleted loop',{channel:deletedLoopChannel})
		if (doubleTap===3) MIDI_OUT.send([0xB0, 0x7B, 0x00]); // reset all note states
    }
})
})
