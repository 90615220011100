define(function(require, exports, module) {
    var MIDI_OUT = require('src/MIDI_OUT.js')
    var Action = require('src/actions/Action.js')
    /* VOLUME SYSEX FORMAT:
    0xF0  SysEx
    0x7F  Realtime
    0x7F  The SysEx channel. Could be from 0x00 to 0x7F.
          Here we set it to "disregard channel".
    0x04  Sub-ID -- Device Control
    0x01  Sub-ID2 -- Master Volume
    0xLL  Bits 0 to 6 of a 14-bit volume
    0xMM  Bits 7 to 13 of a 14-bit volume
    0xF7  End of SysEx
    */
    module.exports = new Action({
        name:"volume",type:"slider",min:0,max:16383, startValue:16383,
        title:"Change the volume of the connected MIDI device.",
        priority:71,
        action:function(value){
            MIDI_OUT.send([0xF0,0x7F,0x7F,0x04,0x01,(this.value>>7)&0b1111111,(this.value>>7)&0b1111111,0xF7])
        },update:function() {
            this.text.textContent = "Vol:"+this.btn.value;
        }
    })
})

        // }, startAction: function(){
        //     let saved = parseInt(localStorage["mainVolume"]);
        //     this.btn.value = isNaN(saved) ? this.startValue : saved;
        //     this.update()
        // }
