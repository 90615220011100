"use strict";
define(function(require, exports, module) {
// TODO:
// DONe: auto deploy
// DONE: scrolling with shift key!!
// DONE: DebugMidiLog should be async or it will delay notes
// now speed is bugged (FIXED)
// some keyboards send on 2 different channels at once, so need setting for each channel
// DONE ^^: use multiple inputs - can select only one channel
// DONE: bug selecting tracks on channel one sometimes doesn't work??
// minor-bug: when changing channel, completed loops don't also update to new channel (but maybe don't need to?)
// TODO on midiAccess statechange we need to update available outputs
//
// DONE: favourite inputs (replaced with simple 'save current')
// button highlighting should be done in css (so different colours can be specified)
// DONe: shift button take into account initial channel selection?
// fix media-min width checking (should be size of available screen excluding sidebars)
// DONE: double taps (and they're sick)
// overdub when shift + loop button
// more simple option for shift key
// NEW
// - fix loop vs rotary colors
// - track 6 needs to default 9
// - make it channels 1-16 and track 1-7
// - make 0.244
/// keyboard sticky at bottom broken
// FatBoy
// delayedLoop bug - need to hold
// live streaming to other browsers
// SETTINGS STORES




var options = require('src/options.js');
const MIDI_OUT = require('src/MIDI_OUT.js');
const MIDI_IN = require('src/MIDI_IN.js');
const syncManager = require('src/syncManager.js');
var timeClockTesterSpeed = 1.0;
var counterIdx = 0;
// console.log(performance.now());
;if (0 && !options.isProd) {(function timeClockTester(){
     MIDI_IN && MIDI_IN.onmidimessage && MIDI_IN.onmidimessage({data:[248],timeStamp: performance.now()});
    if (counterIdx++ % 24 == 0) MIDI_IN && MIDI_IN.onmidimessage && MIDI_IN.onmidimessage({data:[144,70,5],timeStamp: performance.now()});
    // console.log(this)
    if (12000>performance.now()>10000) {
        timeClockTesterSpeed *= 0.99
        if ( timeClockTesterSpeed >0.5) {
            console.log("timeClockTesterSpeed *= 0.99", timeClockTesterSpeed)
        }
    }
    setTimeout(timeClockTester, 20*timeClockTesterSpeed)
})()}
MIDI_IN.onmidimessage = onMIDIMessage
const mapping = require('src/mapping.js')
// const MidiWriter = require('src/MidiWriter.js');
require('src/utils/scrollbarDetector.js');
var saveDeviceName = require('src/saveDeviceName.js')
var saveTrackList = require('src/saveTrackList.js')
// UTILS:
const mod = require('src/mod.js')
const createElement = require('src/utils/createElement.js');
const createSVG = require('src/utils/createSVG.js');

// STATE
const outputState = require('src/outputState.js')

// LOWER KEYBOARD
require('src/drawKeyboard.js')


var actionsController = require('src/actionsController.js');
var actionsLoader = require('src/actionsLoader.js');
const doWhenDOMLoaded = require('src/utils/doWhenDOMLoaded.js')


var LoopController = require('src/LoopController.js')
var loopController = new LoopController(MIDI_OUT);
window.loopController = loopController;
if (options.addObjectsToWindow) {
    window.MIDI_OUT = MIDI_OUT;
    window.MIDI_IN = MIDI_IN;
    window.actionsController = actionsController;
}


var MIDI_INTERCEPTOR = require('src/MIDI_INTERCEPTOR.js')
var MIDI_VELOCICHANGER = require('src/MIDI_VELOCICHANGER.js')


0 && setInterval(()=>{
    MIDI_INTERCEPTOR.note(0, false, 0, 0, timeShifter.now())
},65)

var testTimer = 0;
document.addEventListener('testMIDIMessage',evt=>{
    MIDI_OUT.send(evt.detail)
})
document.addEventListener('noteClicked',evt=>{
    processNoteMessage(performance.now(),evt.detail)
})

window.test_speedChange = function(){
    var idx = 0;


    var noteOnAndOff = [
        ()=>loopController.note(Note.create(144,100,100)),
        ()=>loopController.note(Note.create(128,100,100)),
        ]
    var doNothing = Array(6).fill(()=>{})
    var thingsToDo = [
    ()=>console.log('testing speed'),
    ()=>loopController.loopButton()]
        .concat(noteOnAndOff)
        .concat(noteOnAndOff)
        .concat(doNothing)
        .concat([()=>loopController.loopButton()])
    thingsToDo = thingsToDo.concat(thingsToDo)
    // console.log(thingsToDo)
    thingsToDo[13] = ()=>{actionsController.byName('speed').action(7)}
    thingsToDo.push(()=>{actionsController.byName('speed').action(127)})
    thingsToDo.push(()=>{actionsController.byName('undo').action()})
    thingsToDo.push(()=>{actionsController.byName('undo').action()})
    // thingsToDo(7,0,()=>{timeShifter.setWarp(0.5)})
    var delayDoingNextThing = ()=>{
        // console.log("speedtest", idx)
        setTimeout(()=>{
            // console.log('thingss', idx, thingsToDo[idx])
            if (thingsToDo.length>idx) {
                thingsToDo[idx]();
                idx++;
                delayDoingNextThing()
            }
        },200)
    }
    doWhenDOMLoaded(delayDoingNextThing);
}
window.test_bankSelect = function() {
    var bankSelect = require('src/bankSelect.js')
    setTimeout(()=>{
        // console.log("starting")
        bankSelect.forEach(m=>{
            onMIDIMessage({data:m, timeStamp:performance.now()})
        })
    },200)
}
window.test_loopButton = function test_loopButton(){
    var i = 21+outputState.trackHeldDown.length;
    var n_i_range = 60
    var n_i = n_i_range;
    var createNotesForever = setInterval(()=>{
        loopController.note(Note.create(144, n_i, 100))
        n_i = ((n_i-n_i_range+2) % n_i_range) + n_i_range
        // console.log(n_i)
    }, 50)
    var interval = 500;
    while(i--) {
        let l = i;
        if (i<21)
            setTimeout(()=>{if (l>16) n_i_range=20; var act=l%3==0 ?  ('track'+((l/3)|0)) : 'loop'; var act = act;let a = actionsController.byName(act); 0 && console.log(act,a);a.action()},20+l*interval)
            // setTimeout(()=>{var act=l%3==0 ?  ('track'+((l/3)|0)) : 'loop'; var act = act;let a = actionsController.byName(act); 0 && console.log(act,a);a.action()},20+l*interval)
        // else
            // setTimeout(()=>{console.log('track'+(l-21));actionsController.byName('track'+(l-21)).actionUp()},20+l*interval)
        else if (i<24)
            setTimeout(()=>{console.log('undo',i);actionsController.byName('undo').action()},20+l*interval)
        else if (i<25)
            setTimeout(()=>{clearInterval(createNotesForever)},20+l*interval)
        else
            setTimeout(()=>{console.log('redo',i);actionsController.byName('redo').action()},interval*2+l*interval)
    }

}
if (options.test_bankSelect) {window.test_bankSelect() } 
if (options.test_speedChange) {window.test_speedChange() }
if (options.test_loopButton) {window.test_loopButton() }
//onKeyDown({keyCode:(i%2==0) ? 32 : [81,87,69,82,84,89][i%5],preventDefault:()=>{},stopPropagation:()=>{}})}
// LOOP AND UNDO
// space key is always loop button, backspace always undo
document.addEventListener("keydown", onKeyDown, false);
// for now negative is shift+number
// options: shift+O, debug: shift+D, mapping: shift+M, change instrument: +/_
var keyMap = {'pause':-80, 'map':-77,'options':-79,'debug':-68,'nextInstrument':-187,'prevInstrument':-189,'undo':8,'redo':-8,'loop':32,'delayedLoop':-32, 'record':-83}
function onKeyDown(e) {

    // console.log(e.keyCode)
    // console.log(e.code)
    for(var k in keyMap) {
        if (Math.abs(keyMap[k])==e.keyCode && (keyMap[k]>=0 ^ e.shiftKey==true)) {
            e.preventDefault() // prevent native browser event
            e.stopPropagation() // prevent event passing to other keyboard event
            if (e.repeat!==true) {
                // console.log(k) // logs the action taken
                actionsController.byName(k).action(undefined,undefined,e.timeStamp);
                return false;
            }
        }
    }

}

if (0) {
    // ideal syntax for dealing with MIDI streams
    var inputStream = new NoteStream()
        .filter(note=>Note.isTiming(note) || Note.isSysex(note))
        .filter(
            note=>Note.isControlChange(note) && (Note.data1(note)===0 || Note.data2(note)===32) , // better
            note=>{ MIDI_OUT.send( Note.toArray(note) ); })
        .connect(MIDI_INTERCEPTOR)
}
function onMIDIMessage(event) {
    // console.log(event)

    var data = event.data;
    window.lastMidi = data;
    var timeStamp = event.timeStamp;
    var echoed = false;
    var status = data[0]&0xF0;
    // console.log(event.data, options.optimizeNotes, data.length===3, (data[0]>=128 && data[0]<=144), 
    //     !actionsController.checkTrigger(data[1]),
    //     options.noteChannel===-1 ,options.noteChannel===(data[0]&0x0F))

    // ONLY NOTE ON (note offs must be changed incase channel changes)
    if (options.optimizeNotes && data.length===3 && status===144 && 
        !actionsController.checkTrigger(data[1]) && // is it an action note
        (options.noteChannel===-1 || options.noteChannel===(data[0]&0x0F)) // check for 'no note on this channel' option
        ) {
        // fast track for notes to reduce latency
        // this is definitely premature optimization
        // console.log('fast trakd!',(data[0]&0xF0) + loopController.currChan,
        //     Math.min(127, Math.max(0,data[1] + loopController.keymod[loopController.currTrack])),
        //     data[2])
        var noteVal = Math.min(127, Math.max(0,data[1] + loopController.keymod[loopController.currTrack]))

        if ((status)==144) MIDI_OUT.noteOn(loopController.currChan, noteVal,data[2])
        // if ((status)==128) MIDI_OUT.noteOff(loopController.currChan, noteVal,data[2])
        echoed = true; // make sure it doesn't get echoed again
    }
    
    if (data.length===1 && (data[0]===248 || data[0]===254)) {
        if (data[0]===248) {
            syncManager.onClockMessage(timeStamp);
        }
        return
    }

    if (data.length!==3) {
        console.error('onMIDIMessage data length not 3', data, data.length)
        if (data.length>3 && options.forwardUnknownMidi) MIDI_OUT.send(data); // forward on long messages (probably dodgy custom sysEx for changing instrument)

        if (data.length==2 && (data[0]===192 || data[0]===208)) // Program change and Channel Pressure (After-touch) messages are allowed to be 2 bytes only
            data = [data[0],data[1], 128]
        else
            return;
    }

    var note = Note.standardize(Note.createFromArray(data)).setTimewarp(1);
    // console.log(event.actionDisabled)
    if (event.actionDisabled) note = note.setThru(true)
    if (echoed) note = note.setEchoed(true)
    processNoteMessage(timeStamp, note)
}


window.processNoteMessage = function processNoteMessage(timeStamp, note) {
    window.lastNote = note;
    // console.log(note.print())
    // console.log(...data,Note.print(note))
    if (options.logMidi && Note.status(note)!==248 && Note.status(note)!==254) {console.log("["+note.print()+"]");}


    // is it part of a MSB + LSB + Program-change series?
    if (Note.status(note)===176 && (Note.data1(note)===0 || Note.data1(note)===32)) {
         if (!options.programChange) return;
        // data1 32 = LSB
        // data1 0 = MSB
        console.log("LSB or MSB", Note.data1(note), Note.data2(note))
        MIDI_OUT.send(
            Note.toArray(Note.setChannel(note,loopController.currChan))
            )
        return
    }
    if (Note.status(note)===192) {
         if (!options.programChange) return;
        console.log("program change", Note.data1(note))
        // data[0] = status+loopController.currChan
        note = Note.setChannel(note,loopController.currChan)
        MIDI_OUT.send(
            Note.toArray(note)
            )
        // MIDI_OUT.send(data)
        $('#instrument-select').selectedIndex =
            Math.max(0,Math.min($('#instrument-select').options.length-1,Note.data1(note)+1))
        $('#instrument-select').onchange();
        return
    }
    if (note.isNoteOn() || note.isNoteOff())
        MIDI_INTERCEPTOR.note(note,timeStamp);
    else if (Note.status(note)!==240)
        MIDI_INTERCEPTOR.non_note(note, timeStamp);

}

window.testCC = function(){ 
    setInterval(()=>{
        onMIDIMessage({data:[176,((Math.random())*127)|0,(Math.random()*127)|0],timeStamp:performance.now()})
        onMIDIMessage({data:[176,1,(Math.random()*127)|0],timeStamp:performance.now()})
    }, 100)
}




var MIDI_ACCESS = null;
// keep track of inputs seperately so we can add newly plugged in devices
window.MIDI_INPUTS = new Map();
window.MIDI_OUTPUTS = new Map();
function setupMIDI() {

    if (options.useMidi) {
        // MIDI INPUT
        // console.log("in main",navigator.requestMIDIAccess)
        if (navigator.requestMIDIAccess) {
            return navigator
                    .requestMIDIAccess({sysex:true})
                    .then(function(midiAccess) {
                window.MIDI_ACCESS = MIDI_ACCESS = midiAccess;
                // console.log(MIDI_ACCESS)
                midiAccess.onstatechange = function(e) {
                    // these can be new ports (pluggin in midi device) so need to keep track of MIDI_/IN/OUT/PUTS ourselves
                    // console.log('MIDI_ACCESS change',e.type, e.port.name, e.port.state, e.port.connection,":",e)
                    switch(e.port.type) {
                        case "output":
                            var portNotSeenBefore = MIDI_OUTPUTS.has(e.port.id)===false;
                            MIDI_OUTPUTS.set(e.port.id,e.port)
                            if (portNotSeenBefore) setupOutputOptions(e.port.id);
                            break;
                        case "input":
                            var portNotSeenBefore = MIDI_INPUTS.has(e.port.id)===false;
                            MIDI_INPUTS.set(e.port.id,e.port)
                            if (portNotSeenBefore) setupInputOptions(e.port.id);
                            break;
                        default:
                            console.error("port change with unusual type:", e.port.type)
                            break;
                    }
                }
                MIDI_INPUTS = new Map(midiAccess.inputs);
                MIDI_OUTPUTS = new Map(midiAccess.outputs);

                setupInputOptions();
                setupOutputOptions();

                $('#midi-in-select').dispatchEvent(new Event('change'))
                $('#midi-out-select').dispatchEvent(new Event('change'))
            }, function errHandler(err){
                console.error("err opening MIDI input/output:", err)
                $('#midi-select-message').innerHTML = 'Error: This site has not been granted access to your MIDI devices'
            });
        } else {
            alert("No MIDI support in your browser. MIDI is currently only supported in Chrome and Opera browsers.");
            return Promise.resolve()
        }

    }
}


function setupInputOptions(initial_id) {
    var $in_select = $('#midi-in-select')
    var initial_value = initial_id===undefined ? $in_select.getValue() : initial_id;
    $in_select.clear()
    // loop over all available inputs
    MIDI_INPUTS.forEach(input=>{
        // console.log("input:", input)
        $in_select.addOption(
            input.name + ((input.state==="disconnected" || input.connection==="pending") ? "(!)" : ""),
            {type:'midi-input',value:input.id, name:input.name})
    })

    if (window.localStorage) {
        if (localStorage.getItem("save-input-name")) {
            var saved_input_name = localStorage.getItem("save-input-name");
            MIDI_INPUTS.forEach(input=>{
                if (input.name==saved_input_name) {
                    console.log("matched input:", input, Array.from($in_select.options).map(i=>i.getAttribute('name')))
                    Array.from($in_select.options).find(i=>i.getAttribute('name')==input.name).selected = true;
                }
            })
        }
    }
    // console.log("initial_value",initial_value)
    if (initial_value) {
        $in_select.setByValue(initial_value)
        $in_select.dispatchEvent(new Event('change'))
    }
}
let highlight = require('src/utils/highlight.js')
function setupOutputOptions(initial_id) {
    var $out_select = $('#midi-out-select')
    var initial_value = initial_id===undefined ? $out_select.getValue() : initial_id;

    // loop over all available outputs
    $out_select.clear()
    MIDI_OUTPUTS.forEach(output=>{
        // console.log("output",output)
        var name = output.name;
        // if (output.state==="disconnected") name+="(!)"
        $out_select.addOption(
            output.name + ((output.state==="disconnected" || output.connection==="pending") ? "(!)" : ""),
            {type: 'midi-output', value: output.id, name: output.name})
    })
    if (window.localStorage) {
        if (localStorage.getItem("save-output-name")) {
            var saved_output_name = localStorage.getItem("save-output-name");
            MIDI_OUTPUTS.forEach(output=>{
                if (output.name==saved_output_name) {
                    Array.from($out_select.options).find(i=>i.getAttribute('name')==output.name).selected = true;
                }
            })
        }
    }
    highlight('white',$out_select)
    // console.log("initial_value",initial_value)
    if (initial_value) {
        $out_select.setByValue(initial_value)
        $('#midi-out-select').dispatchEvent(new Event('change'))
    }
}
// ELEMENTS

function bindHTML() {
    // console.log("inital value:",$('#midi-out-select').getValue());
    ;[[MIDI_OUT,$('#midi-out-select'),'out'],[MIDI_IN,$('#midi-in-select'),'in']].forEach(tuple=>{
        var InputOrOutput = tuple[0],selectorElement = tuple[1], type = tuple[2];
        InputOrOutput.status.subscribe(newStatus=>{
            // console.log(type, "STATUS", newStatus)
            // console.log("THING",type, newStatus)
            // var selectorElement = $('#midi-out-select')
            // set to defaults
            // selectorElement.removeAttribute('disabled')
            // console.log(type,'set',newStatus)
            switch(newStatus) {
                case 'connected':
                    selectorElement.classList.add('connected')
                    selectorElement.classList.remove('connectionPending', 'connectionError')
                    break;
                case 'connecting':
                    selectorElement.classList.add('connectionPending')
                    selectorElement.classList.remove('connected', 'connectionError')
                    // selectorElement.setAttribute('disabled',true)
                    break;
                case 'error':
                    selectorElement.classList.add('connectionError')
                    selectorElement.classList.remove('connectionPending', 'connected')
                    break;
                default:
                    selectorElement.classList.remove('connected','connectionPending', 'connected')
                    break;
            }
            // console.log('connection:',selectorElement.id)
            // console.log('connection:', newStatus, selectorElement.classList.value)
        })
    })
    $('#midi-out-select').addEventListener('change',function() {
        var value = this.getValue();
        setTimeout(()=>{
            if (value)
                MIDI_OUT.openOutput(value)
            else
                console.error('no MIDI outputs available')
        })
    },{passive:true})
    $('#midi-in-select').addEventListener('change',function() {
        var value = this.getValue();
        setTimeout(()=>{
            if (value)
                MIDI_IN.openInput(value)
            else
                console.error('no MIDI outputs available')
        })
    },{passive:true})


    setupMIDI();
    (()=>{
        // self contained thing to just add .hideWhenSmall500 class
        var prevHide;
        var css = document.createElement("style");
        css.type = "text/css"; 
        document.body.appendChild(css);

        var checkResizeChange = ()=>{
            var contentWidth = $('#content').clientWidth;
            var hide = [400,500,520, 600, 710, 9999999999999].find(w=>w>contentWidth)
            if (hide!==prevHide) {
                css.innerHTML = `
                .hide710 {display:${hide<=710 ? 'none' : 'inherit'};}
                .hide600 {display:${hide<=600 ? 'none' : 'inherit'};}
                .hide520 {display:${hide<=520 ? 'none' : 'inherit'};}
                .hide500 {display:${hide<=500 ? 'none' : 'inherit'};}
                .hide400 {display:${hide<=400 ? 'none' : 'inherit'};}

                `;
            }
            prevHide = hide;
        }
        window.addEventListener('resize',checkResizeChange)
        checkResizeChange();
    })()
}
doWhenDOMLoaded(bindHTML);

var focusElement = require('src/utils/focusElement.js');
})
