define(function(require, exports, module) {
	module.exports = (function createVirtualKeyboard() {
		// setInterval(()=>{
		// 	send(248)
		// }, 20)
	    // this dict is so holding the key down doesn't generate repeat notes
	    var keyfired = {}; 
	    var keys = require('src/utils/qwerty_keymap.js')
	    // console.log(keys)

	    var virtualLowestNote = 36;
	    var pitch_bend_val = 16000;
	    function keyToSendNote(is_on, e) {
	    	e = e || window.e;
	    	let code = (typeof e.which == "number") ? e.which : e.keyCode;
	    	// code is the new 'position independant' keyboard position so should work for AZERTY/DVORK keyboards
	    	// key is the original number-based key identifiers
	    	if (e.code!==undefined) {
	    		code = e.code
	    	}
		    	// console.log(code)
	    	if (keyfired[code]===is_on) return;
	    	keyfired[code]=is_on;
    		// console.log(is_on, e.code, e.which, e.keyCode, code)

	    	if ((code!==223 && code!=='Backtick') && (keyfired[223]==true || keyfired['Backtick']==true)) {
    	    	send([176, keys[code],is_on ? 0 : 127])
    	    	return
	    	}
	    	// console.log(keys[code])
	    	if (keys[code]!==undefined) {
	    	    if (is_on==true && keys[code]!==undefined ) {
	    	    	send([144, keys[code]+virtualLowestNote,100])
	    	    	// if (typeof virtualOut.onmidimessage === 'function') virtualOut.onmidimessage({data:[144, virtualLowestNote + , 100],timeStamp: performance.now()});
	    	    }
	    	    if (is_on==false && keys[code]!==undefined) {
	    	    	send([128, keys[code]+virtualLowestNote,0])
	    	    	// if (typeof virtualOut.onmidimessage === 'function') virtualOut.onmidimessage({data:[128, virtualLowestNote + keys[code], 100],timeStamp: performance.now()});
	    	    }
	    	}
	    	if (code==223 || code=='Backquote') {
	    		var program = is_on ? pitch_bend_val * (e.shiftKey==true ? 0 : 1) : 8000
	    		// TODO: finish makinng it smooth/ 
	            send([0xE0, program & 0x7F, program >> 7])
	    	}
	    	if (code==49 || code=='Digit1') 
	            send([0xB0 + (e.shiftKey==true ? 1 : 0) , 49, is_on ? 127 : 0])

	    }
	    var keyToSendNoteOn = keyToSendNote.bind(undefined,true)
	    var keyToSendNoteOff = keyToSendNote.bind(undefined,false)
	    var virtualOut = {
	    	// state: "connected", 
	    	// connection:"open", 
	    	id:"qwerty",
	    	name:"qwerty",
	    	type:"input",
	    	_open:function() {
			    document.addEventListener('keydown', keyToSendNoteOn)
			    document.addEventListener('keyup', keyToSendNoteOff)
		    	return Promise.resolve()
		    },
		    _close:function() {
			    document.removeEventListener('keydown', keyToSendNoteOn)
			    document.removeEventListener('keyup', keyToSendNoteOff)
		    	return Promise.resolve()
		    }
		}
		function send(midi) {
			if (midi && midi[0]!==undefined && midi[1]!==undefined && typeof virtualOut.onmidimessage === 'function') 
				virtualOut.onmidimessage({
					data:midi,
					timeStamp: performance.now()
				});
 
		}
	    return virtualOut
	})()
})