define(function(require, exports, module) {
module.exports = {
    // (OLD, NEW) STYLE CODES
    81:0,'KeyQ':0,
    50:1,'Digit2':1,
    87:2,'KeyW':2,
    51:3,'Digit3':3,
    69:4,'KeyE':4,
    82:5,'KeyR':5,
    53:6,'Digit5':6,
    84:7,'KeyT':7,
    54:8,'Digit6':8,
    89:9,'KeyY':9,
    55:10,'Digit7':10,
    85:11,'KeyU':11,
    73:12,'KeyI':12,
    57:13,'Digit9':13,
    79:14,'KeyO':14,
    48:15,'Digit0':15,
    80:16,'KeyP':16,
    219:17,'BracketLeft':17,
    // 187:18,// 'Key':18,
    221:19,'Key':19,
    220:12,'IntlBackslash':12,
    65:13,'KeyA':13,
    90:14,'KeyZ':14,
    83:15,'KeyS':15,
    88:16,'KeyX':16,
    67:17,'KeyC':17,
    70:18,'KeyF':18,
    86:19,'KeyV':19,
    71:20,'KeyG':20,
    66:21,'KeyB':21,
    72:22,'KeyH':22,
    78:23,'KeyN':23,
    77:24,'KeyM':24,
    75:25,'KeyK':25,
    188:26,'Comma':26,
    76:27,'KeyL':27,
    190:28,'Period':28,
    191:29,'Slash':29,
    192:30,'Quote':30,
    222:31,'Backslash':31,
}
})