define(function(require){
    var Action = require('src/actions/Action.js')
    var outputState = require('src/outputState.js')
    const doWhenDOMLoaded = require('src/utils/doWhenDOMLoaded.js')


    doWhenDOMLoaded(function() {

        var track_select = document.getElementById('midi-out-track-select')
        track_select.clear()
        outputState.trackChannels.forEach((_, idx) => {
            track_select.addOption((idx+1)+"",{value:idx});
        })

        var channel_select = document.getElementById('midi-out-channel-select-main')
        for(var i=0; i<16; i++) {
            channel_select.addOption( 'channel '+(i+1), {value:i});
        }

        var inst_select = document.getElementById('instrument-select')
        inst_select.clear()

        // this seems to be a perf killer - using https://stackoverflow.com/questions/32670902/create-multiple-elements
        inst_select.addMultipleOptions(Array.from(outputState.instrumentChoices))

        // track select links directly to current track
        $('#midi-out-track-select').link(outputState.currentTrack)
        // channel select needs to set the current channel
        $('#midi-out-channel-select-main').onchange = function () {
            var newChannel = parseInt(this.value);
            outputState.getCurrentChannelStore().setState(newChannel)
        }
        $('#instrument-select').onchange = function () {
            var inst = parseInt(this.value)
            outputState.getCurrentInstrumentStore().setState(parseInt(this.value))
        }
        outputState.currentChannel.subscribe(newValue=>{
            $('#midi-out-channel-select-main').linkRecieveValue(outputState.getCurrentChannelStore())
            $('#instrument-select').linkRecieveValue(outputState.getCurrentInstrumentStore())
        }, true)


    })

})
