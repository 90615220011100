define(function(require, exports, module) {
var id = 0;
var createElement = require('src/utils/createElement.js')
var mapping = require('src/mapping.js')
var options = require('src/options.js')
var ActionsController = function(actions) {
    this.actions = [];
    this.actionNames = {};
    
    // hack to make it hide advanced controls at beginning (see.. this is why I should've used react)
    this.actionNamesToHide = [...options['advancedControlsList']]
    this.takenNotes = [];

    // updateTriggers no longer neccesary??
    //this.updateTriggers()
}
// let tn = n=>n<0 ? 256 + (-n) : (n >256 ? console.error('note cant be mapped') : n);
ActionsController.prototype.byName = function(actionName) {
    return this.actionNames[actionName];
}
// updates the takenNotes array with 1 where note is used
ActionsController.prototype.updateTriggers = function() {
    this.takenNotes.fill(0)
    // console.log("MAP:",Object.keys(mapping.map))
    this.actions.forEach(a=>{
        // if (a.name && mapping.map[a.name]!==undefined) {
            // console.log("found map for:",a.name,  mapping.map[a.name])
            // a.trigger = mapping.map[a.name]
        a.trigger = undefined;
        this.setTriggerFromMapping(a)
        if (a.trigger!==undefined) {
            // can't have two with same triggers
            if (this.takenNotes[a.trigger]==1) {
                a.trigger = undefined;
                console.log("trigger taken", a.name, a.trigger);
            }
            this.takenNotes[a.trigger] = 1;

        }
    });
    console.log('updateTriggers', this.takenNotes)
}
// takes an action and gives it the correct .trigger property from the saved mapping object
ActionsController.prototype.setTriggerFromMapping = function(a) {
    if(mapping.map && mapping.map[a.name]!==undefined) {
        // console.log("mapping:",a.name,mapping.map[a.name])
        a.trigger = mapping.map[a.name]
        // console.log(a.name, mapping.map[a.name], a.trigger)
    }
    // else if (a.type=="track") {
    //     if (mapping.map.tracks[a.track]==undefined) console.error("missing track id",a, mapping.map.tracks)
    //     a.trigger = mapping.map.tracks[a.track]
    // }
    // console.log(mapping.map[a.name], a.name,a.trigger)
    // if (a.name.endsWith('Instrument')) console.log("setTRiggerFromMapping",a.name,a.trigger,mapping.map[a.name])
}

ActionsController.prototype.changeTrigger = function(action, newNote) {
    // console.log(this.takenNotes)
    if (newNote!==undefined && newNote!==null) {
        // change action's trigger to newNote
        if (action.trigger!==undefined && action.trigger==newNote) return false; // already done

        // if there was already an action listening for the trigger we remove it
        var action_being_displaced = this.getTriggeredAction(newNote);
        console.log('action being displaced',action_being_displaced)
        if (action_being_displaced) {
            action_being_displaced.trigger = undefined;
         }

        if (action.trigger!==undefined) { // if the action had a trigger before
            this.takenNotes[action.trigger] = 0; // remove old trigger
        }
        this.takenNotes[newNote] = 1;
        action.trigger = newNote;
        return action_being_displaced || false;
    } else {
        // if newNote is undefined we simply remove the actions trigger
        this.takenNotes[action.trigger] = 0;
        action.trigger = undefined;
        return false; // return false since no other actions were harmed in the execution of this function
    }
}
ActionsController.prototype.checkTrigger = function(note) {
    return !(this.takenNotes[note]!==1);
}
ActionsController.prototype.getTriggeredAction = function(note) {
    if (note===undefined || note===null) return false
    if (this.takenNotes[note]!==1) return false;
    return this.actions.find((a)=>{
            return a.trigger == note;
        });
};

ActionsController.prototype.createActionButton = function createActionButton(a){
    this.actions.push(a);
    a.id = a.id===undefined ? id++ : a.id;
    switch (a.type) {
        case 'button':
            if (!a.btn) {
                var btn = createElement('button',a.name, {class:'btn', id:'mappable'+a.id, title:a.title ? a.title : '', name:a.name})
                a.btn = btn;
                a.div = createElement('div',undefined,{class:'mappingDiv mappingDivDisplay'})
                a.div.appendChild(a.btn)
            } else {
                a.div = createElement('div',undefined,{class:'mappingDivDisplay'})
                a.btn.parentNode.insertBefore(a.div, a.btn)
                a.div.appendChild(a.btn)
            }
            if (a.actionUp) {
                a.btn.onmousedown = function(e) {
                    if (!(e.button===undefined || e.button===0)) return; // left click only (or undefined if touch?)
                    e.preventDefault()
                    a.action && a.action.bind(a)();
                    a.update && a.update();
                }
                a.btn.onmouseup = function(e) {
                    if (!(e.button===undefined || e.button===0)) return; // left click only (or undefined if touch?)
                    e.preventDefault()
                    a.actionUp && a.actionUp.bind(a)();
                    a.update && a.update();
                }

            } else {
                a.btn.onclick = function(e) {
                    if (!(e.button===undefined || e.button===0)) return; // left click only (or undefined if touch?)
                    e.preventDefault()
                    a.action && a.action.bind(a)();
                    a.update && a.update();
                }
            }
            a.btn.classList.add('clickable')
            a.mainElement = a.btn
            a.midiNumElement = a.div;
            break;
        case 'toggle':

            var toggle = document.createElement("input");
            var btn = createElement("label",undefined, {class:'btn',title:a.title ? a.title : '', name:a.name});
            var text = document.createElement("span");
            text.textContent = a.name;
            toggle.id = 'mappable'+a.id;
            btn.for = toggle.id;
            toggle.type = "checkbox";
            btn.className = 'btn';
            a.toggle = toggle;
            a.text = text;
            a.btn = btn;
            a.div = createElement('div',undefined,{class:'mappingDiv mappingDivDisplay'})
            a.div.appendChild(a.btn)
            // container.appendChild(a.div)
            btn.appendChild(toggle)
            btn.appendChild(text)
            // toggle.onfocus =function() {this.parentNode.classList.add('focused')}
            // toggle.onblur =function() {this.parentNode.classList.remove('focused')}
            a.btn.onclick = function(event) {
                if (event.target && event.target.tagName=='INPUT') return; // otherwise will fire twice
                a.action && a.action.bind(a)();
                a.update && a.update();
            }
            a.mainElement = a.btn
            a.midiNumElement = a.div;
            break;
        case 'slider-vertical':
            var slider_is_vertical = true;
        case 'slider':
            var btn = document.createElement("input");
            var label = createElement("label",undefined, {title:a.title ? a.title : '', name:a.name});
            btn.id = 'mappable'+a.id;
            btn.type = "range";
            btn.min = a.min;
            btn.max = a.max;
            btn.value = a.startValue;
            btn.classList.add('clickable')
            btn.oninput = ()=>{a.action && a.action();a.update && a.update();}
            label.for = btn.id;
            label.className = 'btn';
            a.btn = btn;
            a.label = label;
            a.div = createElement('div',undefined,{class:'mappingDiv mappingDivDisplay'})
            a.div.appendChild(a.label)
            // container.appendChild(a.div)
            label.appendChild(a.btn)
            if (!a.hideText) {
                var text = document.createElement("span");
                text.textContent = a.startValue/100+'';
                // text.style = 'display: inline-block;width:60px;margin:10px;'
                a.text = text;
                label.appendChild(text)
            }

            // btn.onfocus =function() {this.parentNode.classList.add('focused')}
            // btn.onblur =function() {this.parentNode.classList.remove('focused')}
            if (slider_is_vertical) [a.btn,a.label,a.div].map(ele=>ele.setAttribute('orient','vertical'));
            if (!slider_is_vertical && a.loopId===undefined) { // if not a loop action
                var isFirefox = typeof InstallTrigger !== 'undefined';
                a.label.style.width = isFirefox ? "270px" : "240px"
            }
            a.mainElement = a.label
            a.midiNumElement = a.div
            break;
        case 'track':
            // all good, should be hidden
            // console.error("track actin")
            break;
        default:
            console.error("action with no type:", a.type, a);
            break;
    }
    // give button DOM element desired properties
    if (a.div && (a.hidden || this.actionNamesToHide.includes(a.name))) a.toggleHidden(true)
    // if (a.customStyle) a.btn.style = a.customStyle;

    this.actionNames[a.name] = a;

    this.setTriggerFromMapping(a)
    if (a.trigger!==undefined) {
        if (this.takenNotes[a.trigger]===1) {
            console.log('trigger already taken!', a.trigger, a.name,this.getTriggeredAction(a.trigger).name,this.getTriggeredAction(a.trigger).trigger)
            // console.log(a)
            // console.log(this.getTriggeredAction(a.trigger))
            a.trigger = undefined;
        } else {
            this.takenNotes[a.trigger] = 1;
            if (mapping.map) mapping.map[name] = a.trigger;
        }
    }

    if (a.type.startsWith('slider')) {      
        a.sliderSetValue.bind(a)()      
        a.update && a.update.bind(a)()
    }
    if (a.startAction) {
        a.startAction.bind(a)();
    }
    if (options.enableDragging===true) {
        if (a.type == 'button') {
            a.btn.draggable = true;
        }
        if (a.type.startsWith('slider')) {
            a.div.draggable = true;
            console.log('gonna protect slider',a.name)
            a.btn.addEventListener('mousedown', function() {console.log('clicked slider btn'); this.parentNode.parentNode.setAttribute("draggable", false); });
            a.btn.addEventListener('mouseup', function() { this.parentNode.parentNode.setAttribute("draggable", true); });
        }
        // if (a.group!==undefined) {
        //     a.group.ondragover = allowDrop
        // }
    }
}
ActionsController.prototype.removeAction = function(action) {
    if (action.name && this.actionNames.hasOwnProperty(action.name)) {
        delete this.actionNames[action.name]
    }
    this.actions = this.actions.filter(a=>a!==action)
}

// function allowDrop(ev) {
//     console.log("allowDrop")
//     ev.preventDefault();
// }

module.exports = new ActionsController();
window.ActionsController = module.exports

})
