define(function(require, exports, module) {
    var MIDI_IN = require('src/MIDI_IN.js')
    const Action = require('src/actions/Action.js')
    module.exports = new Action({
            name:"pitch",type:"toggle",
            title:"sends pitch bend up (toggleable - for debugging)",
            priority:1,
            action:function(){this.bent = !this.bent;
                var channel = loopController.currChan;
                var program = this.bent ? 0 : 8000
                MIDI_IN.onmidimessage({data:[0xE0 + channel, program & 0x7F, program >> 7],timeStamp:performance.now()});
                this.update()
            },
            //
            // update is for when a key triggers it we might need to update html element
            update:function(){this.btn.checked = !!this.bent},
            startAction:function() {this.btn.checked = this.bent}
    })
})
