define(function(require, exports, module) {
const TRACKIE = require('../TRACKIE.js');
const Action = require('./Action.js')
module.exports = new Action({
    name:"octaveDown",type:"button",
    title: "Decrease the octave of the current channel.",
    priority:90,
    action:function() {
        // if (TRACKIE.active) {TRACKIE.octaveDownButton()}
        {console.log("octave-",loopController.changeOctave(-12));}
    }
})
})
