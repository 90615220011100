define(function(require, exports, module) {
const timeShifter = require('../TimeShifter.js')
const Action = require('./Action.js')
module.exports = new Action({
	name:"speed",type:"slider",
	title:"Change the playback speed of the loops. Warning: negative values can be wierd.",
	min:0,max:200, startValue:100,
    priority:70,
	action:function(){
	    timeShifter.warp = this.value/100;
	},update:function() {
	    this.text.textContent = "Speed:"+(this.btn.value*1.0/100).toFixed(2);
	}
	// TODO: disable
})
})
