define(function(require, exports, module) {
const Action = require('./Action.js')
const createElement = require('../utils/createElement.js')
const doWhenJSLoaded = require('../utils/doWhenJSLoaded.js')

module.exports = new Action({
    name:'help',type:"button",
    title: "Help.",
    btn:$('#helpButtonContainer'),
    isOpen:false,
    notMappable:true,
    action: function() {
    	this.isOpen = !this.isOpen;
        if (this.isOpen) {
            if (!this.modalElement) {
                this.modalElement = createElement('div',`

<div class="modal-content">
<a href='pages/help.html' target="_blank" class="newtab">&#x1F5D7;</a>
<span class="close">&times;</span>
<iframe src='pages/help.html' onload="this.height=this.contentWindow.document.body.scrollHeight;"></iframe>
</div>
`,{id:"myModal",class:"modal"}
)

                document.querySelector('#content').appendChild(this.modalElement)
                this.modalElement.onclick = ()=>{this.action()}
                this.modalElement.querySelector('.modal-content').onclick = (e)=>{e.stopPropagation()}
                this.modalElement.querySelector('.close').onclick = (e)=>{e.stopPropagation(); this.action()}
                // let iframeEl = this.modalElement.querySelector('iframe')
                // resizeIframe = (iframeEl) => {
                //     iframeEl.height = iframeEl.contentWindow.document.body.scrollHeight + "px";
                // }
                // iframeEl.onload = resizeIframe
                // this.modalElement.src = '/pages/help.html'
                // this.modalElement.style = 'position:fixed;'
            }
            this.modalElement.style.display = 'block'
        } else {
            this.modalElement.style.display = 'none'
        }
    },
	startAction() {
		// this.action()
	}
})
// doWhenJSLoaded(()=>{
//     module.exports.action()
// })
})