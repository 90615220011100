define(function(require, exports, module) {
const Action = require('src/actions/Action.js')
var actionsController = require('src/actionsController.js');
var options = require('src/options.js');

module.exports = new Action({
    name:'...',type:"button",
    title: "Show/hide additional buttons.",
    priority:0,
    action: function() {
    	this.opened = !this.opened;
    	var myParent = this.div.parentElement;
        actionsController.actions.forEach(action=>{
            if (action.div.parentElement!==myParent) return
            var priority = typeof(action.priority)==="number" ? action.priority : -Infinity;


			// if (action.div.parentElement==myParent) console.log('priority', priority, action.name)
            if (priority<this.priority && action.div.parentElement==myParent) action.toggleHidden(!this.opened)

    	})
    },
	opened:false
})
})
