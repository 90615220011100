define(function(require){
    // console.log('note')
window.Note = class Note {

    // 100 = isAction
    // 010 = noTimewarping
    // 001 =
    // action format
    // 100-(track/actionType)-data0-data1-data2
    // data1 = action number
    // data2 = value (0 = off, 1 = on, 2 = doubleTap, etc.)
    // ACTION-TYPE
    // 001 = on/off action
    // 010 = range action 0-127
    //       + data2 will then be value
    static createAction(num, type) {
        // create an action
        var n = Note.setAction(0, true)
        // set data0 to action number
        n = Note.setActionNumber(n, num);
        // set 10 in data1
        if (type == 1) n = Note.setRangeAction(n, 1)
        return n;
    }
    static setActionNumber(note, val) {
        return Note.setData0(...arguments)
    }
    static setOnAction(note,on) {
        return Note.setData1((note.data1()&0b11111110)+(0b00000001*on));
    }
    static setRangeAction(note,on) {
     return(!!on)?note|(0x200):note&~0x200
    }
    static isRangeAction(note) {
        return (Note.data1(note)&0b10)===0b10
    }
    static setActionValue (note, val) {
        return Note.setData2(...arguments)
    }
    // array may be sysEx so longer than 3?
    static standardize(note) {
        if (Note.status(note)===Note.ON && Note.data2(note)===0) return note.setStatus(Note.OFF);
        return note;
    }
    static createFromArray(arr) {
        if (!arr || !arr.length || arr.length!==3) console.error('Note from array should have 3 elements [status, data1, data2]')
        return ((arr[0]&0xFF)<<16)+((arr[1]&0xFF)<<8)+(arr[2]&0xFF)
    }
    static create(data0,data1,data2) {
        return ((data0&0xFF)<<16)+((data1&0xFF)<<8)+(data2  &0xFF);
    }
    static createNote(status, channel, data1, data2) {
        // console.log('createNote',status,channel,data1,data2)
        if (data2===undefined) {
            // assume args are status+channel, data1, data2
            return ((status&0xFF)<<16)+((channel&0xFF)<<8)+(data1&0xFF);
        } else {
            var out;
            if (status > 0x0F) {
                out = status&0xF0;
            } else {
                out = status&0x0F; out=out<<4;
            }
            out += channel&0x0F; out=out<<8;
            out += data1&0xFF; out=out<<8;
            out += data2&0xFF;
            return out
        }
    }
    static toEvent(note) {
                return {data:Note.toArray(note), timeStamp:performance.now()}
    }
    static toArray(note) {
        var status = Note.status(note);
        if (status>=244)
            return [
                ((note&0xFF0000)>>>16)|0
            ];
        else if (status===192 || status===208 || status===241 || status===243) // Program change and Channel Pressure (After-touch) messages are allowed to be 2 bytes only
            return [
                ((note&0xFF0000)>>>16)|0,
                ((note&0x00FF00)>>>8)|0
            ];
        else
            return [
               // ((note&0xF00000)>>>20)|0,
               ((note&0xFF0000)>>>16)|0,
               ((note&0x00FF00)>>>8)|0,
                (note&0x0000FF)|0
            ];
    }
    static toArray2(note) {
        return [Note.status(note),Note.channel(note),Note.data1(note),Note.data2(note)]
    }
    // static toMappingId(note) {
    //     if (Note.isControlChange(note)) {

    //     } else {
            
    //     }
    // }
    // status has a special case? yeah for anything above a pitch bend 0xE0 (on the highest channel 0x0F) there is no 'channel' only different global messages
    // status is channel indepenent for noteOn, noteOff, CC, program change, and other non global messages
    static status  (note) {return ((note&0xFF0000)>0xEF0000) ? ((note&0xFF0000)>>>16)|0 : ((note&0xF00000)>>>16)|0 }
    static channel (note) {return ((note&0x0F0000)>>>16)|0 }
    static data0   (note) {return ((note&0xFF0000)>>>16)|0 }
    static data1   (note) {return ((note&0x00FF00)>>>8)|0 }
    static data2   (note) {return (note&0x0000FF)|0 }
    static velocity(note) {return (note&0x0000FF)|0 }
    static track   (note) {return ((note&0xF000000)>>>24)|0 }
    static thru    (note) {return (note&0x10000000)!==0 ? true : false;}
    static action  (note) {return (note&0x20000000)!==0 ? true : false;}
    static timewarp(note) {return (note&0x40000000)!==0 ? true : false;}
    // this DONT WORK
    static echoed (note) {return (note&0x80000000)!==0 ? true : false;}

    static setChannel(note, channel) {return ((note&0xFFF0FFFF)+((channel&0xF)<<16))|0 } //console.log(channel,channel&0xF, note.toString(16), (note&0xFFF0FFFF).toString(16),((channel&0xF)<<16).toString(16));
    static setStatus (note, status)  {return ((note&0xFF0FFFFF)+((status&0xF0)<<16))|0 }
    static setData0  (note, data0)   {return ((note&0xFF00FFFF)+((data0&0xFF)<<16))|0 }
    static setData1  (note, data1)   {return ((note&0xFFFF00FF)+((data1&0xFF)<<8))|0 }
    static setData2  (note, data2)   {return ((note&0xFFFFFF00)+(data2&0xFF))|0 }
    static setTrack  (note, track)   {return ((note&0xF0FFFFFF)+((track&0xF)<<24))|0 }

    static isNoteOn  (note) {return Note.status(note)===144 && Note.data2(note)!==0}
    static isNoteOff (note) {return Note.status(note)===128 || (Note.status(note)===144 && Note.data2(note)===0)}
    static isNoteOnOrOff (note) {return Note.isNoteOn(note)|| Note.isNoteOff(note)}
    static isControlChange (note) {return Note.status(note)===176}
    static isProgramChange (note) {return Note.status(note)===192}

    // THRU = don't trigger any actions
    static setThru(note, thru)  {return (!!thru)?note|(0x10000000):note&~0x10000000}
    // ACTION = not a note! has been converted into an action
    static setAction(note, act) {return (!!act )?note|(0x20000000):note&~0x20000000}
    // TIMEWARP = if playback we don't use timewarp functionality
    static setTimewarp(note, tw)  {return (!!tw)?note|(0x40000000):note&~0x40000000}
    // DISPLAY = display CC-rotary
    static setEchoed(note, display)  {return (!!display)?note|(0x80000000):note&~0x80000000}

    static print  (note) {return `${Note.type(note)}-${Note.toArray(note).join(',')}` + (Note.thru(note) ? '(T)' : '')}
    static log    (note) {console.log(Note.print(note)); return note;}
    static binary (note) {return note.toString(2);}
    static hex    (note) {return note.toString(16);}

    static type   (note) {
        if (this.action(note)) {return 'action'}
        switch (this.status(note)) {
            case 144: return 'note on';
            case 128: return 'note off';
            case 160: return 'polyphonic aftertouch';
            case 176: return 'CC';
            case 192:
                switch (Note.data1(note)) {
                    case 0: return  "Bank Select (MSB)";
                    case 1: return  "Modulation Wheel or Lever (MSB)";
                    case 2: return  "Breath Controller (MSB)";
                    case 3: return  "Undefined (MSB)";
                    case 4: return  "Foot Controller (MSB)";
                    case 5: return  "Portamento Time (MSB)";
                    case 6: return  "Data Entry MSB (MSB)";
                    case 7: return  "Channel Volume (MSB)";
                    case 8: return  "Balance (MSB)";
                    case 9: return  "Undefined (MSB)";
                    case 10: return  "Pan (MSB)";
                    case 11: return  "Expression Controller (MSB)";
                    case 12: return  "Effect Control 1 (MSB)";
                    case 13: return  "Effect Control 2 (MSB)";
                    case 14:
                    case 15: return  "Undefined   (MSB)";
                    case 16: return  "General Purpose Controller 1    (MSB)";
                    case 17: return  "General Purpose Controller 2    (MSB)";
                    case 18: return  "General Purpose Controller 3    (MSB)";
                    case 19: return  "General Purpose Controller 4    (MSB)";
                    case 20: return  
                    case 21: return  
                    case 22: return  
                    case 23: return  
                    case 24: return  
                    case 25: return  
                    case 26: return  
                    case 27: return  
                    case 28: return  
                    case 29: return  
                    case 30: return  
                    case 31: return  "Undefined   (MSB)";
                    case 32: return  "Bank Select (LSB)";
                    case 33: return  "Modulation Wheel or Lever (LSB)";
                    case 34: return  "Breath Controller (LSB)";
                    case 35: return  "Undefined (LSB)";
                    case 36: return  "Foot Controller (LSB)";
                    case 37: return  "Portamento Time (LSB)";
                    case 38: return  "Data Entry (LSB)";
                    case 39: return  "Channel Volume, formerly Main Volume (LSB)";
                    case 40: return  "Balance (LSB)";
                    case 41: return  "Undefined (LSB)";
                    case 42: return  "Pan (LSB)";
                    case 43: return  "Expression Controller (LSB)";
                    case 44: return  "Effect control 1 (LSB)";
                    case 45: return  "Effect control 2 (LSB)";
                    case 46: return  "Undefined (LSB)";
                    case 47: return  "Undefined (LSB)";
                    case 48: return  "General Purpose Controller 1 (LSB)";
                    case 49: return  "General Purpose Controller 2 (LSB)";
                    case 50: return  "General Purpose Controller 3 (LSB)";
                    case 51: return  "General Purpose Controller 4 (LSB)";
                    case 52: 
                    case 53: 
                    case 54: 
                    case 55: 
                    case 56: 
                    case 57: 
                    case 58: 
                    case 59: 
                    case 60: 
                    case 61: 
                    case 62: 
                    case 63: return  "Undefined (LSB)";
                    case 64: return  "Damper Pedal on/off (Sustain)   ≤63 off, ≥64 on";
                    case 65: return  "Portamento On/Off   ≤63 off, ≥64 on";
                    case 66: return  "Sostenuto On/Off    ≤63 off, ≥64 on";
                    case 67: return  "Soft Pedal On/Off   ≤63 off, ≥64 on";
                    case 68: return  "Legato Footswitch   ≤63 Normal, ≥64 Legato ";
                    case 69: return  "Hold 2  ≤63 off, ≥64 on";
                    case 70: return  "Sound Controller 1 (default: Sound Variation) (LSB)";
                    case 71: return  "Sound Controller 2 (default: Timbre/Harmonic Intens.) (LSB)";
                    case 72: return  "Sound Controller 3 (default: Release Time) (LSB)";
                    case 73: return  "Sound Controller 4 (default: Attack Time) (LSB)";
                    case 74: return  "Sound Controller 5 (default: Brightness) (LSB)";
                    case 75: return  "Sound Controller 6 (default: Decay Time) (LSB)";
                    case 76: return  "Sound Controller 7 (default: Vibrato Rate) (LSB)";
                    case 77: return  "Sound Controller 8 (default: Vibrato Depth) (LSB)";
                    case 78: return  "Sound Controller 9 (default: Vibrato Delay) (LSB)";
                    case 79: return  "Sound Controller 10 (default undefined) (LSB)";
                    case 80: return  "General Purpose Controller 5 (LSB)";
                    case 81: return  "General Purpose Controller 6 (LSB)";
                    case 82: return  "General Purpose Controller 7 (LSB)";
                    case 83: return  "General Purpose Controller 8 (LSB)";
                    case 84: return  "Portamento Control (LSB)";
                    case 85:
                    case 86:
                    case 87: return  "Undefined";
                    case 88: return  "High Resolution Velocity Prefix (LSB)";
                    case 89:
                    case 90: return  "Undefined";
                    case 91: return  "Effects 1 Depth";
                    case 92: return  "Effects 2 Depth (formerly Tremolo Depth)";
                    case 93: return  "Effects 3 Depth";
                    case 94: return  "Effects 4 Depth (formerly Celeste [Detune] Depth)";
                    case 95: return  "Effects 5 Depth (formerly Phaser Depth)";
                    case 96: return  "Data Increment (Data Entry +1) (see MMA RP-018) N/A";
                    case 97: return  "Data Decrement (Data Entry -1) (see MMA RP-018) N/A";
                    case 98: return  "Non-Registered Parameter Number (NRPN) (LSB)";
                    case 99: return  "Non-Registered Parameter Number (NRPN) (MSB)";
                    case 100: return  "Registered Parameter Number (RPN) (LSB)";
                    case 101: return  "Registered Parameter Number (RPN) (MSB)";
                    case 102: 
                    case 103: 
                    case 104: 
                    case 105: 
                    case 106: 
                    case 107: 
                    case 108: 
                    case 109: 
                    case 110: 
                    case 111: 
                    case 112: 
                    case 113: 
                    case 114: 
                    case 115: 
                    case 116: 
                    case 117: 
                    case 118: 
                    case 119: return  "Undefined";
                    case 120: return  "[Channel Message] All Sound Off    0";
                    case 121: return  "[Channel Message] Reset All Controllers ";
                    case 122: return  "[Channel Message] Local Control On/Off 0 off, 127 on";
                    case 123: return  "[Channel Message] All Notes Off    0";
                    case 124: return  "[Channel Message] Omni Mode Off (+ all notes off)  0";
                    case 125: return  "[Channel Message] Omni Mode On (+ all notes off)   0";
                    case 126: return  "[Channel Message] Mono Mode On (+ poly off, + all notes off)   ";
                    case 127: return  "[Channel Message] Poly Mode On (+ mono off, +all notes off)";
                    default:
                        return 'program change';
                }
            case 208: return 'channel aftertouch';
            case 224: return 'pitch wheel';
            case 240:
                switch (Note.channel(note)) {
                    case 0: return 'SysEx';
                    case 1: return 'System Common';
                    case 2:
                    case 3: return 'Sys Com Song Position Pntr';
                    default: return 'SysEx (unknown)';
                }
            default:
                return 'unknown';
        }
    }
}
Note.TYPES = {
    OFF:128,
    ON:144,
    CC:176,
    CONTROL:176,
    PROGRAM:198,
    AFTERTOUCH:208,
    PITCH:224,
}
Note.ON = 144;
Note.OFF = 128;
// Note.NOTE_ON = Note.createNote(144,0,)


if (!'feel this is excessive so disabling') {
    // LAZINESS LEVEL OVER 9000 ... this adds syntax for: Note.createAnd(127,40,100).setChannelAnd(5).log()
    Object.getOwnPropertyNames(Note).forEach(method=>{
        if ((method.startsWith('set') || method.startsWith('create')) && typeof Note[method] === 'function') {
            Note[method+'And'] = function() {
                let result = Note[method](...arguments);
                return new Proxy({},{get(target,name) {
                  return Note[name].bind(undefined,result)
                }})
            }
        }
    })
}

Object.getOwnPropertyNames(Note).forEach(method=>{
    if (!method.startsWith('create')  && typeof Note[method] === 'function') {
        Number.prototype[method] = function() {
            return Note[method](this, ...arguments);
        }
    }
})

Number.prototype.status   = function(val){return val===undefined ? Note.status(this)  : Note.setStatus(this,val) }
Number.prototype.channel  = function(val){return val===undefined ? Note.channel(this) : Note.setChannel(this,val)}
Number.prototype.data0    = function(val){return val===undefined ? Note.data0(this)   : Note.setData0(this,val)  }
Number.prototype.data1    = function(val){return val===undefined ? Note.data1(this)   : Note.setData1(this,val)  }
Number.prototype.data2    = function(val){return val===undefined ? Note.data2(this)   : Note.setData2(this,val)  }
Number.prototype.log      = function(){Note.log(this)}




if (0 && "run the bloody unit tests") {
    console.log('hye')
    var thru = [0, 1];
    var acti = [0, 1];
    var Timewarp = [0, 1];
    var Display = [0, 1, true];
    var tracks = [0]
    var status = [128]
    var channel = []
    var data1 = []
    var i = -1;
    while(++i<=15) {
        // if (i<2 || i>14) tracks.push(i)
        if ((i%16)==0) status.push(i)
        if (i<2 || i>14) channel.push(i)
        if (i==0) data1.push(i)
    }
    console.log(tracks, status, channel)
    i = 0;
    thru.forEach(thr=>{
    acti.forEach(act=>{
    Timewarp.forEach(tiwa=>{
    Display.forEach(disp=>{
    tracks.forEach(tra=>{
    status.forEach(sta=>{
    channel.forEach(cha=>{
    data1.forEach(dat=>{
        var n = (0)
            .setThru(thr)
            .setAction(act)
            .setTimewarp(tiwa)
            .setEchoed(disp)
            .setTrack(tra)
            .setStatus(sta)
            .setChannel(cha)
            .setData1(dat)
        if (cha>15) console.log(cha,n.toString(16), n.print(), n.channel())
        if (!(n.thru()==thr)) console.log(i,thr,act,tiwa,disp,tra,'err: thr should = ', thr, " but = ", n.thru(), n.print() )
        if (!(n.action()==act)) console.log(i,thr,act,tiwa,disp,tra,'err: thr should = ', act, " but = ", n.action(), n.print() )
        if (!(n.timewarp()==tiwa)) console.log(i,thr,act,tiwa,disp,tra,'err: tiwa should = ', tiwa, " but = ", n.timewarp(), n.hex() )
        if (!(n.echoed()==disp)|| 1) console.log(i,"thr",thr,"act",act,"tiwa",tiwa,"disp",disp,"tra",tra,'err: disp should = ', disp, " but = ", n.echoed(), n.hex(), n.binary() )
        if (!(n.track()==tra)) console.log(i,thr,act,tiwa,disp,tra,'err: tra should = ', tra, " but = ", n.track(), n.print() )
        if (!(n.status()==sta)) console.log(i,thr,act,tiwa,disp,tra,'err: sta should = ', sta, " but = ", n.status(), n.print() )
        if (!(n.channel()==cha)) console.log(i,thr,act,tiwa,disp,tra,'err: cha should = ', cha, " but = ", n.channel(), n.print() )
        if (!(n.data1()==dat)) console.log(i,thr,act,tiwa,disp,tra,'err: dat should = ', dat, " but = ", n.data1(), n.print() )
        i++;
    }) }) }) }) }) }) }) })
    var tests = [
        function on(on, off) {return Note.isNoteOn(on)===true},
        function off(on, off) {return Note.isNoteOff(off)===true},
        function notOn(on, off) {return Note.isNoteOn(off)===false},
        function notOff(on, off) {return Note.isNoteOn(off)===false},
        function onChanChange(on, off) {on = Note.setChannel(on, 15); return Note.channel(on)===15},
        function offChanChange(on, off) {off = Note.setChannel(off, 4); return Note.channel(off)===4},
        function arrayBackForth(on, off) {return Note.createFromArray(Note.toArray(on))===on},
        function (on, off) {return Note.isProgramChange(on)===false},
        function (on, off) {return Note.isControlChange(on)===false},
        function (on, off) {on = Note.setStatus(on, 176); return Note.isControlChange(on)===true},
        function (on, off) {on = Note.setStatus(on, 176); return Note.isProgramChange(on)===false},
        function (on, off) {on = Note.setStatus(on, 198); return Note.isProgramChange(on)===true},
        function (on, off) {on = Note.setStatus(on, 198); return Note.isControlChange(on)===false},
        function (on, off) {on = Note.setData1(on, 127); return Note.data1(on)===127},
        function (on, off) {on = Note.setData2(on, 5); return Note.data2(on)===5},
    ];
    tests.forEach((test, idx)=>{
        var testNoteOn = Note.create(Note.ON, 10, 100)
        var testNoteOff = Note.create(Note.OFF, 20, 0)
        if (!test(testNoteOn, testNoteOff)) console.error('test failed', test, idx)
    })
    console.log("UNIT TESTS PASSED")
}
// console.log((2147483647).toString(2))
// console.log((2147483647).toString(2).length)
// console.log("NOTE DEBUG")
// console.log(0xFFFFFF.toString(2))
// console.log(0xFFFFFF.toString(2).length)
// Note.log(Note.createNote(144,126,1))
// Note.log(Note.setChannel(Note.createNote(144,126,1),5))
// console.log("NOTE DEBUG2")
// console.log('should be note:',Note.binary(Note.createNote(9, 2,3,4)))
// Note.log(Note.createNote(15, 1,255,255))
// console.log(Note.print(Note.createNote(15, 15,255,255)))
// console.log(Note.print(Note.createNote(224,255,255)))
// console.log(Number.MAX_SAFE_INTEGER)
    // console.log(2147483647)
// console.log(0b1<<32*8)
if (0) {
    class NoteStream {
        constructor() {
            // this.time = undefined;
            this.chain = [];
            return this;
        }
        execute(fn) {
            this.chain.push(()=>{
                fn.bind(this)();
                return true;
            })
            return this;
        }
        filter(condition, fn) {
            this.chain.push(()=>{
                if (condition.bind(this)(this.n, this.t)) {fn.bind(this)();return false;}
                else return true;
            })
            return this;
        }
        input(note, time) {
            this.n = note;
            this.t = time;
            // EXECUTE ALL IN CHAIN
            this.chain.some((fn)=>{
                if (fn.bind(this)()===false) return true;
            })
            return this;
        }
        connect(noteStream) {
            // console.log("connecting to stream", noteStream)
            this.chain.push(()=>{
                console.log("connection: ", this, "to",noteStream)
                noteStream.input(this.n, this.t)
            })
            return this;
        }
    }
    window.NoteStream = NoteStream
    console.log("==============TESTING STREAM==============")

    var end_stream = new NoteStream()
    end_stream.execute(function(){console.log("END STREam", Note.print(this.n), this.t)});
    console.log(end_stream)
    var stream =
        new NoteStream()
            .execute(function(){console.log("STEP1",Note.status(this.n))})
            .filter(Note.isNoteOn,function(n, time){console.log('foundNoteOn',Note.print(this.n),this.t,"\n FILTER this:", this)})
            .execute(n=>console.log('note made it through'))
            .execute(function(){console.log('this is ',this)})
            .connect(end_stream)
    console.log(stream)
    stream.input(Note.create(144,0,1), 100)
    stream.input(Note.create(128,0,0), 200)
    stream.input(Note.create(176,10,0), 300)


} else  if (0) {
    // OLD NOTESTREAM
    class NoteStream {
        constructor() {
            // this.time = undefined;
            this.chain = [];
            return this;
        }
        execute(fn) {
            this.chain.push((note, time)=>{
                fn(note, time);
                return true;
            })
            return this;
        }
        modifyNote(fn) {
            this.chain.push(fn)
            return this;
        }
        filter(condition, fn) {
            this.chain.push((note, time)=>{
                if (condition(note, time)) {fn && fn(note,time);return false;}
                else return true;
            })
            return this;
        }
        input(note, time) {
            let n = note;
            let t = time;
            // EXECUTE ALL IN CHAIN
            this.chain.some((fn)=>{
                let retVal = fn(n, t);
                let type = typeof retVal
                console.log("retVal:", retVal, type)
                if (retVal===false) return true; // end the Chain
                if (typeof retVal === "number") {
                    n = retVal;
                    console.log("NUMBER")
                }

            })
            return this;
        }
        connect(noteStream) {
            this.chain.push((note, time)=>{
                noteStream.input(note, time)
            })
            return this;
        }
    }
    console.log("==============TESTING STREAM==============")
    var end_stream = new NoteStream()
    end_stream.execute((n, t)=>{console.log("END STREam", Note.print(n), t)});
    var stream =
        new NoteStream()
            .execute((n,t)=>{console.log("INPUT:",Note.print(n),t)})
            .modifyNote((n,t)=>{return Note.setStatus(n,176)})
            .modifyNote((n,t)=>{return [1,2]})
            .filter(Note.isNoteOn,(n, t)=>{console.log('foundNoteOn and blocked',Note.print(n),t)})
            .filter(Note.isNoteOff)
            .execute(n=>console.log('note made it through'))
            .execute(()=>{console.log('this is ',this)})
            .connect(end_stream)
    console.log(stream)

    stream.input(Note.create(144,0,1), 100)
    stream.input(Note.create(128,0,1), 200)
    // stream.input(Note.create(176,0,1), 200)
}
})



// function toString() {
//     return this.outerHTML
// }
// function setState(state) {
//     console.log('setState',this)
//     console.log('setState',this.props)
//     for(var p in state) {
//         if (this.props[p]!==state[p]) {
//             console.log(this.map[p])
//             this.props[p] = state[p]
//             this.map[p].setAttribute(p,this.props[p])
//         }
//     }
// }
// let create = (html)=>{
//     // let node = document.createElement('div')
//     console.log("create:",html, propStack)
//     // node.innerHTML = html; node = node.children[0];
//     rootnode = new DOMParser().parseFromString(html, 'text/html').body
//     let map = {};
//     for(let p in propStack) {
//         // map[p] = rootnode.querySelectorAll(`[prop*="{${p}}"]`)
//         let el = rootnode.querySelector(`[s-${p}]`)
//         console.log(p, el )
//         if (el) {
//             map[p] = el;
//             map[p].removeAttribute(`s-${p}`)
//             map[p].setAttribute(`${p}`,propStack[p])
//             delete propStack[p]
//         }
//     }
//     console.log(map)
//     node = rootnode.firstChild
//     node.props = propStack
//     node.map = map
//     node.setState = setState.bind(node)
//     // node.toString = toString.bind(node)
//     node.toString = ()=>{
//         propStack = {...propStack, ...node.props};
//         return node.outerHTML;
//     }

//     // propStack = {};
//     return node
// } 


// let propStack = {}
// function prop(name, val) {
//     console.log('prop:',name,val)
//     propStack[name] = val;
//     return `s-${name}="${val}"`
// }
// let v = create(/* html */ `
// <div ${prop('hi','test')}>
//     <p ${prop('style','background-color:red')}>
//         ${create(`<div ${prop('hi2','test2')}></div>`)}
//     </p>
// </div> `)
// console.log(v.parentElement.innerHTML)
// v.setState({hi:'lol'})
// v.setState({style:'width:100px;2'})
// window.v = v;
// console.log(v)
