define(function(require, exports, module) {
var MIDI_INTERCEPTOR = require('src/MIDI_INTERCEPTOR.js')
var actionsController = require('src/actionsController.js');
const mod = require('src/mod.js')
const white_keys = require('src/keys.js').white_keys;
const black_keys = require('src/keys.js').black_keys;

function setLowestNote(n) {
    var chanIdx = 0;
    var controlIdx = 0;
    const controlKeys = ['loop','undo','octaveDown','octaveUp','shift']

    console.log("setLowestNote(",n,")")
    for(var i = n; i<n+12; i++) {
        if (black_keys.indexOf(mod(i+120,12))===-1) {
            console.log(i, "white chan:",chanIdx);
            actionsController.changeTrigger(actionsController.byName("track"+(chanIdx+1)),i)
            chanIdx++;
        } else {
            console.log(i,"black",controlKeys[controlIdx])
            actionsController.changeTrigger(actionsController.byName(controlKeys[controlIdx]),i)
            controlIdx++;
        }
    }
}
const Action = require('./Action.js')
module.exports = new Action({
    name:"lowest note",type:"button",
    title:"When activated the next key pressed will be set to the bottom key on keyboard, determines which notes are mappped to controls",
    priority:7,
    action:function() {
        if (MIDI_INTERCEPTOR.iWantNextNote!==this) {
            this.enable()
        } else {
            this.disable()
        }
    },
    stealNote:function(on, note) {
        console.log("setting lowest note",note)
        setLowestNote(note);
        this.disable();
        return false;
    }, enable: function(){
        console.log("lowest note active")
        MIDI_INTERCEPTOR.iWantNextNote = this;
        document.body.style.backgroundColor = '#485';
    }, disable: function () {
        console.log("lowest note not active")
        document.body.style.backgroundColor = '';
        MIDI_INTERCEPTOR.iWantNextNote = null;
    }
})
})
