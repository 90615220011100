define(function(require, exports, module) {
	require('src/MIDIjs/js/midi/loader.js')
	require('src/MIDIjs/inc/shim/Base64binary.js')
	require('src/MIDIjs/js/midi/audioDetect.js')
	require('src/MIDIjs/js/midi/gm.js')
	require('src/MIDIjs/js/midi/plugin.audiotag.js')
	require('src/MIDIjs/js/midi/plugin.webaudio.js')
	require('src/MIDIjs/js/util/dom_request_xhr.js')
	require('src/MIDIjs/js/util/dom_request_script.js')
	require('src/MIDIjs/js/util/dom_request_script.js')
	var instrument_loading_bar = require('src/utils/instrument_loading_bar.js');

	function onerror(e) {
		var errMsg = (e.currentTarget && (e.responseURL + ":" + (e.currentTarget.statusText || e.currentTarget.status))) || e;
		console.error("failure loading webmidi font, error:",e);
		console.log("midistatus","failure loading webmidi font, error:"+errMsg)
	}
	function onprogress(state, progress) {
		instrument_loading_bar.set(progress)
	}
	function onsuccess(){
	}

	module.exports  = {
		id: 'web-synth',
		name:'web-synth',
		type:'output',
		_open: function() {
			instrument_loading_bar.show()
			// console.log("open",this)
			if (!this._MIDIjsLoaded)
				return new Promise(function(res,err) {
					this.connection = 'pending'
					// window.MIDI = {};
					// ()=>{
					// });

					// what a load of garbage just to make it load the channels in order.. doesn't help that GM doesn't include drums by standard!
					this.mostRecentlyUsedNonLoadedChannel = null;
					this.currentlyLoading = null;
					this.channelsNeedingLoad = []
					this.needsToStartLoading = false;
					this.channelInstruments = new Array(16).fill(0);
					this.channelInstruments[9] = 254

					this.channelInstruments.forEach((inst,idx)=>{
						MIDI.channels[idx].instrument = inst;
					})
					MIDI.channels[9].instrument = 254

					// ADDED THIS TO START LOADING PIANO BY DEFAULT
					this.needsToStartLoading = true;
					this.channelsNeedingLoad.push(0);
					// (above can be removed)
					if (this.needsToStartLoading===true) {
						this.loadNextChannel();
					}
					// was in callback before..
						// console.log('require done',MIDI)
						// console.log("MIDI.channels",MIDI.channels)
						// console.log(Object.keys(MIDI))
						MIDI.soundfontUrl = "./midi-js-soundfonts/FluidR3_GM/"

						MIDI.setDefaultPlugin(MIDI.WebAudio);
						MIDI.api = 'webaudio'
						// console.log("MIDIjs", MIDI)

					this._MIDIjsLoaded = true;
					this.onLoadedAllChannels = res;

				}.bind(this))
			else
				return Promise.resolve()
		},
		_send: function() {
			if (arguments[0][0]==0xF0 &&
				arguments[0][1]==0x7F &&
				arguments[0][2]==0x7F &&
				arguments[0][3]==0x04 &&
				arguments[0][4]==0x01 &&
				arguments[0][7]==0xF7) {
				// shift to be in 256 range
				var volume = ((arguments[0][5]+(arguments[0][6]<<7))&16383)>>6
				console.log((arguments[0][5]+(arguments[0][6]<<7)), volume)
				MIDI.setVolume(null, volume)
			}
			else if ((arguments[0][0]&0xF0)==0xC0) {
				// PROGRAM CHANGE
				var channel = arguments[0][0]&0x0F;
				var instrument = arguments[0][1];
				if (channel==9) instrument = 254

				if (this._MIDIjsLoaded)
					MIDI.channels[channel].instrument = instrument;
				this.channelInstruments[channel] = instrument;


				this.channelsNeedingLoad.push(channel)
				this.mostRecentlyUsedNonLoadedChannel = channel;
				instrument_loading_bar.show()

				this.loadNextChannel();

			} else if ((arguments[0][0]&0xF0)==0x90) {
				var channel = arguments[0][0]&0x0F;
				// TODO: fix this monstrousity (although it works)
				if ((this.channelsNeedingLoad.length!==0 && this.channelsNeedingLoad.indexOf(channel)!==-1) ||
					(channel===9 && (!MIDI.Soundfont || !MIDI.Soundfont.percussion || !MIDI.Soundfont.percussion.isLoaded))) {
					this.mostRecentlyUsedNonLoadedChannel = channel;
					// console.log("PRIORITIZING CHANNEL",channel)
				}
				if (this._MIDIjsLoaded)
					MIDI.noteOn(arguments[0][0]&0x0F,arguments[0][1]&0xFF,arguments[0][2]&0xFF)
			} else if ((arguments[0][0]&0xF0)==0x80) {
				if (this._MIDIjsLoaded)
					MIDI.noteOff(arguments[0][0]&0x0F,arguments[0][1]&0xFF)
			}

		},
		_MIDIjsLoaded:false,
		loadNextChannel:function() {
			console.log("loadNextChannel")
			// if (!this._MIDIjsLoaded) {this.needsToStartLoading = true; return;}
			if (this.currentlyLoading!==null) return;
			var channel = null;
			if (this.mostRecentlyUsedNonLoadedChannel!==null) {
				channel = this.mostRecentlyUsedNonLoadedChannel;
				this.mostRecentlyUsedNonLoadedChannel = null;
			} else {
				if (this.channelsNeedingLoad.length>0)
					channel = this.channelsNeedingLoad[0];
			}
			if (channel===null) {
				// this means all channels with an instrument selected are done loading...
				// (except you can't select an instrument on the drum channel so load that!)
				// console.log("done?", MIDI.Soundfont)
				if (!MIDI.Soundfont || !MIDI.Soundfont.percussion || !MIDI.Soundfont.percussion.isLoaded) {
					channel = 9;
					// console.log("no")
				} else {
					instrument_loading_bar.hide()
					this.onLoadedAllChannels && this.onLoadedAllChannels();
					this.onLoadedAllChannels = undefined;
					return;
				}
			}
			// console.log("NOW LOADING", channel,performance.now())
			// console.log(this.channelsNeedingLoad)
			this.currentlyLoading = channel;
			this.channelsNeedingLoad = this.channelsNeedingLoad.filter(c=>c!==channel)
			var instrument = MIDI.channels[channel].instrument
			// if (instrument==254) console.log("LOADING DRUMSSSSSSSS")
			MIDI.loadPlugin({
	            instrument:instrument,
	            api:"webaudio",
	            onsuccess:function() {
	            	this.currentlyLoading = null;
	            	onsuccess()
	            	this.loadNextChannel()
	            }.bind(this),
	            onerror:onerror,
	            onprogress:onprogress
	        })
	        console.log(instrument,MIDI.GM.byId[instrument])
	        MIDI.GM.byId[instrument] && instrument_loading_bar.set_name(MIDI.GM.byId[instrument].instrument);
	        instrument_loading_bar.set_channel(channel);
		}
	}
})
