define(function(require, exports, module) {
	// MIDI LIST

	// MIDI CC Number	MIDI CC
	// Purpose	MIDI CC
	// Description
	module.exports = {
	"-1":"Pitch", // yep another hack
	0:"Bank Select",
	1:"Mod wheel",
	2:"Breath Controller",
	3:"CC:3",
	4:"Foot Controller",
	5:"Portamento Time",
	6:"Data Entry MSB",
	7:"Channel Volume",
	8:"Balance",
	9:"CC:9",
	10:"Pan",
	11:"Expression Controller",
	12:"Effect Control 1",
	13:"Effect Control 2",
	14:"CC:14",
	15:"CC:15",
	16:"Controller 1",
	17:"Controller 2",
	18:"Controller 3",
	19:"Controller 4",
	20:"CC:20",
	21:"CC:21",
	22:"CC:22",
	23:"CC:23",
	24:"CC:24",
	25:"CC:25",
	26:"CC:26",
	27:"CC:27",
	28:"CC:28",
	29:"CC:29",
	30:"CC:30",
	31:"CC:31",
	32:"Bank Select",
	33:"Modulation Wheel",
	34:"Breath Controller",
	35:"CC:35",
	36:"Foot Controller",
	37:"Portamento Time",
	38:"Data Entry",
	39:"Channel Volume, formerly Main Volume",
	40:"Balance",
	41:"CC:41",
	42:"Pan",
	43:"Expression Controller",
	44:"Effect control 1",
	45:"Effect control 2",
	46:"CC:46",
	47:"CC:47",
	48:"General Purpose Controller 1",
	49:"General Purpose Controller 2",
	50:"General Purpose Controller 3",
	51:"General Purpose Controller 4",
	52:"CC:52",
	53:"CC:53",
	54:"CC:54",
	55:"CC:55",
	56:"CC:56",
	57:"CC:57",
	58:"CC:58",
	59:"CC:59",
	60:"CC:60",
	61:"CC:61",
	62:"CC:62",
	63:"CC:63",
	64:"Damper Pedal on/off",
	65:"Portamento On/Off",
	66:"Sostenuto On/Off",
	67:"Soft Pedal On/Off",
	68:"Legato Footswitch",
	69:"Hold 2",
	70:"Sound Variation",
	71:"Timbre/Harmonic Intens.",
	72:"Release Time",
	73:"Attack Time",
	74:"Brightness",
	75:"Decay Time",
	76:"Vibrato Rate",
	77:"Vibrato Depth",
	78:"Vibrato Delay",
	79:"CC:79",
	80:"Controller 5",
	81:"Controller 6",
	82:"Controller 7",
	83:"Controller 8",
	84:"Portamento Control",
	85:"CC:85",
	86:"CC:86",
	87:"CC:87",
	88:"High Resolution Velocity Prefix",
	89:"CC:89",
	90:"CC:90",
	91:"Effects 1 Depth ",
	92:"Effects 2 Depth",
	93:"Effects 3 Depth ",
	94:"Effects 4 Depth",
	95:"Effects 5 Depth",
	96:"Data Increment",
	97:"Data Decrement",
	98:"NRPN - LSB",
	99:"NRPN - MSB",
	100:"RPN - LSB*",
	101:"RPN - MSB*",
	102:"CC:102",
	103:"CC:103",
	104:"CC:104",
	105:"CC:105",
	106:"CC:106",
	107:"CC:107",
	108:"CC:108",
	109:"CC:109",
	110:"CC:110",
	111:"CC:111",
	112:"CC:112",
	113:"CC:113",
	114:"CC:114",
	115:"CC:115",
	116:"CC:116",
	117:"CC:117",
	118:"CC:118",
	119:"CC:119"
	}


})
