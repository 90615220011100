define(function(require, exports, module) {

var options = {
    // map:mapping.map,
    numChannels:16, // not used
    numTracks:7,
    channelsUsed: [0,1,2,3,4,5,9],
    channelInstruments:[-1, 4, 5, 48, 11, 19, 51],
    channelsActive:new Array(16).fill(false), // unused?
    actionChannel: -1, // set to -1 for all channels, otherwise will only allow notes to trigger actions from the specified channel
    noteChannel: 0, // set to -1 for all channels, otherwise will only allow notes to play from the specified channel
    drumChannel: 9, // MIDI specifies drums on channel 10! (= 9 zero indexed)
    useMidi: true,
    useChannels:true,
    programChange:true, // set to false and won't send any program change messages (no guarantees)
    forwardUnknownMidi:true, // set to false and won't send any program change messages (no guarantees)
    sync:true,
    countInTime:false, // 1000, // if you repeat press within this (ms) do count In
    cleanUpEndNotes:true, // No-one likes hanging notes
    logMidi:false,
    test_loopButton:false, // fake loop button presses
    test_bankSelect:false    , // fake inst switch button presses
    test_speedChange:false    ,
    isMobile: /Mobi/.test(navigator.userAgent), // disable auto loading samples on mobile
    isProd: window.location.href.indexOf("midilooper.com") > -1,
    autoScroll: true, // scroll to bottom of page on action
    disableOtherOutputs: false,
    useSVG: false,
    startOctave: 0,
    perTrackOctaveShift: true,
    showCCSliders: false,
    showVolSliders: false,
    showCCValues: true,
    showAdvancedControls: true,
    advancedControlsList: ['fix-length','channels','CCinfo','console','sync','velocity','pitch'], // hide these by default
    closeOldInputs: true,
    pauseWhenHidden: true,
    loopThingsThatArentNotes: true,
    enableDragging: false,
    addObjectsToWindow: true,
    optimizeNotes: true,
    muteTracksWhileLooping: false,
};
if (window.localStorage && window.localStorage.getItem('options')) {
    var savedOptions = JSON.parse(window.localStorage.getItem('options'))
    // console.log('loading saved options', savedOptions)
    Object.keys(savedOptions).forEach(k=>{0 && console.log(k, k in options,options[k], savedOptions[k]); if(k in options) options[k] = savedOptions[k]})

}
options.isMobile = /Mobi/.test(navigator.userAgent) // disable auto loading samples on mobile
options.isProd = window.location.href.indexOf("midilooper.com") > -1
window.options = options
module.exports = options;
})
