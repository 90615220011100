define(function(require, exports, module) {
    const Action = require('src/actions/Action.js')
    const highlight = require('../utils/highlight.js')
    module.exports = new Action({
        name:"fix-length",type:"toggle",
        title: "Set loops to allways be the same length. Will be set to longest current loop.",
        priority:-1,
        action:function(val) {
            console.log('set fixLength.js',this.toggle.checked,val)
            // this.toggle.checked = val!==undefined ? val : !this.toggle.checked

            this.toggle.checked = loopController.setFixedLength(this.toggle.checked)===true;
            console.log('fixLength.js',this.toggle.checked)
	        this.update()
        },
        update:function(){highlight('white',this.btn)},
        // startAction:function() {this.toggle.checked = false}

    })
})
