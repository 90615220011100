define(function(require, exports, module) {
  "use strict";
  // minimal sub/pub implementation
  // subscribe(fun) to have fun(newValue) called whenever the value changes
  module.exports = (initalState) => {
    let state = initalState;
    const subscribers = [];
    const store = {
      // _internal_
      publish: newState =>{
        if (newState===undefined) newState = store.getState();
        subscribers.forEach(handler=>handler(newState))
      },
      setState: (newState, forceUpdate) => {
        let update = forceUpdate===true || state !== newState; // only update if state has changed
        state = newState;
        if (update) {
          store.publish(newState)
        }
      },
      getState: () => state,
      getValue: () => {console.error('you meant to write getState here;'); return this.getValue()},
      setValue: (value) => {console.error('you meant to write getValue here;'); return this.setValue(value)},
      // add a subscriber function. alsoExecute if the function should be executed immediately
      subscribe: (handler, alsoExecute) => {
        subscribers.push(handler);
        setTimeout(()=>{
          if (alsoExecute) handler(store.getState())
        })
        return () => {
          const index = subscribers.indexOf(handler);
          if (index >= 0) {
            subscribers.splice(index, 1);
          } else {
            console.error('could not unsubscribe', index, subscribers,subscribers.length, handler)
          }
        };
      },

    };
    return store;
  };
})
