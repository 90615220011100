define(function(require, exports, module) {
var SideBar = require('../SideBar.js')
var MIDI_INTERCEPTOR = require('../MIDI_INTERCEPTOR.js')
const Action = require('./Action.js')

// functions to add debugs to sidebar
var debugRequestAnimationFrame = false;
var newDebugs = [];

function debugMidiLog(timeStamp, note) {
    newDebugs.push(arguments)
    if (debugRequestAnimationFrame===false) {
        debugRequestAnimationFrame = true;
        requestAnimationFrame(drawNewDebugs)
    }
}
function drawNewDebugs() {
    var toAppend = "";
    for(var i = 0; i<newDebugs.length; i++) {
        let argumentz = newDebugs[i]//.pop();
        // if (argumentz===undefined) continue;
        // console.log('argumentz', argumentz)
        let timeStamp = argumentz[0] || 0.0;
        toAppend += timeStamp.toFixed(2) + "-" + Note.print(argumentz[1]) + "\n";
    }
    newDebugs.length = 0;
    var debugDiv = $('#MIDI-debug-bar');
    $('#MIDI-debug-output').innerHTML += toAppend;
    debugDiv.scrollTop = debugDiv.scrollHeight;
    debugRequestAnimationFrame = false;
}
window.addEventListener('debug-midi-in', e=>debugMidiLog(...e.detail))

module.exports = new Action({
    name:"midi-monitor", type:"button",
    title:"shows incoming midi in a sidebar",
    priority:5,
    action: function() {
        var is_open = this.SIDE_BAR.open_or_close()
        MIDI_INTERCEPTOR.debug = is_open;
    },
    startAction: function() {
        this.SIDE_BAR = new SideBar('right', '<pre id="MIDI-debug-output" style="margin:0px;margin-top: 5px;font-size:12px;">MIDI-IN:\n</pre>','MIDI-debug-bar');
    }
})
})
