"use strict"
define(function(require, exports, module) {
	module.exports = function doWhenDOMLoaded(callback){
		// so much grief from getting this wrong in prod...
		if( document.readyState === 'loading' ) {
			// if document still loading wait for it to load then fire
		    document.addEventListener('DOMContentLoaded', function () {
		        callback()
		    });
		} else {
			// if document already loaded fire immediately
		    callback();
		}
	}
})
