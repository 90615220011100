define(function(require, exports, module) {
// var actionsController = require('./actionsController.js');
var options = require('./options.js');

var mapping = {}
mapping.selectedMapping = 'virtualKeyboard'

mapping.saved = {}
mapping.saved['virtualKeyboard'] = {
	inputs:['qwerty'],
	actionChannel: -1,
	map: {
	    loop: 37,
	    undo: 39,
	    track0:36,
	    track1:38,
	    track2:40,
	    track3:41,
	    track4:43,
	    track5:45,
	    track6:47,
	    octaveDown: 42,
	    octaveUp: 44,
	    shift: 46,
	    // delayedLoop: -106
	}
}
// mapping.saved['launchkey'] = {
// 	inputs:['Launchkey Mini'],
// 	actionChannel: 9,
// 	map: {
// 		// specific for launchkey mini
// 		track0:36,
// 		track1:37,
// 		track2:38,
// 		track3:39,
// 		track4:44,
// 		track5:45,
// 		track6:46,
// 		track7:47,
// 		loop:40,
// 		undo:41,
// 		octaveDown:42,
// 		octaveUp:43,
// 		shift:48,
// 		delayedLoop:-106,
// 		volume:-21
// 	}
// }

var loadedMapNoErrors = false;
if (window.localStorage && window.localStorage.getItem('mapping')) {
	try {
		var map = JSON.parse(window.localStorage.getItem('mapping'))
		mapping.map = map;
		console.log("loaded map", map)
		loadedMapNoErrors = true;
	} catch(error) {
		console.error(error)
	}
}
if (loadedMapNoErrors!==true) {
	mapping.map = mapping.saved[mapping.selectedMapping].map;
	// console.log("auto map", map)
}
// mapping.save = function() {
// }
mapping.save = function() {
	mapping.map = {}
	actionsController.actions
	    .filter(a=>a.trigger!==undefined)
	    .forEach(a=>{
	        mapping.map[a.name] = a.trigger
	    })
	window.localStorage.setItem('mapping',JSON.stringify(mapping.map))
	console.log("saved", mapping.map)
}
mapping.select = function(mapName) {
	return;
	console.log("select Map",mapName)
	if ((mapping.saved[mapName]===undefined)) {return console.error('selecting non-existant map', mapName, 'from', mapping.saved)}
	mapping.selectedMapping = mapName;
	// TODO remove object hard link?
	mapping.map = mapping.saved[mapping.selectedMapping].map;
	if (mapping.actionChannel!==undefined) options.actionChannel = mapping.saved[mapping.selectedMapping].actionChannel;
	// console.log("new map",mapName,mapping.map)
}
mapping.new = function(mapName) {
	if (mapping.saved[mapName]!==undefined) {
		var i = 2;
		while(mapName + i in mapping.saved) {
			i++;
		}
		mapName = mapName+i
	}
	mapping.saved[mapName] = {}
	return mapName;
}
// TODO finish
// give name of opened midi device -> changes mapping to match
// should call actionsController.updateTriggers after this :/
mapping.inputSelected = function(inputName) {
	for (var k in mapping.saved) {
		if (mapping.saved[k].inputs.indexOf(inputName)!==-1) {
			mapping.select(k)
			return
		}
	}
}
window.mapping = mapping;
module.exports = mapping
})
