define(function(require, exports, module) {

    const outputState = require('src/outputState.js')
    const createElement = require('src/utils/createElement.js')
    const cols = require('src/utils/cols.js')
    const Action = require('src/actions/Action.js')
    const messageBar = require('src/messageBar.js');

    var TRACKIE = require('src/TRACKIE.js')


    var MIDI_TRACKLIST = {
        init: function() {
            this.open = false;
            this.toggle_btn = $('#expand-tracklist'),
            this.div = $('#output-tracks')
            this.currTrack_div = $('#current-track');
            this.divs = [];
            this.toggle_btn.onclick = this.onclick.bind(this)
            this.track_select_spans = [];
            this.trackActions = [];
            this.createExtraSelects();
            this.update()
        },
        onclick: function() {
            this.open = !this.open;
            // if (this.open && this.divs.length==0) this.createExtraSelects();
            // this.currTrack_div.style.backgroundColor = this.open ? 'red' : ''
            this.update.bind(this)();

        },
        update: function() {
            $('#expand-tracklist').children[0].innerHTML = this.open ? "▲" : "▼";
            ;[...$('#current-track').getElementsByTagName("*")]
                // .filter(c=>c.id!=='current-track-controls')
                .filter(c=>(c instanceof HTMLSelectElement))
                .forEach(c=>{c.style.display = this.open ? 'none' : '';})

            ;[...$('#output-tracks').querySelectorAll('.single-track')].forEach(c=>{
                c.style.display = this.open ? '' : 'none'
            })
            // console.log("rerender")
            this.trackActions.forEach(trackAction=>trackAction.rerender())
        },
        createExtraSelects: function() {

            var track_template = $('#track-template')
            var track_container = $('#output-tracks')
            outputState.trackChannels.forEach((_,track_idx)=>{
                let track_div = track_template.cloneNode(true);
                // track_div.id = 'track-'+track_idx;
                track_div.track = track_idx;
                track_div.style.display = this.open ? '' : 'none'; 
                track_div.classList.add('single-track')
                var track_select_span = createElement('span',undefined,{})
                track_div.prepend(track_select_span)
                this.track_select_spans.push(track_select_span)

                let channel_select = track_div.querySelector('.midi-out-channel-select')
                channel_select.clear()
                for(let i=0; i<16; i++) {
                    channel_select.addOption( 'channel '+(i+1), {value:i, class:'channel-'+i+' bg-shade-1'});
                }

                let inst_select = track_div.querySelector('#channel-instrument-select')
                inst_select.clear()
                inst_select.addMultipleOptions(Array.from(outputState.instrumentChoices))



                // link it all up
                // console.log('linking track', track_idx, outputState.trackChannels[track_idx])
                let whichChannel = outputState.trackChannels[track_idx];
                // channel should set what channel the track plays on
                channel_select.link(whichChannel)
                // instrument sets whichever channel is selected in the preceding div
                whichChannel.subscribe((value)=>{
                    // console.log('channel inst now linked to channel', value)
                    // link should unlink previous automatically
                    inst_select.link(outputState.channelInstruments[value])
                }, true)
                track_container.appendChild(track_div)

            })

            let highlightSelectedTrack = selectedTrack => {
                selectedTrack = outputState.currentTrack.getState()
                // console.error('track selected: ', selectedTrack) // TODO this is called too often
                var heldDownTracks = outputState.trackHeldDown.map(x=>x.getState())
                // console.log(heldDownTracks)
                // console.log(selectedTrack)
                ;[...$('#output-tracks').querySelectorAll('.single-track')].forEach(el=>{
                    // console.log(el.id, 'track-'+selectedTrack)
                    if (el.track===selectedTrack) {
                        var chan = outputState.getCurrentChannel()
                        // console.log(chan)
                        // el.classList.add('trackSelected')
                        cols.styleElement(el,chan, 4,'bg')
                        cols.styleElement(el,chan, 6,'border')
                    } else {
                        var chan = outputState.trackChannels[el.track].getState()
                        // console.log(el.track,heldDownTracks[el.track])
                        if (heldDownTracks[el.track]===true) {
                            cols.styleElement(el,chan, 2,'bg')
                            cols.styleElement(el,chan, 4,'border')
                        } else {
                            cols.unstyleElement(el)
                        }
                    }
                })
            }
            outputState.trackHeldDown.forEach(t=>t.subscribe(highlightSelectedTrack))
            outputState.currentTrack.subscribe(highlightSelectedTrack, true)
            track_template.remove()
        }
    }
    require('src/utils/doWhenDOMLoaded.js')(()=>{
        $('#expand-tracklist').style.display = ''
        MIDI_TRACKLIST.init.bind(MIDI_TRACKLIST)()

        outputState.trackChannels.forEach((_,track_idx)=>{
            var trackAction = new Action({
                name:"track"+(track_idx+1),
                track:track_idx,
                container: MIDI_TRACKLIST.track_select_spans[track_idx],
                type:"button",
                // customStyle:'background-color:'+cols.channel[,
                action:function(){
                    // console.log('track action', this.track, outputState, outputState.channelInstruments[this.track])
                    if (!loopController.activeLoop) {
                        if (TRACKIE.active==false) {
                            outputState.currentTrack.setState(this.track)
                        }
                        TRACKIE.chanButton(outputState.channelInstruments[this.track].getState(),true);
                        outputState.trackHeldDown[this.track].setState(true)
                        if (!TRACKIE.currDownactive) {
                            // console.log('WHat was this?>?')
                            // var inst = outputState.channelInstruments[this.track].getState()
                            // $('#midi-out-track-select').options.selectedIndex = this.track;
                            // $('#midi-out-track-select').onchange()
                        }
                    } else {
                        // during looping mute/unmute track since we can't (or shouldn't)  change track
                        // TODO: REFACTOR
                        var shouldMute = loopController.completedLoops.some(l=>l.track==track_idx && l.muted!==true && l.hidden!==true);
                        var tracksToModify = loopController.completedLoops.filter(l=>l.track==track_idx && l.hidden!==true)
                        if (tracksToModify.length>0) {
                            tracksToModify.forEach(l=>l.muted=shouldMute)
                            messageBar.setMessage(`track-${(track_idx+1)} ${shouldMute ? 'muted' : 'un-muted'}`, {track:track_idx})
                        } else {
                            messageBar.setMessage(`track-${(track_idx+1)} empty`, {track:track_idx})
                        }                    }

                }, actionUp: function(){
                    if (!loopController.activeLoop) {
                        TRACKIE.chanButton(outputState.channelInstruments[this.track].getState(), false);
                        outputState.trackHeldDown[this.track].setState(false)
                    }
                }
            })
            MIDI_TRACKLIST.trackActions.push(trackAction)
        })


    })
})
