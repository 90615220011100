define(function(require, exports, module) {
const doWhenDOMLoaded = require('src/utils/doWhenDOMLoaded.js')
const cols = require('src/utils/cols.js')

module.exports = {
	show: function() {
		$('#sound-loading-div').style.display = ''
	},
	hide:function() {
		$('#sound-loading-div').style.display = 'none'
	},
	set: function(progress) {
		$('#sound-loading-bar-inner').innerHTML = `<div class="loading-cube"> </div>`.repeat((progress*10)|0)
	},
	set_name: function(name) {
		$('#sound-loading-info').innerHTML = "loading: "+name
	},
	set_channel: function(channel) {
		cols.styleElement($('#sound-loading-bar'),channel, 4, 'bg')
		cols.styleElement($('#sound-loading-bar-inner'),channel,7, 'bg')
		cols.styleElement($('#sound-loading-bar-inner'),channel,1, 'border')
	}
}
})
