define(function(require, exports, module) {
var loopId = 0;
var options = require('src/options.js')
var timeShifter = require('src/TimeShifter.js');
var LoopView = require('src/LoopView.js')

var mod = require('src/mod.js')
var Loop = function(time, channel, MIDI_OUT, waitForNote, track, section) {
    this.MIDI_OUT = MIDI_OUT;
    this.waitForNote = !!waitForNote;
    this.startTime = time;
    this.notes = [];
    // [time, on, note, prev_id]
    this.notesHeldDown = [];
    // helper arrays for not recalucating array min/max
    this.notemax = null;
    this.notemin = null;
    // for when starting:
    this.notes_on = {}; // idx's of notes that have not ended
    this.finished = false;
    this.id = loopId++;
    // console.log("loop id=" + this.id + " started", waitForNote)
    // this.lastPolled = null;
    this.pollIdx = null;
    this.repeats = null;
    this.finalLength = null;
    this.muted = false;
    this.pendingchange = 0;
    this.channel = channel;
    this.track = track;
    this.volumeMod = 1;
    this.loopView = new LoopView(this); // needs to be set externally as created after the fact
    this.hidden = false;
    this.sections = [section]
    // console.log("NEW LOOP", this.loopView)
    // return;
    // setInterval(function(){
    //     this.notes.forEach(n=>{
    //         if (typeof(n[4])!=="number") console.error(n)
    //     })
    // }.bind(this),0)
}
Loop.prototype.setHidden = function(hide) {
    hide = !!hide // force bool
    this.hidden = hide;
    this.loopView.div.style.display = hide ? "none" : ""
    this.loopView.ctx.canvas.style.display = hide ? "none" : ""
}
Loop.prototype.length = function(t) {
    if (this.finished) return this.finalLength
    if (this.waitForNote) return 0;
    return ((t===undefined ? timeShifter.now() : t) - this.startTime)
}

Loop.prototype.end = function(time) {
    // if any notes have been turned on, end them
    if (options.cleanUpEndNotes) {
        var actual_time = timeShifter.now()
        for (let note in this.notes_on) {
            if (this.notes_on[note] !== undefined) {
                console.log("cleaning trailling note",this.notes_on[note])
                this.note(actual_time, Note.create(128, note, 0))
            }
        }
    }
    // console.log(time)
    this.endTime = time;
    this.finalLength = this.endTime - this.startTime;

    // console.log("loop id=" + this.id + " finished")


    this.finished = true;
    this.repeats = 1
    this.pollIdx = 0;
    this.notes_on = {};
    this.notes.sort((a,b)=>a[0]-b[0])
}
Loop.prototype.note = function note(time, note) {
    // only handles note on/off
    if (this.waitForNote) {
        this.startTime = time;
        this.waitForNote = false;
    }

    var prev_id = undefined;
    // this is to make sure all notes have one 'note on' and one 'note off'
    // important for making sure loops don't leave ghost notes hanging about
    var TRUE = timeShifter.warp>=0;
    if (note.isNoteOn() === TRUE) {
        // note_on is already 'on'? ignore it
        if (this.notes_on[note.data1()] !== undefined)
            return console.warn('warning:note already on');
        // otherwise keep track of it's position in the notes array
        else
            this.notes_on[note.data1()] = this.notes.length;
    }
    else if (note.isNoteOn() === !TRUE) {
        // if it's a 'note off' but hasn't been turned on? ignore it
        if (this.notes_on[note.data1()] === undefined)
            return console.warn('warning:note off hasn\'t been turned off');
        else {
            // now we know the previous note's id
            prev_id = this.notes_on[note.data1()]; // prev_id is the index to the note on that started this note off (used by LoopView)
            this.notes[prev_id][2] = this.notes.length // also add this notes index to the note on note;
            this.notes_on[note.data1()] = undefined;
        }
        // need a prev_id for updating view
        if (prev_id == undefined) {
            console.log(this.id, this.notes, time, note.print())
            throw new Error();

        }
    }
    // update min/max (for loopView)
    this.notemax = this.notemax===null ? note.data1() : Math.max(this.notemax,note.data1());
    this.notemin = this.notemin===null ? note.data1() : Math.min(this.notemin,note.data1());
//     console.log(note, "min/max",prev_id)
    // add to my list of notes
    this.notes.push([time - this.startTime, note, prev_id]);
}
Loop.prototype.non_note = function non_note(time, note) {
    if (this.waitForNote) return;
    this.notes.push([time - this.startTime, note, undefined]);
}
/// NOTE : [
//  time,
//  note,
//  prev_id (or undefined if not a note on/off)
//  ]
//  this.notes[prev_id] = 
//  if noteOn -> the noteOff that ends the note
//  if noteOff -> the noteOn that started the note

Loop.prototype.keymod = function(n) {
    this.notes.forEach(a=>{
        if (a[1].isNoteOnOrOff()) a[1] = a[1].setData1(a[1].data1()+n);
    })
}
Loop.prototype.currPos = function(t) {
    t = t || timeShifter.now()
    return this.finished ? mod(t - this.startTime, this.length(t)) : t - this.startTime;
}
Loop.prototype.setChannel = function(channel) {
    this.channel = channel;
    this.loopView.updateChannel()
}
Loop.prototype.poll = function(t) {
    if (!this.finished || this.notes.length==0)
        return [];
    // if (this.lastPolled === null)
    //     this.lastPolled = this.startTime;
    var len = this.length();
    var reversing = timeShifter.warp < 0;
    var newNotes = [];
    var infiniteLoopChecker = 500;
    while (true) {
        if (!(this.pollIdx in this.notes) || !(0 in this.notes[this.pollIdx])) {
            return 0;
        }
        var n = this.notes[this.pollIdx]
        // note = [time, note, prev_id]);

        if (!reversing && n[0] + this.startTime + len * this.repeats < t) {
            newNotes.push(n.slice())
            this.pollIdx++;
        } else if ( reversing && n[0] + this.startTime + len * this.repeats > t) {
            newNotes.push(n.slice())
            this.pollIdx--;
        } else break;


        if (this.pollIdx >= this.notes.length) {
            this.pollIdx = 0;
            this.repeats++;
        }
        if (this.pollIdx < 0) {
            this.pollIdx = this.notes.length-1;
            this.repeats--;
        }
        if (infiniteLoopChecker--==0) {
            // console.log(this.pollIdx,this.lastPolled, this.startTime, this.endTime, this.repeats, reversing, this.notes, this);
            this.muted = true;
            break
        }
    }

    if (this.muted || this.hidden)
        // when muted only return notes Off
        return newNotes.filter(n=>{
            // var time=n[0] 
            var note=n[1],prev_id=n[2];
            // if it's a note off and has been put on by this loop
            // console.log(note.isNoteOff(), prev_id,this.notes_on[prev_id])
            // console.log(note.print(), prev_id, note.isNoteOff() && prev_id!==undefined && this.notes_on[prev_id]!==undefined);
            if (note.isNoteOff() && prev_id!==undefined && this.notes_on[prev_id]!==undefined) {
                this.notes_on[prev_id] = undefined;
                return true
            }
            return false;
        });
    else {
        return newNotes.map(n=>{
            var time=n[0], note=n[1],prev_id=n[2];

            if (note.isNoteOnOrOff()) {
                
                if (prev_id!==undefined) {this.notes_on[prev_id] = note.data1()}
                // change volume (velocity) from loopView slider
                note = note.setData2(Math.min(127,(note.data2()*this.volumeMod))); // | cast to int
            }
            return [time,note,prev_id];
        })
    }
}
Loop.prototype.recreateLoopView = function() {
    this.loopView = new LoopView(this);
}
Loop.prototype.delete = function() {
//     console.log(this.notes_on)
    var still_on = [];
    for (let note in this.notes_on) {
        // console.log(key, this.notes_on[key])
//         console.log("still on",note,this.notes_on[note])
        if (this.notes_on[note]!==undefined) {
            still_on.push(this.notes_on[note])
        }
    }
//     console.log('still_on',still_on)
    still_on.forEach((note)=>{

//         console.log(this.channel, note, 0)
        this.MIDI_OUT.send(Note.create(128, note, 0).setChannel(this.channel).toArray())
    })
}
module.exports = Loop;
})
