define(function(require, exports, module) {
var MIDI_VELOCICHANGER = undefined; //require('src/MIDI_VELOCICHANGER.js')
var KEYCHANGER = undefined;//require('src/KEYCHANGER.js')
var options = require('src/options.js');
var actionsController = require('src/actionsController.js');
const recorder = undefined;//require('src/recorder && recorder.js');
var MIDI_INTERCEPTOR = {
    iWantNextNote:null,
    debug: false
}
function sendToDebug() {
    window.dispatchEvent(new CustomEvent('debug-midi-in',{detail:[...arguments]}));
}

var lastTimeNoteOn = new Array(256).fill(-10000);
var doubleTapState = new Uint8Array(256).fill(0)
var doubleTapInterval = 250;

MIDI_INTERCEPTOR.note = function note(note, timeStamp) {
    // TODO: convert this fully
    // MIDI_INTERCEPTOR.note()
    if (this.debug) sendToDebug(timeStamp, note)

    // stealNote need to convert in map.js and setLowestNote
    if (this.iWantNextNote!==null) {if (!this.iWantNextNote.stealNote(note.isNoteOn(), note.data1())) return;}
    // thru means we don't want it to trigger actions (e.g. keyboard input)
    if (!note.thru() && (options.actionChannel==-1 || options.actionChannel==channel)) {
        // check if note is mapped to an action, if so do the action and don't play the note
        // console.log("checking action",note.isNoteOn(), note.data1(),note.data2(), timeStamp)
        if(!doActionIfExists(note.isNoteOn(), note.data1(),note.data2(), timeStamp))
            return;
    }
    // if noteChannel is specified filter out notes from other channels
    if (options.noteChannel!=-1 && options.noteChannel!=Note.channel(note)) return;
    // VELOCICHANGER - would go here but currently not used
    if (KEYCHANGER && KEYCHANGER.active) {
        note = KEYCHANGER.note(note, timeStamp)
    }

    // optional modules
    if (window.echo) {
        setTimeout(()=>{
            loopController.note(note.setEchoed(true),timeStamp)
        },window.echoDelay)
    }
    if (window.strumNote) window.strumNote(note)
    
    loopController.note(note, timeStamp);
    recorder && recorder.record(note, timeStamp)
}
MIDI_INTERCEPTOR.non_note = function non_note(note, timeStamp) {
    // console.log("non_note",note)
    if (this.debug) sendToDebug(timeStamp, note)

    // HACK: we are going to convert CC messages to negative notes so we can reuse our old mapping code;
    if (Note.isControlChange(note)) {
        // TODO: decide if other midi messages like program change can also be mapped?
        // max data1 can be is (127)
        var id = -(1+(Note.data1(note)&127)+(Note.channel(note)*128));
        // console.log("fancy id:",id)
        // console.log(Note.data1(note)&127+(Note.channel(note)*128));
        // console.log("note",Note.data1(note),"channel:",Note.channel(note), "full:",1+(Note.data1(note)&127)+(Note.channel(note)*128));
        // need it to be on for rotary controls, TODO: in future re-enable this for button controls?
        // var on = data2>=64; // copy ableton section 26.2.3 : https://www.ableton.com/en/manual/midi-and-key-remote-control/
        var on = true;
        if (this.iWantNextNote!==null) {if (!this.iWantNextNote.stealNote(on, id)) return;}
        if (!doActionIfExists(on, id, Note.data2(note), timeStamp)) return;
    }
    // if (window.strumPitch && note.data0()===Note.TYPES.PITCH) {
    //     window.strumPitch(note);
    //     if (window.strumming) return;
    // }
    loopController.non_note(note, timeStamp)//Note.channel(note), Note.status(note),Note.data1(note), Note.data2(note), timeStamp);
    recorder && recorder.record(note, timeStamp)
}.bind(MIDI_INTERCEPTOR);



function doActionIfExists(on, note, value, timeStamp) {
    var action = actionsController.getTriggeredAction(note);
    if (action) {
        var doubleTapState = on ? getDoubleTapState(note,timeStamp) : 0;
        if (!on) {
            // TODO: actionUp should be on mouseup as well as midi message
            action.actionUp && action.actionUp(value, undefined, timeStamp);

        } else {
            (action.action && action.action(value,doubleTapState, timeStamp )) || (action.update && action.update());
                    }
        return false;
    }
    return true;
}

function getDoubleTapState(note,timeStamp) {
    if (note>=0 && note<=255) {
        var lastTime = lastTimeNoteOn[note]
        // console.log(timeStamp,lastTime)
        if (lastTime+doubleTapInterval>timeStamp) {
            doubleTapState[note]++;

            // console.log(['normal','DOUBLE','TRIPLE','Q-Q-QUADRUPLE','**QUINTUPLE**','M_M_M_M_M_E_G_A_A_A_A_A'][Math.min(doubleTapState[note],5)]," TAP",timeStamp-lastTime)
        } else {
            doubleTapState[note] = 0;
            // console.log(['normal','DOUBLE','TRIPLE','Q-Q-QUADRUPLE','**QUINTUPLE**','M_M_M_M_M_E_G_A_A_A_A_A'][Math.min(doubleTapState[note],5)]," TAP",timeStamp-lastTime)
        }
        lastTimeNoteOn[note] = timeStamp

        return doubleTapState[note];
    } else {
        return 0;
    }
}

module.exports = MIDI_INTERCEPTOR;
})
