define(function(require, exports, module) {
var debug = false;

var _requestMIDIAccess = undefined;
if (navigator.requestMIDIAccess)
	_requestMIDIAccess = navigator.requestMIDIAccess.bind(navigator); // store original request Object

var _midiAccess = null; // store the midiAccess object in here

/* SPEC:
connection : {
    "open",
    "closed",
    "pending" // THIS IS MIDWAY TO BEING OPENED
};
state: {
    "disconnected",
    "connected" // YES THIS IS CONFUSING AS FUCK
};
STATE = IS IT PLUGGED IN?
CONNECTION = HAVE WE ACTUALLY OPENED IT?
*/
var fake_outputs = [
	require('src/devices/tiny-synth.js'),
	require('src/devices/web-synth.js'),
	{
		id: 'none',
		name:'none',
		type:'output'
	}
]
var fake_inputs = [
	require('src/devices/qwerty.js'),
];


function open_success() {
	// console.log("open_success")
	this.connection = "open"
	this.state = "connected"
	if (typeof _midiAccess.onstatechange === 'function')
		// _midiAccess.onstatechange(new MIDIConnectionEvent(_midiAccess,this));
		setTimeout(()=>{_midiAccess.onstatechange(new MIDIConnectionEvent(_midiAccess,this));})
	if (typeof this.onstatechange === 'function')
		// this.onstatechange(new MIDIConnectionEvent(_midiAccess,this));
		setTimeout(()=>{this.onstatechange(new MIDIConnectionEvent(_midiAccess,this));})

	// console.log("onstatechange",this)
	// console.log("done onstatechange?")
	return Promise.resolve(this);
}

function close_success() {
	console.log("shim-close", this.id)
	this.connection = "closed"
	if (this.type==="input" && this.onmidimessage) {
		this.onmidimessage = undefined;
	}
	// this.state = "disconnected"

	if (typeof _midiAccess.onstatechange === 'function')
		setTimeout(()=>{_midiAccess.onstatechange(new MIDIConnectionEvent(_midiAccess,this));})
	if (typeof this.onstatechange === 'function')
		setTimeout(()=>{this.onstatechange(new MIDIConnectionEvent(_midiAccess,this));})

	return Promise.resolve(this)
}
;[...fake_outputs,...fake_inputs].forEach(standardizePort)
function standardizePort(port){
	port.state = "connected"; // disconnected means it's "unplugged" from system
	port.connection = "closed";
	port.manufacturer = 'internal'; // easily identify virtual ports later

	port.open = function() {
		// console.log("open", this, this.connection)
		if (typeof this._open === 'function') {
			return Promise.resolve(this._open.bind(this)()).then(()=>{
				// console.log("_open done")
				return open_success.bind(this)()//.catch(e => setTimeout(() => { throw e; }))
			})
		} else {
			return open_success.bind(this)()//.catch(e => setTimeout(() => { throw e; }))
		}
	}.bind(port)

	port.close = function() {
		if (typeof this._close === 'function') {
			// must wrap with Promise resolve since Jazz-Midi doesn't return a Promise as per spec
			return Promise.resolve(this._close.bind(this)()).then(()=>{
				return close_success.bind(this)()
			})
		} else {
			return close_success.bind(this)()
		}
	}.bind(port)

	// other functions will call 'send' to play a note, so make sure noteOn's happen
	if (port.type==='output' && !port.send) port.send = function(){
		if (this.state!=="connected") {console.error(this.id, "send while closed", arguments[0]);return;}
		var data = arguments[0];
		var channel = data[0] & 0x0f
		var status = data[0] & 0xf0

		// console.log(this.id,"send",arguments[0], "status", status,"channel",channel, this.noteOn)
		// TODO: these not used and are confusing
		if (status==144 && typeof this.noteOn === "function") this.noteOn(channel, data[1], data[2])
		else if (status==128 && typeof this.noteOff === "function") this.noteOff(channel, data[1], data[2])
		else if (this._send) this._send.apply(this,arguments)
	}.bind(port)
}
navigator.requestMIDIAccess = function(options) {
	return new Promise(function(success, error) {
		if (typeof(_requestMIDIAccess)=="function")
			// if we are in a MIDI-enabled browser, get the MIDIAccess object and extend it with our own
			_requestMIDIAccess(options)
				.then(
					res=>extendMIDIAccess(res,success),
					err=>extendMIDIAccess(undefined, success)
					)
		else
			// if no native MIDI we construct a fake MIDIAccess object to extend anyway
			extendMIDIAccess(undefined, success)
	})
}
function extendMIDIAccess(realAccess, success) {
	var real_outputs_map = (realAccess ? realAccess.outputs : new Map([]))
	var fake_outputs_map = new Map(fake_outputs.map(p=>[p.id,p]))
	var new_outputs =  new Map([...real_outputs_map,...fake_outputs_map])
	var real_inputs_map = (realAccess ? realAccess.inputs : new Map([]))
	var fake_inputs_map = new Map(fake_inputs.map(p=>[p.id,p]))
	// var new_inputs =  new Map(function*() { yield* real_inputs_map; yield* fake_inputs_map; }());
	var new_inputs =  new Map([...real_inputs_map,...fake_inputs_map])
	// var new_inputs = (realAccess ? new Map(realAccess.inputs) : new Map([]))
	// realAccess.outputs.forEach((op)=>{console.log("op",op)})
	var midiAccess = {
		inputs:new_inputs,
		outputs:new_outputs,
		sysexEnabled:realAccess ? realAccess.sysexEnabled : false,
		onstatechange: null//typeof(midiAccess.onstatechange)=="function" ? midiAccess.onstatechange.bind(midiAccess) : null
	};
	if (realAccess) realAccess.onstatechange = function(){midiAccess && midiAccess.onstatechange && midiAccess.onstatechange(...arguments)}
	_midiAccess = midiAccess
	if (0) {
		// add a fake 'multiple' inputs
		var multiple_input = require('src/devices/multiple.js')(_midiAccess)
		standardizePort(multiple_input)
		// midiAccess.inputs.set(multiple_input.id, multiple_input);
	}

	success(midiAccess)

	window.test_outputConnected = function() {
		var test_output = {
			id:'browser-port', name:'browser-port',type:'output',
			_open: function() {
				console.log('browser-port')
				// this.bc = new BroadcastChannel('test_channel');
				// return Promise.resolve()
			},
			_send: function() {
				console.log('sent to test port')
			}
		}
		standardizePort(test_output)

		midiAccess && midiAccess.onstatechange && midiAccess.onstatechange({port:test_output})


		var test_input = {
			id:'browser-port-ins', name:'browser-port-in',type:'input',
			_open: function() {
				console.log('browser-port INPUT opened')
				// this.bc = new BroadcastChannel('test_channel');
				// return Promise.resolve()
			},
		}
		standardizePort(test_input)

		midiAccess && midiAccess.onstatechange && midiAccess.onstatechange({port:test_input})
		setInterval(()=>{
			test_input.onmidimessage && test_input.onmidimessage({data:[144,100,100],timeStamp: performance.now()})
		}, 100)
	}
	window.test_dodgyInput = function() {

		var test_input = {
			id:'dodgy-input', name:'dodgy-input',type:'input',
			_open: function() {
				console.log('dodgy-input INPUT opened')
			},
		}
		standardizePort(test_input)

		midiAccess && midiAccess.onstatechange && midiAccess.onstatechange({port:test_input})
		var on = false;
		setInterval(()=>{
			test_input.onmidimessage && test_input.onmidimessage({data:[128 + (on *16),50,0+on],timeStamp: performance.now()+1000000000})
			on = !on;
		}, 500)
	}

	// setTimeout(window.test_dodgyInput, 500)
}

// emulate emitting MIDIConnectionEvent
class MIDIConnectionEvent {
    constructor(midiAccess, port) {
        this.bubbles = false;
        this.cancelBubble = false;
        this.cancelable = false;
        this.currentTarget = midiAccess;
        this.defaultPrevented = false;
        this.eventPhase = 0;
        this.path = [];
        this.port = port;
        this.returnValue = true;
        this.srcElement = midiAccess;
        this.target = midiAccess;
        this.timeStamp = Date.now();
        this.type = 'statechange';
    }
}

})
