define(function(require, exports, module) {

	// these are both just fancy animated scrolling functions
	var cumulativeOffset = require('src/utils/cumulativeOffset.js')
	function isNumeric(n) {
	  return !isNaN(parseFloat(n)) && isFinite(n);
	}
	// thx (modified): https://stackoverflow.com/questions/17733076/smooth-scroll-anchor-links-without-jquery
	var scrollTo = (function() {
		var timer = undefined;
	    return function scrollTo(element, to, duration) {
	        if (duration <= 0) return;
	        if (timer) clearTimeout(timer);
	        if (!isNumeric(to)) {
	        	// can also pass in an element to scroll to
		        to = cumulativeOffset(to).top
		    }
	        // console.log("to:",to)
	        var difference = to - element.scrollTop;
	        var perTick = difference / duration * 10;

	        timer = setTimeout(function() {
	            // console.log(element.scrollTop,perTick)
	            element.scrollTop = (element.scrollTop + perTick)|0;
	            if (element.scrollTop === to) return;
	            scrollTo(element, to, duration - 10);
	        }, 10);
	    }
	})()

	module.exports = {scrollTo:scrollTo}
})
