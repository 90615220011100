define(function(require, exports, module) {
	const Action = require('src/actions/Action.js')
	const ModAction = require('src/actions/ModAction.js')
	const outputState = require('src/outputState.js')
	const createStore = require('src/utils/createStore.js')

	outputState.section = createStore(0)
	var nextSection = new Action({
	    name:'nextSection', type:"button",
	    title: "Select next section",
	    action: function() {
	  		setSection(outputState.section.getState()+1)
	    }
	})
	var prevSection = new Action({
	    name:'prevSection', type:"button",
	    title: "Select prev section",
	    action: function() {setSection(outputState.section.getState()-1)
	    }
	})

	var firstTimeUsingSection = true;
	// add the inital 'section' property to all loops
	function initSections() {
		firstTimeUsingSection = false;
		var currSection = outputState.section.getState();
		loopController.completedLoops.forEach(loop=>{
			loop.sections.push(currSection)
		})
	}
	// var isInitalMove = true; //
	// var draggedLoopsInitalSection = [];
	function setSection(s) {
		if (firstTimeUsingSection) initSections();
		// this needs to happen WHEN track is pressed otherwise will carry along tracks in other sections
		var tracksDown = outputState.trackHeldDown.map(t=>t.getState())
		console.log("tracksDown", tracksDown)
		loopController.completedLoops.forEach(loop=>{
			console.log(loop.id,loop.track)
			if (loop.hidden==false && tracksDown[loop.track]==true) {
				console.log("keeping loop",loop.id, loop.channel);
				// if (!isInitalMove)
				loop.sections.push(s)
			}
			console.log("loop hide:",!loop.sections.includes(s),loop.id, loop.channel);
			loop.setHidden(!loop.sections.includes(s))
		})
		outputState.section.setState(s)
	}

	module.exports = new ModAction({
	    octaveUp:{name:'sectUp',action:function(){nextSection.action();}},
	    octaveDown:{name:'sectDown',action:function(){prevSection.action();}},
	})

	// on release shift key
	module.exports.onDeactivate = function() {

	}

})
