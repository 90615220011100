define(function(require, exports, module) {
    const MIDI_INTERCEPTOR = require('src/MIDI_INTERCEPTOR.js');
    const actionsController = require('src/actionsController.js');
    const Action = require('src/actions/Action.js')
    module.exports = new Action({
        name:"delayedLoop",type:"button",
        title: "Start recording a loop, will wait for first note to begin recording",
        priority:51.2,
        action:function() {
            loopController.loopButton(undefined, true)

        },

    })
})
