define(function(require, exports, module) {


	require('src/loadMidiFile.js')


	function dragOverHandler(ev) {
	    console.log('File(s) in drop zone');
	    // console.log(ev)

	    if (ev.dataTransfer.items) {
	        // Use DataTransferItemList interface to access the file(s)
	        for (var i = 0; i < ev.dataTransfer.items.length; i++) {
	          // If dropped items aren't files, reject them
	          var item = ev.dataTransfer.items[i];
	          if (item.kind === 'file') {
	            var type = item.type;
	            if (type=='audio/mid') {
	                console.log('... file[' + i + '].name = ',item);
	            }
	          }
	        }
	  } else {
	    // Use DataTransfer interface to access the file(s)
	    for (var i = 0; i < ev.dataTransfer.files.length; i++) {
	      console.log('... file[' + i + '].name = ' + ev.dataTransfer.files[i].name);
	    }
	  }
	  // Prevent default behavior (Prevent file from being opened)
	  ev.preventDefault();
	}

	function dropHandler(ev) {
	  console.log('File(s) dropped');

	  // Prevent default behavior (Prevent file from being opened)
	  ev.preventDefault();

	  if (ev.dataTransfer.items) {
	    // Use DataTransferItemList interface to access the file(s)
	    for (var i = 0; i < ev.dataTransfer.items.length; i++) {
	      // If dropped items aren't files, reject them
	      if (ev.dataTransfer.items[i].kind === 'file') {
	        var file = ev.dataTransfer.items[i].getAsFile();
	        console.log('... file[' + i + '].name = ' + file.name);
	          if ( ev.dataTransfer.files[i].name.endsWith('.mid')) {
	            processFile(file)
	          }
	      }
	    }
	  } else {
	    // Use DataTransfer interface to access the file(s)
	    for (var i = 0; i < ev.dataTransfer.files.length; i++) {
	      console.log('... file[' + i + '].name = ' + ev.dataTransfer.files[i].name);
	      if ( ev.dataTransfer.files[i].name.endsWith('.mid')) {
	        processFile(file)
	      }
	    }
	  }
	}

	async function processFile(file) {
	    let fileArray = await file.arrayBuffer();
	    console.log('fileArray ', file.name, fileArray)
	    window.loadMidiFile(fileArray)
	}

	window.dropHandler = dropHandler;
	window.dragOverHandler = dragOverHandler;
})
