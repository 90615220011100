define(function(require, exports, module) {
	const Action = require('./Action.js')

	module.exports = new Action({
	    name:'refresh', type:"button",
	    title: "Clear the cache and reload page",
	    btn:$('#refreshButton'),
	    isRealAction:false,
	    action: function() {
	    console.log('deleting cache')
	    	caches.keys().then(function(cacheNames) {
	    	      return Promise.all(
	    	        cacheNames.map(function(cacheName) {
	    	          console.log('deleting cache', cacheName)
	    	          return caches.delete(cacheName);
	    	        })
	    	      );
	    	    })
		    	.then(()=>{
		    		navigator.serviceWorker.getRegistrations().then(function(registrations) {
			    		for(let registration of registrations) {
				    		registration.unregister()
			    		}
			    	})
		    	})
	    		.then(()=>{location.reload();})
	    }
	})
});
