define(function(require, exports, module) {
    var options = require('../options.js')
    const SideBar = require('../SideBar.js')
    var actionsController = require('../actionsController.js')
    const Action = require('./Action.js')
    module.exports = new Action({
        name:"options", type:"button",
        title:"Set options for behaviour.",
        btn:$('#settings'),
        isRealAction: false,
        action: function() {
            var is_open = this.SIDE_BAR.open_or_close()
        },
        startAction: function() {
            var changeableOptions = {
                'logMidi':{fullName:'Log Midi to console'},
                'useChannels':{},
                'programChange':{fullName:'Send thru program-change messages'},
                'forwardUnknownMidi':{fullName:'Send thru unknown (SysEx) MIDI'},
                'pauseWhenHidden':{fullName:'Pause when tab is hidden'},
                'closeOldInputs':{},
                'disableOtherOutputs':{},
                'perTrackOctaveShift':{fullName:'per-track octave'},
                'cleanUpEndNotes':{fullName:'clean up end notes'},
                'loopThingsThatArentNotes':{fullName:'loop things that aren\'t notes (i.e CC or pitch bend)'},
                'showCCSliders':{fullName:'show CC sliders', action:function() {
                    actionsController.actions.filter(a=>a.CCnumber!==undefined).forEach(a=>a.div.style.display=options.showCCSliders?'':'none')}
                },
                'showVolSliders':{fullName:'show Vol sliders', action:function() {
                    actionsController.actions.filter(a=>a.volumeChannel!==undefined).forEach(a=>a.div.style.display=options.showVolSliders?'':'none')}
                },
                'showCCValues':{fullName:'show CC messages at top', action:function(){
                    
                }},
                'showAdvancedControls':{fullName:'show Advanced buttons', action:function() {

                        actionsController.actions.filter(a=>options.advancedControlsList.includes(a.name)).forEach(a=>a.div.style.display=options.showAdvancedControls?'':'none')
                        // console.log(options.advancedControlsList.filter(name=>!actionsController.actions.filter(a=>options.advancedControlsList.includes(a.name)).map(a=>a.name).includes(name)))
                        // console.log(actionsController.actions.filter(a=>options.advancedControlsList.includes(a.name)).map(a=>a.name))
                        // console.log(actionsController.actions.map(a=>[options.advancedControlsList.includes(a.name),a.name]))
                    }
                },
                'muteTracksWhileLooping':{fullName:'enable quick track muting while looping'},
                'optimizeNotes':{fullName:'reduce lateny on notes'}

            }

            var htmlContent = '<div class="control-group"> <h2>Options</h2>'

            for (var k in changeableOptions) {
                htmlContent += '<label class="control control--checkbox">'
                if (changeableOptions[k].fullName) htmlContent += changeableOptions[k].fullName
                else htmlContent += k;
                htmlContent += '<input type="checkbox" ' + (options[k]==true ? "checked" : "") + ' id="optionChanger'+k+'"/> <div class="control__indicator"></div> </label>'
            }
            htmlContent += '</div>'
            htmlContent += '<button class="btn" style="margin-left:10px" id="options-save-button" title="save settings pernamently (in this browser)">save options</button>'
            htmlContent += '<button class="btn" style="margin-left:10px" id="options-clear-button" title="clear saved settings pernamently (in this browser)">clear saved</button>'

            this.SIDE_BAR = new SideBar('right', htmlContent, 'optionsSideBar', 300, ()=>{
                // this.action()
                for (var k in changeableOptions) {
                    $('#optionChanger'+k).onclick = function() {
                        var optionName = this.id.substr('optionChanger'.length)
                        if (options[optionName]==undefined) console.error('option changed that does not exist!',optionName)
                        options[optionName] = this.checked
                        changeableOptions[optionName].action && changeableOptions[optionName].action(optionName);
                        // console.log(this.id,options[this.id],);
                    }
                }
                $('#options-clear-button').onclick = ()=>{
                    window.localStorage.removeItem('options')
                }

                $('#options-save-button').onclick = ()=>{
                    var optionsToSave = {}
                    Object.keys(changeableOptions).forEach(k=>optionsToSave[k]=options[k])
                    console.log('saving',optionsToSave)
                    window.localStorage.setItem('options',JSON.stringify(optionsToSave))
                }
            })
        }
    })
})
