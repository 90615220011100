define(function(require, exports, module) {
	const createSVG = require('src/utils/createSVG.js');
	const cols = require('src/utils/cols.js');
	const midiCCshortNames = require('src/utils/midiCCshortNames.js');
	const createElement = require('src/utils/createElement.js');
	const options = require('src/options.js');
	const circumference = 100
	const radius = circumference / (2*Math.PI)
	const diameter = radius * 2;

	const allRotarys = {};
	var rotaryFadeInterval = null;
	var fadeInterval = 2;
	var fadeAmt = 4*fadeInterval/1000; // fade in 4 secs
	// module.exports = function createRotary(ccNum,channel,value) {
	module.exports = function createRotary(note) {
		if (options.showCCValues===false) return;
		// console.log(note.print())
		// FILTER BANK SELECT
		if (note.status()==176  && (note.data1()===0 || note.data1()===32)) return;

		var channel = note.channel();
		var ccNum, value;
		if (note.status()==224) {
			// PITCH BEND (special case)
			ccNum = -1;
			value = typeof(note.data2())=='undefined' ? 100 : note.data2();
		} else if (note.status()==176) {
			// PROGRAM CHANGE
			ccNum = note.data1() | 0;
			value = typeof(note.data2())=='undefined' ? 100 : note.data2();
		} else {
			return;
		}
		value = value&127
		var lookup = ccNum + "_" + channel
		// console.log(value, lookup, ccNum)
		// console.log(`createRotary(${ccNum},${channel},${value}) `,lookup)
		if (!(lookup in allRotarys)) {
			// console.log('new lookup', lookup, allRotarys)
			var name = midiCCshortNames[ccNum]
			var div = createElement('div',undefined,{class:'CC-box'})
			var name = createElement('span',name,{class:'CC-name'})
			var center_value = createElement('span',undefined,{class:'CC-value'})
			// need relative div to use absoulte positioning on center_value
			var center_div = createElement('div',undefined,{class:'CC-value-container'})
			var svg = createSVG('svg',`
			<path class="rotarySVG"
			    d="M18 2.0845
			      a ${radius} ${radius} 0 0 1 0 ${diameter}
			      a ${radius} ${radius} 0 0 1 0 -${diameter}"
			    transform = "translate(0, 0) rotate(180 18 18)"
			  />
			  `,{viewbox:"0 0 36 36",class:"rotarySVGBox"})
			document.getElementById('CC-message-box').appendChild(div)

			cols.styleElement(div,channel,4,'bg');
			// cols.styleElement(div,channel,9,'border');

			div.appendChild(center_div)
			div.appendChild(svg)
			div.appendChild(name)
			center_div.appendChild(center_value)
			// div.classList.add(cols.classNames[channel])
			// div.style.backgroundColor = cols.channel[channel]
			allRotarys[lookup] = div
		} else {
			var div = allRotarys[lookup]
		}
		div.style.opacity = 4
		setPercentage.bind(div)(value)
		if (rotaryFadeInterval===null)
			rotaryFadeInterval = setInterval(fade,fadeInterval);
	}
	function setPercentage(value) {
		// console.log("setPercentage",this.children[0],value)
		this.children[0].children[0].innerHTML = value.toString();
		this.children[1].style.strokeDasharray = Math.floor(value*100/127)+" 100";
		// this.children[0].setAttribute('stroke-dasharray',Math.floor(value)+" 100")
	}
	function fade(){
		for (let ccNum in allRotarys) {
			var newOpacity = parseFloat(allRotarys[ccNum].style.opacity)-fadeAmt
			// console.log(ccNum, newOpacity)
			if (newOpacity<=0) {
				document.getElementById('CC-message-box').removeChild(allRotarys[ccNum])
				delete allRotarys[ccNum]
				if (Object.keys(allRotarys).length==0) noRotarysLeft()
			} else {
				allRotarys[ccNum].style.opacity = newOpacity;

				allRotarys[ccNum].style.width = Math.min(40,100*newOpacity);
			}
		}
	}
	function noRotarysLeft() {
		clearInterval(rotaryFadeInterval)
		rotaryFadeInterval = null;
	}
})
