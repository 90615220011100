"use strict";
define(function(require){
    var options = require('src/options.js')
    var midiCC = require('src/utils/midiCC.js')
    var MIDI_OUT = require('src/MIDI_OUT.js')
    const outputState = require('src/outputState.js')
    var Action = require('src/actions/Action.js')

    // 1 - modu lation
    // 7 - volume
    // 73 - attack
    // 84 - portamento amount
    ;[0,1,2,4,5,7,8,10,12,13,73,84,91,93,72].forEach((CCnumber,idx)=>{
        new Action({
            name:"CC"+CCnumber,
            title: CCnumber+":"+midiCC[CCnumber],
            type: 'slider-vertical',min:0,max:127, startValue:127,
            CCnumber:CCnumber,hidden:!options.showCCSliders,
            group:'CCsliders',
            priority: -80-idx,
            action: function(value) {
                value = this.value;
                // console.log("Cc"+CCnumber, value )

                if (outputState.anyTrackHeldDown.getState()==true) { // if any chennels are being pressed down
                    outputState.trackHeldDown.forEach((down, chan)=>{
                        down = down.getState()
                        if (down) {
                                MIDI_OUT.send([0xB0+chan, CCnumber, value])
                        }
                    })
                } else {
                    MIDI_OUT.send([0xB0+loopController.currChan, CCnumber, value])
                }
                // this.update()
            },update:function() {
                this.text.textContent = "CC"+CCnumber//+":"+this.btn.value;
            }
        })
    })

    options.channelInstruments.forEach((_,idx)=>{
        new Action({
            name:"Vol"+idx,
            type: 'slider-vertical',min:0,max:127, startValue:26,
            volumeChannel:idx,hidden:!options.showVolSliders,
            group:'VolumeSliders',
            priority: -90-idx,
            action: function() {
                let value = this.value;
                console.log("Vol"+this.volumeChannel, value)
                if (typeof(value)=="number" && typeof(this.volumeChannel)=="number")
                    MIDI_OUT.send([0xB0+(this.volumeChannel|0), 7, value|0])
                else
                    console.error("volume slider undefined value:", value,typeof(value),"or channel:", typeof(this.volumeChannel),this.volumeChannel, this)

            },update:function() {
                this.text.textContent = "Vol"+this.volumeChannel//+":"+this.btn.value;
            }
        })
    })
})
