// define all global helper methods here
window.$ = document.querySelector.bind(document);


if (!Array.from) {
    Array.from = function (object) {
        'use strict';
        return [].slice.call(object);
    };
}


// https://tc39.github.io/ecma262/#sec-array.prototype.includes
if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, 'includes', {
    value: function(valueToFind, fromIndex) {

      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      // 1. Let O be ? ToObject(this value).
      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If len is 0, return false.
      if (len === 0) {
        return false;
      }

      // 4. Let n be ? ToInteger(fromIndex).
      //    (If fromIndex is undefined, this step produces the value 0.)
      var n = fromIndex | 0;

      // 5. If n ≥ 0, then
      //  a. Let k be n.
      // 6. Else n < 0,
      //  a. Let k be len + n.
      //  b. If k < 0, let k be 0.
      var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

      function sameValueZero(x, y) {
        return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
      }

      // 7. Repeat, while k < len
      while (k < len) {
        // a. Let elementK be the result of ? Get(O, ! ToString(k)).
        // b. If SameValueZero(valueToFind, elementK) is true, return true.
        if (sameValueZero(o[k], valueToFind)) {
          return true;
        }
        // c. Increase k by 1. 
        k++;
      }

      // 8. Return false
      return false;
    }
  });
}


// Make iteration easier
NodeList.prototype.forEach = Array.prototype.forEach;

;(function (arr) {
  arr.forEach(function (item) {
    if (item.hasOwnProperty('prepend')) {
      return;
    }
    Object.defineProperty(item, 'prepend', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function prepend() {
        var argArr = Array.prototype.slice.call(arguments),
          docFrag = document.createDocumentFragment();

        argArr.forEach(function (argItem) {
          var isNode = argItem instanceof Node;
          docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
        });

        this.insertBefore(docFrag, this.firstChild);
      }
    });
  });
})([Element.prototype, Document.prototype, DocumentFragment.prototype]);

// Helpers for <input> & <select> boilerplate
HTMLSelectElement.prototype.getValue = function() {
  return this.options[this.selectedIndex] ? this.options[this.selectedIndex].getAttribute('value') : undefined;
}
HTMLSelectElement.prototype.setByValue = function(value) {
  [...this.options].filter(option=>option.value==value).forEach(option=>option.selected = true)
}
HTMLInputElement.prototype.setByValue = function(value) {this.value = value}
HTMLInputElement.prototype.getByValue = function(value) {return parseFloat(this.value)}

// linking Input value to variables React-style (see createStore.js)
HTMLSelectElement.prototype.linkRecieveValue = HTMLInputElement.prototype.linkRecieveValue = function(store) {
  if (this.unsubFromLink) {this.unsubFromLink(); this.unsubFromLink = undefined;}
  if (!store || !store.getState) console.error('link to non-store', this, store)
  this.setByValue(store.getState())
  this.unsubFromLink = store.subscribe((state)=>{
        this.setByValue(state)
    })
}
HTMLSelectElement.prototype.link = HTMLInputElement.prototype.link = function(store) {
  this.linkRecieveValue(store)
  this.onchange = function() {
    store.setState(parseInt(this.value))
  }
}

HTMLSelectElement.prototype.clear = function() {
  for (var i = this.options.length; i >= 0; i--) {
      this.remove(i);
    }
}

HTMLSelectElement.prototype.addOption = function(name, attrs) {
  var option = document.createElement('option')
  if (name!==undefined) option.innerHTML = name;
  if (attrs) for (var key in attrs) option.setAttribute(key,attrs[key]);
  this.appendChild(option)
}

HTMLSelectElement.prototype.addMultipleOptions = function(arr) {
  var docFrag = document.createDocumentFragment();
  arr.forEach(val=>{
    var option = document.createElement("option");
    option.value = val[0];
    option.textContent = val[1];
    docFrag.appendChild(option);
  });
  this.appendChild(docFrag)
}
