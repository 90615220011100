define(function(require, exports, module) {
    const mod = require('src/mod.js')
    const options = require('src/options.js')
    const outputState = require('src/outputState.js')
    const undoManager = require('src/undoManager.js')
    const scroller = require('src/utils/scroller.js')

    const TRACKIE = {
        active: false,
        selectionIndex: null,
        selectionAvailable: [],
        currSelected: [],
        anyChanSelected: false,
        updateSelectionAvailable: function() {
            TRACKIE.anyChanSelected = false;
            TRACKIE.selectionAvailable = [];
            outputState.trackHeldDown.forEach((on, idx) =>{
                on = on.getState()
                if (on) {
                    TRACKIE.anyChanSelected = true;
                    Array.prototype.push.apply(TRACKIE.selectionAvailable,loopController.completedLoops.filter(loop=>loop.track==idx && loop.hidden!==true))
                }
            })
            // if no tracks held down use all available loops
            if (!TRACKIE.anyChanSelected) {
                TRACKIE.selectionAvailable = loopController.completedLoops.filter(loop=>loop.hidden!==true);
            }
            TRACKIE.selectionAvailable = TRACKIE.selectionAvailable.sort((a,b)=>a.id-b.id);
            TRACKIE.updateView();
        },
        activate:function(){
            TRACKIE.active = true;
            TRACKIE.selectionIndex = null;
            TRACKIE.updateSelectionAvailable();
        },
        deactivate:function(){
            // console.log("deactivate", loopController.completedLoops)
            var to_delete = []; // don't modify array during forEach!
            loopController.completedLoops.forEach(loop=>{
                // console.log("is dead?" , loop.pendingchange)

                if(loop.pendingchange==1)
                    loop.muted=true;
                if(loop.pendingchange==-1)
                    loop.muted=false;
                if(loop.pendingchange==2) {
                    to_delete.push(loop.id)
                }
                loop.pendingchange=0
             })
            undoManager.addToQueue(to_delete)
            to_delete.forEach(loop_id=>loopController.removeLoop(loop_id));
            TRACKIE.loopsToPlay.clear()
            TRACKIE.loopsToPause.clear()
            TRACKIE.active = false;
            TRACKIE.updateView();
        },
        // this one should always happen
        chanButton: function(chan, on) {
            TRACKIE.selectionIndex = null;
            if (1 || TRACKIE.active) TRACKIE.updateSelectionAvailable();
        },
        octaveUpButton: function() {
            console.log('octaveUpButton', TRACKIE.selectionAvailable)
            if (TRACKIE.selectionIndex===null) TRACKIE.selectionIndex = 0;
            else TRACKIE.selectionIndex = mod(TRACKIE.selectionIndex+1, TRACKIE.selectionAvailable.length);
            TRACKIE.updateView()
        },
        octaveDownButton: function() {
            console.log('octaveDownButton', TRACKIE.selectionAvailable)
            if (TRACKIE.selectionIndex===null) TRACKIE.selectionIndex = TRACKIE.selectionAvailable.length-1;
            else TRACKIE.selectionIndex = mod(TRACKIE.selectionIndex-1, TRACKIE.selectionAvailable.length);
            TRACKIE.updateView()
        },
        loopsToPause:new Set([]),
        loopsToPlay:new Set([]),
        loopButton: function(value, doubleTap) {

            // return false so we can do a different action with loop button (e.g. doubleTap)
            if (TRACKIE.currSelected.length==0) return false;
            if (!(doubleTap>0)) {
                // toggle current selected from play/pause
                TRACKIE.currSelected.forEach(loop=>{
                    TRACKIE.loopsToPlay.add(loop);
                    TRACKIE.loopsToPause.delete(loop);
                    loop.pendingchange = -1;
                })
            } else {
                // double tap means unmute immediately
                if (doubleTap==1) {
                    TRACKIE.currSelected.forEach(loop=>{
                        TRACKIE.loopsToPlay.delete(loop);
                        TRACKIE.loopsToPause.delete(loop);
                        loop.muted = false;
                        loop.pendingchange = 0;
                    })
                }
                // let's try triple tap = solo (on shift release)
                if (doubleTap>1) {
                    // console.log('doubleTap loop')
                    loopController.completedLoops.forEach(loop=>{
                        // console.log('loop',loop.id,TRACKIE.currSelected.includes(loop))
                        loop.pendingchange = 0;
                        if (TRACKIE.currSelected.includes(loop)) {
                            // loop.muted = false;
                            loop.pendingchange = -1;
                        } else {
                            // loop.muted = true;
                            loop.pendingchange = 1;
                        }
                    })
                }
            }
            return true;
        },
        undoButton: function(value, doubleTap) {
            // return false so we can do a different action with undo button
            // if we aren't holding any channels down
            if (TRACKIE.currSelected.length==0 && outputState.trackHeldDown.every(a=>a.getState()===false)) return false;
            if (!(doubleTap>0)) {
                // toggle current selected from play/pause
                TRACKIE.currSelected.forEach(loop=>{
                    TRACKIE.loopsToPlay.delete(loop);
                    TRACKIE.loopsToPause.add(loop);
                    loop.pendingchange = 1;
                })
            } else {
                // if it's a double tap mute the selected loop immediately
                if (doubleTap==1) {
                    TRACKIE.currSelected.forEach(loop=>{
                        TRACKIE.loopsToPlay.delete(loop);
                        TRACKIE.loopsToPause.delete(loop);
                        loop.muted = true;
                        loop.pendingchange = 0;
                    })
                }
                // triple tap = delete (on release shift button - so can be reversed!)
                if (doubleTap>1) {
                    TRACKIE.currSelected.forEach(loop=>{
                        TRACKIE.loopsToPlay.delete(loop);
                        TRACKIE.loopsToPause.add(loop);
                        loop.pendingchange = 2;
                    })
                }
            }
            return true;
        },
        updateView() {
            // console.log("updateView", TRACKIE.selectionIndex, TRACKIE.selectionAvailable)
            loopController.completedLoops.forEach(loop=>{
                loop.loopView.div.classList.remove('trackSelected')
            })
            TRACKIE.currSelected = [];
            if (TRACKIE.active==false || (!TRACKIE.anyChanSelected && TRACKIE.selectionIndex===null)) return;
            // if selecting a specific loop
            if (TRACKIE.selectionIndex!==null) {
                var idx = 0;
                TRACKIE.selectionAvailable.forEach(loop=>{
                    if (idx==TRACKIE.selectionIndex) {
                        loop.loopView.div.classList.add('trackSelected')
                        TRACKIE.currSelected.push(loop)
                        if (options.autoScroll) {
                            // console.log(cumulativeOffset(loop.loopView.div))
                            scroller.scrollTo($('#content'), loop.loopView.div, 100);
                        }
                    }
                    idx += 1;
                })
            } else {
                // we are selecting based on channel
                var lowestDownLoop = null;
                TRACKIE.selectionAvailable.forEach(loop=>{
                    loop.loopView.div.classList.add('trackSelected')
                    TRACKIE.currSelected.push(loop)
                    lowestDownLoop = loop;
                })
                // scroll to the neares loop
                if (lowestDownLoop) {
                    // console.log("scrolling to",lowestDownLoop,cumulativeOffset(lowestDownLoop.loopView.div).top )
                    scroller.scrollTo($('#content'), lowestDownLoop.loopView.div, 100);
                }
            }
            console.log('currSelected', TRACKIE.currSelected)
        },
    }
    outputState.trackHeldDown.forEach(t=>t.subscribe(TRACKIE.chanButton))
    module.exports = TRACKIE;
})
