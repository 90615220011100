define(function(require, exports, module) {
    const highlight = require('src/utils/highlight.js')
    const options = require('src/options.js')
    const doWhenDOMLoaded = require('src/utils/doWhenDOMLoaded.js')
    doWhenDOMLoaded(function(){
        $('#save-output-tracks').onclick = saveOutputTracks
        function saveOutputTracks() {
            console.log("saveOutputTracks",options.channelsUsed,options.channelInstruments)
            console.log("saveOutputTracks",options.channelsUsed,options.channelInstruments)
            localStorage.setItem('save-output-instruments', JSON.stringify(outputState.channelInstruments.map(i=>i.getState())));
            localStorage.setItem('save-output-channels', JSON.stringify(outputState.trackChannels.map(c=>c.getState())));

            highlight('#fb8874',$('#midi-out-track-select'))
            highlight('#fb8874',$('#midi-out-channel-select'))
            highlight('#fb8874',$('#instrument-select'))
        }

        const outputState = require('src/outputState.js')
        if (window.localStorage) {
            // Well it's a lot of code just to load two saved arrays but we gotta be strict on this data!
            var saved_output_channels = localStorage.getItem("save-output-channels");
            if (saved_output_channels) {
                try {
                    saved_output_channels = JSON.parse(saved_output_channels)
                    // console.log("loaded output channels",saved_output_channels)

                    if (saved_output_channels &&
                        typeof(saved_output_channels)=='object' &&
                        saved_output_channels.every(a=>Number.isInteger(a)) &&
                        saved_output_channels.every(a=>(a>=0 && a<=15))
                        ) {
                        saved_output_channels.forEach((value, idx)=>{
                            outputState.trackChannels[idx].setState(value)
                            // console.log(idx, value)
                        });
                    }
                } catch (e) {
                    console.error(e)
                }
             }
            var saved_output_instruments = localStorage.getItem("save-output-instruments");
            if (saved_output_instruments) {
                try {
                    saved_output_instruments = JSON.parse(saved_output_instruments);
                    // console.log("loaded output instruments",saved_output_instruments)

                    if (saved_output_instruments &&
                        typeof(saved_output_instruments)=='object' &&
                        saved_output_instruments.every(a=>Number.isInteger(a)) &&
                        saved_output_instruments.every(a=>(a>=-1 && a<=255))
                        ) {
                        saved_output_instruments.forEach((value, idx)=>{
                            if (!outputState.channelInstruments[idx]) return console.error('extra track',value, idx)
                            // outputState.channelInstruments[idx].setState(value);
                            // outputState.trackInstrumentStore(idx).setState(value);
                            outputState.channelInstruments[idx].setState(value);
                            // console.log(idx, value)
                        })
                        // options.channelInstruments = saved_output_instruments;
                    }
                }
                catch (e) {
                    console.error(e)
                }
            }
            if (0) {
                console.log("saved_output_channels")
                console.log(saved_output_channels)
                console.log(outputState.trackChannels.map(c=>c.getState()))
                console.log("saved_output_instruments")
                console.log(saved_output_instruments)
                console.log(outputState.channelInstruments.map(c=>c.getState()))
            }
        }

        // TBH this should probably be moved?
        const MIDI_OUT = require('src/MIDI_OUT.js')
        outputState.channelInstruments.forEach((store,idx)=>{
            store.subscribe(newInst=>{
                // console.error('changeINST',idx,newInst,outputState.channelInstruments[idx].getState());
                if (newInst!==undefined && newInst>=0) {
                    if (options.programChange) MIDI_OUT.changeInstrument(idx,newInst);
                }
            })
        })
    })
})
