define(function(require, exports, module) {
module.exports = function highlight(color, element) {
    if (!element) element = document.body;
    // if element was recently highlighted clear the timeout to change it's color
    if (element.bg_timer!==undefined) {clearTimeout(element.bg_timer); element.bg_timer = undefined;}

    element.style.backgroundColor = color || "pink";

    return new Promise(function(success, error) {
        element.bg_timer = setTimeout(()=>{
            element.bg_timer = undefined;
            if (element==document.body)
                element.style.backgroundColor = "#333";
            else
                element.style.backgroundColor = "";
            success();
        },100)
    })
}
})
