define(function(require, exports, module) {
var options = require('../options.js')
var highlight = require('../utils/highlight.js')
const Action = require('./Action.js')
module.exports = new Action({
    name:"sync",type:"toggle",
    title:"ON: loops will synchronise, OFF: loops can be created with any duration",
    priority:8,
    action:function(){options.sync = !options.sync;this.update()},
    // update is for when a key triggers it we might need to update html element
    update:function(){this.toggle.checked = options.sync; highlight('white',this.btn)},
    startAction:function() {this.toggle.checked = options.sync}
})
})
