define(function(require, exports, module) {

	if (window.Touch===undefined || window.TouchEvent===undefined ||
		Touch.length !== 1 || TouchEvent.length !== 1) {
		window.Touch = function(c) {return Object.assign(c, {})}
		window.TouchEvent = function(type, c) {
			var evt = new Event(type, {bubbles:c.bubbles, cancelable:c.cancelable, composed: c.composed})
			delete c.bubbles; delete c.cancelable; delete c.composed;
			return Object.assign(evt, c);}
	}
	function mouseToTouch(el) {

		const convertMouse = (evt)=>{
			// console.log('stopped event:', evt)
			if (evt.button!==0) return;

			evt.preventDefault()
			evt.stopImmediatePropagation()
			el.dispatchEvent(
				changeMouseEventToTouchEvent(evt)
			)
			return false;
		}
		const onMouseUp = (evt)=>{
			document.removeEventListener('mouseup', onMouseUp)
			document.removeEventListener('mousemove',convertMouse)
			return convertMouse(evt)
		}
		el.addEventListener('mousedown',evt=>{
			document.addEventListener('mouseup', onMouseUp)
			document.addEventListener('mousemove',convertMouse)
			return convertMouse(evt)
		})

		function changeMouseEventToTouchEvent(evt) {
			var touch = new window.Touch({
				identifier:9999,
				target:el,
				clientX:evt.clientX,
				clientY:evt.clientY,
				screenX:evt.screenX,
				screenY:evt.screenY,
				pageX:evt.pageX,
				pageY:evt.pageY,
			})
			var touchEvent = new window.TouchEvent(
				evt.type==='mousedown' ? 'touchstart' :
				evt.type==='mouseup' ? 'touchend' :
				evt.type==='mousemove' ? 'touchmove' : undefined
			, {
			    cancelable: true,
			    bubbles: true,
			    composed: true,
			    touches: [touch],
			    targetTouches: [touch],
			    changedTouches: [touch]
			});
			return touchEvent;
		}
	}

	module.exports = mouseToTouch;
})
