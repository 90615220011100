"use strict"
define(function(require, exports, module) {

    let loaded = false;
    window.addEventListener('requirejs-loaded', ()=>{
    	loaded = true;
    })
    let doWhenJSLoaded = callback => {
    	if (loaded == true) callback()
    	else window.addEventListener('requirejs-loaded', callback)
    }
	module.exports = doWhenJSLoaded

})
