"use strict";
define(function(require, exports, module) {

    const MIDIFile = require('lib/MidiFile.js');
    const doWhenJSLoaded = require('src/utils/doWhenJSLoaded.js')
	const outputState = require('src/outputState.js')

	window.loadMidiFile = function(arrayBuffer) {
		var byteArray = new Uint8Array(arrayBuffer);
		var midiFile = new MIDIFile(byteArray)
		console.log('===================================')
        console.log('midiFile.header.getFormat()', midiFile.header.getFormat())
        console.log('midiFile.header.getTracksCount()', midiFile.header.getTracksCount())
        console.log(midiFile.tracks)
		console.log('===================================')

        console.log('midiFile.header.getTimeDivision()', midiFile.header.getTimeDivision())
        console.log('midiFile.header.getTickResolution()', midiFile.header.getTickResolution())
        let tickResolution = midiFile.header.getTickResolution();
        let tickPerBeat = midiFile.header.getTicksPerBeat()
        // let scaleT = 1.0;
        let scaleT = tickResolution * (tickPerBeat/100) / 1000 ;

        if(midiFile.header.getTimeDivision() === MIDIFile.Header.TICKS_PER_BEAT) {
            console.log('midiFile.header.getTicksPerBeat()', midiFile.header.getTicksPerBeat())
            // scaleT = midiFile.header.getTicksPerBeat() / 960
            console.log('scaleT', scaleT)
        } else {
            console.log('midiFile.header.getSMPTEFrames()', midiFile.header.getSMPTEFrames())
            console.log('midiFile.header.getTicksPerFrame()', midiFile.header.getTicksPerFrame())
        }
		console.log('===================================')

        // MIDI events retriever
        console.log('midiFile', midiFile)

        console.log(window.loopController)


        let t = performance.now();
        midiFile.tracks.forEach((track, track_idx) => {

	        var track_hasdata = midiFile.getTrackEvents(track_idx).reduce((curr, acc) => acc.type==8 || curr, false)
	        var track_duration = midiFile.getTrackEvents(track_idx).reduce((curr, acc) => acc.delta * scaleT + curr, 0)
	        var track_channels = midiFile.getTrackEvents(track_idx).reduce((curr, acc) => curr.add(acc.channel), new Set())

	        console.log('track ', track_idx, 'dur:"', track_duration, track_hasdata)
	        track_channels.delete(undefined)
	        var numchannels = track_channels.size
	        console.log('channels:', track_channels,'num', numchannels)

	        if (numchannels==1) {
	        	let channel = [...track_channels][0]
	        	console.log('setting channel', channel)
	        	outputState.trackChannels[outputState.currentTrack.getState()].setState(channel)

	        	console.log(outputState.currentChannel.getState(channel))
	        }

	        if (!track_hasdata) {
	        	console.log('skipping....')
	        	return;
	        }
	        t -= track_duration
	        window.loopController.loopButton(t)

	        let printThisMany = 10;
	        midiFile.getTrackEvents(track_idx).forEach(e=>{
	            t += e.delta * scaleT
	            if (printThisMany && --printThisMany) console.log(e.delta, t, e)
	            if (e.type==8) {
	                // console.log(t, e)
	                var n = Note.create(e.subtype * 16 + e.channel, e.param1, e.param2).setEchoed(1).setThru(1)
	                // console.log(n.channel(), n.print())
	                window.loopController.note(n, t)
	            }
	        })
	        
	        window.loopController.loopButton(t)
	    })
	}

	window.loadMidiUrl = function(url) {
	    var oReq = new XMLHttpRequest();
	    oReq.open("GET", url, true);
	    oReq.responseType = "arraybuffer";

	    oReq.onload = function (oEvent) {
	      var arrayBuffer = oReq.response; // Note: not oReq.responseText
	      doWhenJSLoaded(()=>{
	      	console.log('JS LOADED')
		      loadMidiFile(arrayBuffer)
	      })
		}

	    oReq.send(null);
	}
	if ('test'===true) 
		loadMidiUrl("/midi/ACIDDANCE.mid")
	// loadMidiUrl("/midi/HipHop-05.mid")
})