define(function(require, exports, module) {
	// all this should do is fire a resize event when a right-side scrollbar appears (so elements can resize accordingly)
	const doWhenDOMLoaded = require('src/utils/doWhenDOMLoaded.js')
	doWhenDOMLoaded(function(){
		var iframe = document.createElement('iframe');
		iframe.id = 'hacky-scrollbar-resize-listener';
		iframe.style.cssText = 'height:0;background-color: transparent; position: absolute; width:100%;'
		iframe.title='Intentionally blank'
		iframe.setAttribute('aria-hidden',true)

		function dispatchResizeEvent() {
			iframe.contentWindow.addEventListener('resize', function() {
				try {
					// we'll just listen for this event wherever we'd normally use resize event
					window.dispatchEvent(new Event('viewport-resize'));
					// console.log('iframe RESIZE')
				} catch(e) {
					console.error(e)
				}
			})
		}
		iframe.onload = dispatchResizeEvent;
		document.getElementById('content').appendChild(iframe)

		document.getElementById('hacky-scrollbar-resize-listener').addEventListener('resize', dispatchResizeEvent)

	})
})
