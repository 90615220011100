define(function(require, exports, module) {
const createElement = require('src/utils/createElement.js')
let focused = undefined;
let overlay = createElement('div',undefined,{id:'tutorial-overlay'})
overlay.style.cssText = `
background:rgba(0,0,0,0.5);
display:none;
width:100%; height:100%;
position:absolute; top:0; left:0; z-index:99998;
`
document.body.appendChild(overlay)
module.exports = function focus(element) {
	console.log('focus:',element, focused)
	if (focused) {
		focused.style.zIndex = focused.getAttribute('z-index-style-saved')
		focused.style.position = focused.getAttribute('position-style-saved')
		focused.removeAttribute('z-index-style-saved')
		focused.removeAttribute('position-style-saved')
	}
	if (element) {
		overlay.style.display = ''
		element.setAttribute('z-index-style-saved', element.style.zIndex)
		element.style.zIndex = '99999'
		// console.log(window.getComputedStyle(element).getPropertyValue('position'))
		if (window.getComputedStyle(element).getPropertyValue('position')==='static') {
			element.setAttribute('position-style-saved', element.style.position)
			element.style.position = 'relative'
		}
		focused = element;
	} else {
		overlay.style.display = 'none'
	}
	console.log(overlay,overlay.style.cssText)
	element && console.log(element,element.style.cssText)
}

overlay.onclick = module.exports.bind(undefined,undefined)
})