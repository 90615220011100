define(function(require, exports, module) {
// const setupDialog = require('../setupDialog.js')
const Action = require('./Action.js')
module.exports = new Action({
    name:'setup',type:"button",
    title: "Help with setting up MIDI.",
    // priority:-1,
    btn:$('#setupMIDI'),
    isOpen:false,
    action: function() {
    	this.isOpen = !this.isOpen;
    	$('#mainHelp').style.display = !this.isOpen ? 'none' : '';
    	if (this.isOpen) {
    		recurseDecendants($('#mainHelp'),(el)=>{
                // lazy loading of images
                if (el.getAttribute('data-src')){
                    // only set src when we want to load them
                    el.src = el.getAttribute('data-src');
                    el.removeAttribute('data-src');
                }
    		})
    	}
    },
	startAction() {
		// this.action()
	}
})
function recurseDecendants(el, fn) {
	;[ ...el.children ].forEach(childEl=>{
		recurseDecendants(childEl, fn)
		fn(childEl)
	})
}
})