define(function(require, exports, module) {
var options = require('src/options.js')
var Loop = require('src/Loop.js')
var timeShifter = require('src/TimeShifter.js')
var TRACKIE = require('src/TRACKIE.js')
var syncManager = require('src/syncManager.js') // syncManager is unfinished
var undoManager = require('src/undoManager.js')
var outputState = require('src/outputState.js')
const createRotary = require('src/utils/createRotary.js');
const scroller = require('src/utils/scroller.js')
const messageBar = require('src/messageBar.js');

var LoopController = function(MIDI_OUT) {
	// hacks till can refactor properly:
	// options = thisoptions;
	// if this is a function all next notes will go into it
	this.MIDI_OUT = MIDI_OUT;

    this.activeLoop = null;
    this.completedLoops = [];
    this.keymod = new Array(outputState.trackChannels.length).fill(0); // add to incoming notes
    if (typeof(options.drumChannel)=="number") this.keymod[options.drumChannel] = -12; // set drum channel to lower starting octave so lowest key is lowest drum!
    this.keymod = this.keymod.map(n => n+options.startOctave) // hack for lowering all by octave
    this.lastNotes = [];
    this.lastNotesPos = 0;
    this.lastN = 10; // keep track of last N notes
    this.fixedLength = null; // if not null fix loop lengthx
    // if we press note C4 on channel 1 and then octave up and change channel,
    // we shouldn't send a note off for note C5 on the channel 2
    // so keep track of which note off to do for each key
    this.noteState = new Uint16Array(256).fill(65536);
    this.noteChan = new Uint8Array(256);
    this.pedalChan = null;
    // count in by pressing loop button twice
    this.countInTicks = null; // will count to 4 then loop
    this.countInTimer = null; // used to setInterval
    setInterval(()=>{
        this.poll()
    }
    , 10)

    this.currTrack = outputState.currentTrack.getState()
    outputState.currentTrack.subscribe(newTrack=>{
        this.currTrack = newTrack;
    }, true)

    this.currChan = outputState.currentChannel.getState()
    outputState.currentChannel.subscribe(newChannel=>{
        this.currChan = newChannel;
    }, true)
    // for testing colors
    // setTimeout(()=>{
    //     (new Array(16).fill(0)).forEach((a,idx)=>{
    //         console.log(idx)
    //         this.currChan = idx;
    //         this.startLoop(performance.now()+idx*1000)
    //         this.endLoop(performance.now()+500+idx*1000)
    //     })
    // }, 100)
};
LoopController.prototype.getById = function getById(id) {
    if (this.activeLoop !== null && this.activeLoop.id==id) return this.activeLoop;
    else return this.completedLoops.find(l=>l.id==id);
}
LoopController.prototype.startLoop = function startLoop(time, waitForNote) {
    if (this.activeLoop !== null) {
        console.error("you shouldn't be making another loop before the first one finishes properly!")
        return null;
    }

    var newLoop = new Loop(time, this.currChan, this.MIDI_OUT, waitForNote, this.currTrack, outputState.section.getState());
    if (!waitForNote) {
        this.lastNotes.forEach((n)=>{
            if (n[0]!==undefined && n[0]>time-100)
            newLoop.note(n[0],n[1])
            // thinking about it
        })
    }
    if (options.autoScroll) scroller.scrollTo($('#content'),newLoop.loopView.div, 100) //.scrollTo(0,document.getElementById('content').scrollHeight);
    return newLoop;
}
//     var syncTime = undefined;
//     if (options.sync && this.completedLoops.length > 0) {
//         var length = this.activeLoop.length();
//         var min_length = Infinity;
//         this.completedLoops.forEach((l)=>{min_length=Math.min(min_length, l.length())})
//         // console.log("min length:", min_length, "this length:", length);
//         if (min_length!==Infinity)
//             syncTime = this.activeLoop.startTime + this.calculateSyncLength(min_length,length)
//         // console.log("startTime",this.activeLoop.startTime)
//         // console.log("synclen",syncTime-this.activeLoop.startTime)
//     }
// }
// function getSyncTime(time)
LoopController.prototype.getMinLength = function getMinLength() {
    var min_length = Infinity;
    this.completedLoops.forEach((l)=>{min_length=Math.min(min_length, l.length())});
    return min_length
}
LoopController.prototype.getMaxLength = function getMaxLength() {
    var max_length = -Infinity;
    this.completedLoops.forEach((l)=>{max_length=Math.max(max_length, l.length())});
    return (max_length===-Infinity ? null : max_length);
}   
    
LoopController.prototype.getSyncTime = function getSyncTime(time, loop, log) {
    // used to start/end loops and by drawCAnvas every frame
    // var log = false;
    var syncTime = undefined;
    var syncManagerLength = syncManager.getSyncLength(); // null if no clock source
    var min_length = Infinity;
    var length = loop.length(time);
    if (options.sync && this.completedLoops.length > 0) {
        min_length = this.getMinLength();
        log && console.log("min length:", min_length, length);
    } else if (syncManagerLength!==null) {
        min_length = syncManagerLength;
        log && console.log("using syncManagerLength", syncManagerLength)
    }
    if (min_length!==Infinity)
        syncTime = loop.startTime + this.calculateSyncLength(min_length,length)
    log && console.log("startTime",loop.startTime)
    log && console.log("length",length)
    log && console.log("synctime",syncTime, min_length)
    return syncTime;
}
LoopController.prototype.endLoop = function endLoop(time) {
    var log = false;
    log && console.log("======================")
    if (this.activeLoop === null)
        return log && console.log("no active loop to end!");
    if (this.activeLoop.waitForNote===true) {
        this.deleteActiveLoop();
        return;
    }
    log && console.log("start time    :",this.activeLoop.startTime);
    log && console.log("end time (now):",time);
    log && console.log("ending Loop len:", time-this.activeLoop.startTime);
    log && console.log("note min/max   :", Math.min(...this.activeLoop.notes.map(n=>n[0])), Math.max(...this.activeLoop.notes.map(n=>n[0])));

    var syncTime = this.getSyncTime(time, this.activeLoop, true);
    log && console.log("synced loop len:",syncTime);
    log && console.log("======================")
    var endTime = (syncTime===undefined || isNaN(syncTime)) ? time : syncTime;
    this.activeLoop.end(endTime);
    this.completedLoops.push(this.activeLoop);
    if (this.completedLoops.length===1) syncManager.firstLoopSet(this.activeLoop.endTime-this.activeLoop.startTime);
    this.activeLoop = null;
    // if (options.autoScroll) document.getElementById('content').scrollTo(0,document.getElementById('content').scrollHeight);
    // if (options.autoScroll) document.getElementById('content').scrollTop = document.getElementById('content').scrollHeight;
    if (options.autoScroll) scroller.scrollTo(document.getElementById('content'),document.getElementById('content').scrollHeight,100);
}

// function to be called when you press the loop button twice or more
LoopController.prototype.countIn = function countIn(time) {
    if (this.countInTicks === null) {
        if (this.activeLoop === null) return;
        this.countInTicks = [this.activeLoop.startTime];
        this.deleteActiveLoop()
    }
    this.countInTicks.push(time);
    if (this.countInTicks.length>=3) {
        var tik = this.countInTicks;
        var avg_beat = (tik[tik.length-1] - tik[0])/(tik.length-1);
        // console.log(tik.map((a)=>a-tik[0]))
        clearInterval(this.countInTimer)
        this.countInTimer = setTimeout(() => {
            highlight('green');
            this.countInTimer = null;
            if (this.activeLoop===null) {
                this.activeLoop = this.startLoop(timeShifter.now());
                undoManager.loopButtonPressed();
            }
        }, avg_beat*2);
        setTimeout(() => {
            highlight('yellow');
        },avg_beat);
        // console.log("timer set for t+",avg_beat*2)
        this.countInTicks = null;
        highlight('white');
    } else {
        var tik = this.countInTicks;
        var avg_beat = (tik[tik.length-1] - tik[0])/(tik.length-1);
        // console.log("will cancel in ", avg_beat*1.5)
        this.countInTimer = setTimeout(() => {
            btns_div['loop'].innerHTML = 'cancelled!'
            setTimeout(() => {btns_div['loop'].innerHTML = 'loop';},1000);
            this.countInTicks = null;
        },avg_beat*1.5); // timing should not be worse than 1.5 times out
    }
}
LoopController.prototype.loopButton = function loopButton(t, waitForNote) {
    if (document.hidden===true) return; // because bad things happen with infinte loops D:
    if (timeShifter.warp==0) {
        messageBar.setMessage('un-pause to loop')
        return; // same reason
    }
    waitForNote = !!waitForNote;
    console.log("loopButton t",t)
    t = t===undefined ? performance.now() : t
    // console.log("timeShifter t", timeShifter.lastTime, timeShifter.currTime)
    let time = timeShifter.now(t);
    console.log("loopButton time",time)

    if (this.countInTimer!==null && this.countInTicks!==null && this.countInTicks.length>=3) {
        console.log('count in already started! (ignoring) \nthis.countInTimer',this.countInTimer)
        highlight('red')
    } else if (this.countInTicks!==null) {
        this.countIn(time)
    } else if (this.activeLoop === null) {
        console.log("starting")
        this.activeLoop = this.startLoop(time, waitForNote);
        undoManager.loopButtonPressed();
    } else if (options.countInTime===false ||
            this.activeLoop.notes.length!==0 ||
            this.activeLoop.startTime+options.countInTime <= time)
    {
        // console.log('endLoop')
        this.endLoop(time);
    } else if (this.activeLoop !== null) {
        // start the count in process
        highlight('lightgreen')
        this.countIn(time)
    }
}
LoopController.prototype.resetButton = function resetButton() {
    let t = timeShifter.now();
    let maxLen = this.getMaxLength()


    timeShifter.fastForward(1000)

    t = timeShifter.now();
    var muteStatus = this.completedLoops.map(l=>l.muted);
    console.log(muteStatus)
    this.completedLoops.forEach(l=>l.muted = true)
    this.poll()
    this.completedLoops.forEach((l,idx)=>l.muted = muteStatus[idx])

}
LoopController.prototype.deleteActiveLoop = function() {
    console.log('deleteActiveLoop')
    if (this.activeLoop) {
        this.removeLoop(this.activeLoop.id)
    }
}
LoopController.prototype.redoButton = function redoButton() {
    var loop = undoManager.popFromQueue()
    if (loop===null) {
        console.log('redo: no more undo\'s to redo')
        return;
    }
    loop.recreateLoopView();
    // catch it up to the current tracker
    loop.poll(timeShifter.now()); // just don't use the notes
    if (loop.finished!==true) {
        if (this.activeLoop!==null) {console.error("this shouldn't happen! restoring",loop, this.activeLoop); return}
        this.activeLoop = loop;
    } else {
        this.completedLoops.push(loop)
    }
}
LoopController.prototype.undoButton = function undoButton() {
    console.log("undoButton active::", this.activeLoop, "\n",this.completedLoops)
    if (this.activeLoop !== null) {
        var channelOfDeleted = this.activeLoop.channel;
        undoManager.addToQueue(this.activeLoop.id)
        this.deleteActiveLoop();
        return channelOfDeleted;
    } else if (this.countInTicks!==null) {
        this.countInTicks = null;
        highlight('red')
    } else if (this.completedLoops.length>0) {
        var last_loop = this.completedLoops[this.completedLoops.length-1]
        var channelOfDeleted = last_loop.channel;
        undoManager.addToQueue(last_loop.id)
        this.removeLoop(last_loop.id)
        return channelOfDeleted;
    }
}
LoopController.prototype.changeOctave = function changeOctave(shift) {
    if (options.perTrackOctaveShift)
        this.keymod[this.currTrack] += shift;
    else
        this.keymod = this.keymod.map(k=>k+shift)
    return this.keymod[this.currTrack];
}



LoopController.prototype.note = function(n, timeStamp) {
    var time = timeStamp || performance.now();
    var note_val = n.data1()
    // if (timeStamp) console.log(note +"\t:"+(time-timeStamp), timeStamp);
    if (this.keymod[this.currTrack]===undefined) console.log("MODNOTE ERR:", this.currTrack, this.currChan,n.isNoteOn(), note_val, n.velocity() )
    // take into account octave setting
    var modnote = note_val
    if (!n.thru()) modnote += this.keymod[this.currTrack];
    if (n.isNoteOn() && (modnote<0 || modnote>256)) return console.error("note out of range!",modnote, note_val);
    var t = timeShifter.now(time);;
    // if (note.timewarp()) {

    // } else {
    //     console.log('skipping timewarp')
    // }

    // console.log('note', t, ' timewarp:', note.timewarp())
    var channel = this.currChan;
    if (n.isNoteOn()) {
        if (!n.echoed()) this.MIDI_OUT.noteOn(channel, modnote, n.velocity())
        this.noteState[note_val] = modnote;
        this.noteChan[note_val] = channel;
        // console.log(n.print(),'setting note for', channel, modnote)
    } else {
        if (this.noteState[note_val]!==65536) {
            // if either the octave setting or channel was changed while a key was held down, we must note-off the original note when lifted
            channel = this.noteChan[note_val]
            modnote = this.noteState[note_val]
            // console.log(n.echoed(), n.print(),'was a note for', channel, modnote)
        }
        if (!n.echoed()) this.MIDI_OUT.noteOff(channel, modnote, 0)
        this.noteState[note_val] = 65536;
        this.noteChan[note_val] = this.currChan;
    }
    n = n.setEchoed(true)
    this.lastNotes[this.lastNotesPos++ % this.lastN] = [t, n.setData1(modnote)]
    if (this.activeLoop === null)
        return;
    this.activeLoop.note(t, n.setData1(modnote))
}
LoopController.prototype.non_note = function non_note(note, timeStamp) {
    let timewarp_on = true;
    if (timewarp_on) {
        var t = timeShifter.now(timeStamp || performance.now());
    } else {
        var t = timeStamp || performance.now();
        // console.log('skipping timewarp')
    }

    // console.log("non_note",[note.status() + (this.currChan & 0x0F), note.data1(), note.data2()])
    // try {

    var isAnyChannelEvenHeldDown = outputState.anyTrackHeldDown.getState();
    if (note.status()==192 && note.data1()==64) {
        // sustain pedal
        var on = (note.data2()>=64);
        if (on) this.pedalChan = this.currChan;
        else {
            if (this.pedalChan!==null) note.setChannel(this.pedalChan)
            else note.setChannel(this.currChan);
            this.pedalChan = null;
        }
    } else if (isAnyChannelEvenHeldDown) {
        outputState.trackHeldDown.forEach((trackState, idx) =>{
            var on = trackState.getState()
            var heldChannel = outputState.trackChannels[idx].getState()
            if (on) {
                note = note.setChannel(heldChannel)
                // var newStatus = note.status + (heldChannel & 0x0F);
                this.MIDI_OUT.send(note.toArray(),0)

                createRotary(note)

            }
        })
    } else {
        // console.log("note chan:", note.data0(),note.status(),note.channel(), note.print())
        note = note.setChannel(this.currChan)
        // console.log("note chan:", note.data0(),note.status(),note.channel(), note.print())
        // console.log('NOTE ting:', note.hex(), (note&0xFF0000).hex(),(0xEF0000).hex())
        this.MIDI_OUT.send(note.toArray(),0)
        createRotary(note)
    }

    if (this.activeLoop === null)
        return;
    if (options.loopThingsThatArentNotes)
        this.activeLoop.non_note(t, note);
}
LoopController.prototype.poll = function poll() {

    var t = timeShifter.now();
    var ccToDisplay = []; // we want to do it after sending the timing-crucial MIDI data
    this.completedLoops.forEach((loop)=>{

        var chan = loop.channel;
        loop.poll(t).forEach(n=>{
            var note = n[1];
            note = note.setChannel(chan)
            this.MIDI_OUT.send(note.toArray())
            if (note.status()==176) {
                // for CC messages like modWheel, and pitchBend
                // console.log([n[2] + (chan & 0x0F), n[3], n[4]])
                // this.MIDI_OUT.send([n[2] + (chan & 0x0F), n[3], n[4]])
                ccToDisplay.push( note )
            }
        })
    })
    // console.log(this.fixedLength, this.activeLoop, this.activeLoop && this.activeLoop.length())
    if (this.fixedLength!==null && this.activeLoop!==null) {
        if (this.activeLoop.length()>this.fixedLength) this.endLoop()
    }
    ccToDisplay.forEach(createRotary)
}
LoopController.prototype.removeLoop = function(id) {

	// TODO : reveiew
	var loop;
	if (this.activeLoop!==null && this.activeLoop.id == id) {
        console.log('deleting active loop', id)
	    loop = this.activeLoop;
        this.activeLoop = null;
	} else {
		var loop_idx = this.completedLoops.findIndex(l => l.id == id)
        console.log("found loop id:",id,'at',loop_idx)
		if (loop_idx!=-1)
			loop = this.completedLoops.splice(loop_idx,1)[0]
	}
	if (loop===undefined) {console.error('deleting nonexisting loop id:',id); return;}
    console.log('deleteing', loop)
	// var loop_y = loop.y;
	loop.loopView.delete();
	loop.delete();

	// this.completedLoops.forEach((l) => {if (l.y>loop_y) l.y--});
	// this.activeLoop && this.activeLoop.y>loop_y && this.activeLoop.y--;
    console.log(this.completedLoops)
    if (this.completedLoops.length===0) syncManager.noFirstLoopAnymore();
}

LoopController.prototype.setFixedLength = function setFixedLength(on) {
    console.log('set fixedLength', on)
    if (this.completedLoops.length===0) {
        messageBar.setMessage('no loops yet')
        on = false;
    }
    if (on) {
        this.fixedLength = this.getMaxLength()
        console.log(this.fixedLength)
        return true;
    } else {
        console.log(this.fixedLength)
        this.fixedLength = null;
        return false;
    }
}
LoopController.prototype.calculateSyncLength = function calculateSyncLength(len, min) {
    // var div = Math.log2(len/min) - 0.41503749927884376 + 0.5; //* 1.5/(1+1/3);
    // old method: based on loop length progressively allow running overtime
    // var div = Math.log2(len/min) - 0.45 + 0.5; //* 1.5/(1+1/3);
    // new method: fixed ms overtime allowed
    var div = Math.log2((len)/min) +0.2 -0.5 //* 1.5/(1+1/3);
    // console.log(len, min, "div",div," => 1/(2^"+Math.round(div),')=', 1/Math.pow(2,Math.round(div)))
    // div = len/(2**Math.round(div))
    div = len/Math.pow(2,Math.round(div))
    return div;
}
module.exports = LoopController;
})
