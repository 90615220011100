define(function(require, exports, module) {
	const createSVG = require('src/utils/createSVG.js');
	const cols = require('src/utils/cols.js');
	const createElement = require('src/utils/createElement.js');
	const outputState = require('src/outputState.js');
	var fader = null;
	var fadeInterval = 2;
	var fadeAmt = 4*fadeInterval/1000; // fade in 4 secs
	var captialize = true;
	// console.log('msgbox',document.getElementById('general-message-box'))
	outputState.currentTrack.subscribe((currentTrack)=>{
	    messageBar.setMessage(`track-${currentTrack+1} selected`,{track:currentTrack})
	})
	const messageBar = {
		setMessage:function(message, options) {
			// console.log(options)
			options = options || {};
			if (captialize) message = message.charAt(0).toUpperCase()+message.slice(1)
			var msgBox = document.getElementById('general-message-box')
			var msgBoxText = document.getElementById('general-message-box-inner')
			msgBox.opacity = 4.0;
			msgBox.style.opacity = msgBox.opacity;
			if (options.channel===undefined && options.track!==undefined) {
				options.channel = outputState.trackChannels[options.track].getState()
			}
			if (options.channel===undefined) options.channel = outputState.currentChannel.getState();
			if (options.channel!==undefined) {
				cols.styleElement(msgBox, options.channel, 4, 'bg')
				cols.styleElement(msgBox, options.channel,9	,'border');
				msgBox.style.borderWidth = '1px'
				msgBox.style.borderStyle = 'solid'
				// msgBox.style. = '1px'
			} else {
				msgBox.style.borderWidth = '0px'
				msgBox.style.borderStyle = ''
				cols.unstyleElement(msgBox)
			}
			msgBoxText.innerHTML = message;

			if (!options.dontFade) {
				if (fader===null)
					fader = setInterval(fade,fadeInterval);
			} else {
				if (fader!==null) clearInterval(fader)
			}
		}
	}
	function fade(){
		var msgBox = document.getElementById('general-message-box')
		var newOpacity = parseFloat(msgBox.opacity)-fadeAmt
		// console.log('opacity')
		// console.log(ccNum, newOpacity)
		if (newOpacity<=0) {
			document.getElementById('general-message-box-inner').innerHTML = ""
			clearInterval(fader);
			fader = null;
		} else {
			msgBox.opacity = newOpacity;
			msgBox.style.opacity = newOpacity;
		}
	}
	module.exports = messageBar

})
