define(function(require, exports, module) {
var cols =  ['#b2c225','#fecc2f','#f9a228','#f6621f','#db3838','#ee657a','#a363d9','#40a4d8','#5af5d8','#5af5d8','#5af5d8','#5af5d8']
var style_names = ["Red",
"Pink",
"Purple",
"Deep Purple",
"Indigo",
"Blue",
"Light Blue",
"Cyan",
"Teal",
"Green",
"Light Green",
"Lime",
"Yellow",
"Amber",
"Orange",
"Deep Orange",
"Brown"].map(col=>col.replace(' ','-').toLowerCase())

// var style_matrix = [1,2,3,4,5,6,7,8,9].map(num=>{

// 	var stylez = a.map((col, idx)=>{
// 		console.log(col)
// 		document.body.innerHTML += "<p id='col"+idx+"' style='width:100px;height:100px;' class='mdc-bg-"+col+"-"+num*100+"'>"+col+"</p>"
// 		var e = document.getElementById('col'+idx)
// 		var style = document.defaultView.getComputedStyle(e,null)['background-color'];
// 		console.log(e,style)
// 		e.parentElement.removeChild(e)
// 		return style
// 	})
// 	console.log(stylez)
// 	return stylez
// })
var style_matrix = [
	["rgb(255, 205, 210)", "rgb(248, 187, 208)", "rgb(225, 190, 231)", "rgb(209, 196, 233)", "rgb(197, 202, 233)", "rgb(187, 222, 251)", "rgb(179, 229, 252)", "rgb(178, 235, 242)", "rgb(178, 223, 219)", "rgb(200, 230, 201)", "rgb(220, 237, 200)", "rgb(240, 244, 195)", "rgb(255, 249, 196)", "rgb(255, 236, 179)", "rgb(255, 224, 178)", "rgb(255, 204, 188)", "rgb(215, 204, 200)"],
	["rgb(239, 154, 154)", "rgb(244, 143, 177)", "rgb(206, 147, 216)", "rgb(179, 157, 219)", "rgb(159, 168, 218)", "rgb(144, 202, 249)", "rgb(129, 212, 250)", "rgb(128, 222, 234)", "rgb(128, 203, 196)", "rgb(165, 214, 167)", "rgb(197, 225, 165)", "rgb(230, 238, 156)", "rgb(255, 245, 157)", "rgb(255, 224, 130)", "rgb(255, 204, 128)", "rgb(255, 171, 145)", "rgb(188, 170, 164)"],
	["rgb(229, 115, 115)", "rgb(240, 98, 146)", "rgb(186, 104, 200)", "rgb(149, 117, 205)", "rgb(121, 134, 203)", "rgb(100, 181, 246)", "rgb(79, 195, 247)", "rgb(77, 208, 225)", "rgb(77, 182, 172)", "rgb(129, 199, 132)", "rgb(174, 213, 129)", "rgb(220, 231, 117)", "rgb(255, 241, 118)", "rgb(255, 213, 79)", "rgb(255, 183, 77)", "rgb(255, 138, 101)", "rgb(161, 136, 127)"],
	["rgb(239, 83, 80)", "rgb(236, 64, 122)", "rgb(171, 71, 188)", "rgb(126, 87, 194)", "rgb(92, 107, 192)", "rgb(66, 165, 245)", "rgb(41, 182, 246)", "rgb(38, 198, 218)", "rgb(38, 166, 154)", "rgb(102, 187, 106)", "rgb(156, 204, 101)", "rgb(212, 225, 87)", "rgb(255, 238, 88)", "rgb(255, 202, 40)", "rgb(255, 167, 38)", "rgb(255, 112, 67)", "rgb(141, 110, 99)"],
	["rgb(244, 67, 54)", "rgb(233, 30, 99)", "rgb(156, 39, 176)", "rgb(103, 58, 183)", "rgb(63, 81, 181)", "rgb(33, 150, 243)", "rgb(3, 169, 244)", "rgb(0, 188, 212)", "rgb(0, 150, 136)", "rgb(76, 175, 80)", "rgb(139, 195, 74)", "rgb(205, 220, 57)", "rgb(255, 235, 59)", "rgb(255, 193, 7)", "rgb(255, 152, 0)", "rgb(255, 87, 34)", "rgb(121, 85, 72)"],
	["rgb(229, 57, 53)", "rgb(216, 27, 96)", "rgb(142, 36, 170)", "rgb(94, 53, 177)", "rgb(57, 73, 171)", "rgb(30, 136, 229)", "rgb(3, 155, 229)", "rgb(0, 172, 193)", "rgb(0, 137, 123)", "rgb(67, 160, 71)", "rgb(124, 179, 66)", "rgb(192, 202, 51)", "rgb(253, 216, 53)", "rgb(255, 179, 0)", "rgb(251, 140, 0)", "rgb(244, 81, 30)", "rgb(109, 76, 65)"],
	["rgb(211, 47, 47)", "rgb(194, 24, 91)", "rgb(123, 31, 162)", "rgb(81, 45, 168)", "rgb(48, 63, 159)", "rgb(25, 118, 210)", "rgb(2, 136, 209)", "rgb(0, 151, 167)", "rgb(0, 121, 107)", "rgb(56, 142, 60)", "rgb(104, 159, 56)", "rgb(175, 180, 43)", "rgb(251, 192, 45)", "rgb(255, 160, 0)", "rgb(245, 124, 0)", "rgb(230, 74, 25)", "rgb(93, 64, 55)"],
	["rgb(198, 40, 40)", "rgb(173, 20, 87)", "rgb(106, 27, 154)", "rgb(69, 39, 160)", "rgb(40, 53, 147)", "rgb(21, 101, 192)", "rgb(2, 119, 189)", "rgb(0, 131, 143)", "rgb(0, 105, 92)", "rgb(46, 125, 50)", "rgb(85, 139, 47)", "rgb(158, 157, 36)", "rgb(249, 168, 37)", "rgb(255, 143, 0)", "rgb(239, 108, 0)", "rgb(216, 67, 21)", "rgb(78, 52, 46)"],
	["rgb(183, 28, 28)", "rgb(136, 14, 79)", "rgb(74, 20, 140)", "rgb(49, 27, 146)", "rgb(26, 35, 126)", "rgb(13, 71, 161)", "rgb(1, 87, 155)", "rgb(0, 96, 100)", "rgb(0, 77, 64)", "rgb(27, 94, 32)", "rgb(51, 105, 30)", "rgb(130, 119, 23)", "rgb(245, 127, 23)", "rgb(255, 111, 0)", "rgb(230, 81, 0)", "rgb(191, 54, 12)", "rgb(62, 39, 35)"]
]
// filter col 5
style_matrix.forEach(row=>row.splice(5,1))
style_names.splice(5,1)
 module.exports = {
	keys:style_matrix[5],
	names:style_names,
	channel:style_matrix[3],
	border:style_matrix[7],
	classNames:style_names.map(name=>'.mdc-bg-'+name+'-500'),
	styleElement: function(el, channel, shade, type) {
		if (!type_classes.includes(type)) return console.error('type invalid',type);
		el.classList.remove(...channel_classes,...shade_classes[type]);
		el.classList.add(`channel-${channel}`,`${type}-shade-${shade}`)
	},
	unstyleElement(el) {
		el.classList.remove(...channel_classes,...shade_classes['bg'], ...shade_classes['border'], ...shade_classes['text']);
	}
}
const channel_classes = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15].map(n=>`channel-${n}`)
const type_classes = ['bg','border','text']
const shade_classes = {};
type_classes.forEach(type=>{
	shade_classes[type] = [0,1,2,3,4,5,6,7,8,9].map(n=>`${type}-shade-${n}`)
})
// console.log(style_matrix)

if (!true && "make a color palette at bottom") {
	document.body.innerHTML += `<div style="float:left;height:10px;width:10px;font-size:10px;"></div>`
	style_matrix[0].forEach((col,idx)=>{
		document.body.innerHTML += `<div style="float:left;height:10px;width:10px;font-size:10px;">${idx}</div>`
	})
	document.body.innerHTML += `<div style="float:left; clear:both;"></div>`
	style_matrix.forEach((row,idx)=>{
		document.body.innerHTML += `<div style="float:left;height:10px;width:10px;font-size:10px;">${idx}</div>`
		row.forEach(el=>{
			document.body.innerHTML += `<div style="float:left;height:10px;width:10px;background-color:${el};"></div>`
		})
		document.body.innerHTML += `<div style="float:left; clear:both;"></div>`
	})
}
if (!true && "generate css file") {
	var css_text = ``;
	document.body.innerHTML += `<div style="float:left;height:10px;width:10px;font-size:10px;"></div>`
	style_names.forEach((col,idx)=>{
		document.body.innerHTML += `<div style="float:left;height:10px;width:10px;font-size:10px;">${idx}</div>`
	})
	document.body.innerHTML += `<div style="float:left; clear:both;"></div>`
	var default_level = 4;
	[1,2,3,4,5,6,7,8,9].map(num=>{
		document.body.innerHTML += `<div style="float:left;height:10px;width:10px;font-size:10px;">${num}</div>`
		style_names.forEach((col,idx)=>{
			document.body.innerHTML +=
				`<div id='col-${idx}-${num}' style='float:left;width:10px;height:10px;' class='mdc-bg-${col}-${num*100}'></div>`


			var e = document.getElementById(`col-${idx}-${num}`)
			var style = document.defaultView.getComputedStyle(e,null)['background-color'];
			css_text += `.channel-${idx}.bg-shade-${num} {background-color:${style};}\n`
			css_text += `.channel-${idx}.border-shade-${num} {border-color:${style};}\n`
			css_text += `.channel-${idx}.text-shade-${num} {color:${style};}\n`
			// if (num==default_level)
			// 	css_text += `.channel-${idx} {background-color:${style};}`
		})
		document.body.innerHTML += `<div style="float:left; clear:both;"></div>`
	})
	console.log(css_text)
}

})

/*
Red
Pink
Purple
Deep Purple
Indigo
Blue
Light Blue
Cyan
Teal
Green
Light Green
Lime
Yellow
Amber
Orange
Deep Orange
Brown
*/
