define(function(require, exports, module) {
	"use strict"
	// this doesn't work for now due to some timing precision stuff :'(
	const timeShifter = require('src/TimeShifter.js')
	const createStore = require('src/utils/createStore.js')
	const createElement = require('src/utils/createElement.js')
	const MODE_SLAVE = 0;
	const MODE_MASTER = 1;
	class SyncManager {
	    constructor() {
	    	this.mode = MODE_MASTER;
	    	this.off = true;



	        this.timingLast = null;
	        this.smoothedTempo = null;
	        this.N = createStore(20);
	        this.Nmax = 1000;
	        this.lastNClocks = new Array(this.Nmax).fill(null);
	        this.lastNClocksIdx = 0;
	        this.dom = {
	        	element: $('#titletext'),
	        	bpm:$('#bpm'),
	        	onOff:$('#syncOnOff')
	        }
	        this.blinkN = 0;
	        this.lockedInSpeed = undefined;
	        this.lockedInWarp = undefined;
	        this.PPQN = createStore(24);
	        if (!this.off) {
	        	// enable dom elements
		    	this.dom.bpm.style.display='none'
		    	this.dom.onOff.style.display='none'
		        var PPQN_select = createElement('select')
		        $('#title').appendChild(PPQN_select)
		        var i = 0; while(i++<90) PPQN_select.addOption(i, i);
		        PPQN_select.link(this.PPQN)


		        var N_select = createElement('select')
		        $('#title').appendChild(N_select)
		        var i = 0; while(i++<90) N_select.addOption(i, i);
		        N_select.link(this.N)

		        this.dom.onOff.onchange = ()=>{this.off = !this.dom.onOff.checked; console.log("toggle syncManager", this.off)}
		    }
	    }
	    blink() {
	    	var timeoutInterval = 60;
	    	if (this.lastTimeout===undefined || performance.now()>this.lastTimeout+timeoutInterval*2) {
	    		this.blinkN++;
	    		if ((this.blinkN % 4) === 0 || ( this.blinkN % this.PPQN.getState() ) === 0) {
			    	this.dom.element.innerHTML = ((this.blinkN % this.PPQN.getState() ) === 0) ? 'midilooper com' : 'midilooper:com';
			    	// console.log(this.getSmoothedTempo(), this.blinkN % 4)
			    	setTimeout(()=>{
				    	this.dom.element.innerHTML = 'midilooper.com'
			    	}, timeoutInterval)
			    	this.lastTimeout = performance.now()
	    		}

		    }
		    this.dom.bpm.innerHTML = (60000 / (this.getSmoothedTempo()*this.PPQN.getState())).toFixed(1)+":"+this.N.getState() /*ms between quarter notes*/  /*per minute (in ms)*/
	    }
	    onClockMessage(timeStamp) {
	    	if (this.mode===MODE_SLAVE) {

		        if (this.timingLast!==null) {
		        	var interval = performance.now()-this.timingLast
		            this.lastNClocks[this.lastNClocksIdx++ % this.N.getState()] = interval;
		            // console.log(interval.toFixed(4), this.lastNClocks.filter(a=>a!==null).map(a=>a.toFixed(1)).toString())
		        }
		        this.timingLast = timeStamp;
		    	if (this.lockedInSpeed!==undefined) this.keepWarpLockedIn(this.getSmoothedTempo())
	    	}
            this.blink();
	    }
	    getSmoothedTempo() {
	    	if (this.off) return;
	    	if (this.mode===MODE_MASTER) {
	    		return this.lockedInSpeed;
	    	} else {

		    	var numNotNull = 0;
		    	var sum = 0;
		    	for(var i=0; i<this.N.getState(); i++) {
		    		var t = this.lastNClocks[i];
		    		// console.log(i,t)
		    		if (t!==null) {
		    			numNotNull++;
		    			sum += t;
		    		}
		    	}
		    	// console.log(numNotNull)
		    	if (numNotNull===0) return null;
		    	var newTempo = sum/numNotNull;
		    	return newTempo;
	    	}
	    }
	    getSyncLength(desiredLength) {
	    	if (this.off) return null;
	    	this.smoothedTempo = this.getSmoothedTempo();
	    	return this.smoothedTempo * this.PPQN.getState();
	    }
	    firstLoopSet(loopLength) {
	    	if (this.off) return;
	    	if (this.mode===MODE_MASTER) {
	    		if (typeof(loopLength)!=="number") return console.error('not a number',loopLength)
	    		var minBeatInterval = 400;
	    		while(loopLength>minBeatInterval) loopLength /= 2;
	    		loopLength /= 24;
	    		this.lockedInSpeed = loopLength; // TODO
	    		this.lockedInWarp = timeShifter.warp
	    		console.log("LOOPLENGTH", this.lockedInSpeed)
	    		if (this.lockinTimer===undefined) this.lockinTimer = setTimeout(this.keepWarpLockedIn.bind(this),this.lockedInSpeed)
	    	} else {

		    	var currTempo =  this.getSmoothedTempo();
		    	if (currTempo!==null) {
			    	this.lockedInSpeed = currTempo;
			    	this.lockedInWarp = timeShifter.warp
			    }
		    	console.log("useCurrentSpeedAsSync",currTempo, this.lockedInSpeed, this.lockedInWarp)
	    	}
	    }
	    noFirstLoopAnymore() {
	    	if (this.off) return;
	    	console.log('noFirstLoopAnymore')
	    	this.lockedInSpeed = undefined
	    	this.lockedInWarp = undefined
	    }
	    keepWarpLockedIn(newTempo) {
	    	if (this.off) return;
	    	// console.log(timeShifter.warp, this.lockedInWarp, newTempo, this.lockedInSpeed);
	    	if (this.mode===MODE_SLAVE) {
		    	timeShifter.warp = this.lockedInWarp * this.lockedInSpeed/newTempo;
	    	} else {
	    		if (this.lockedInSpeed===undefined) return
	    		var adjustSpeedForWarp = this.lockedInWarp * this.lockedInSpeed / timeShifter.warp;
	    		this.lockinTimer = setTimeout(this.keepWarpLockedIn.bind(this),adjustSpeedForWarp)
	    		// if (adjustSpeedForWarp>500) { // tooo long an interval between clock messages will make most keyboards freak out
	    		// 	return
	    		// }
	    		document.dispatchEvent(new CustomEvent('testMIDIMessage',{detail:[248]}))
	    		// console.log('should be refired after',this.lockedInSpeed)
	    		// console.log(this)
	    	}
	    }
	}

	var syncManager = new SyncManager();
	module.exports = syncManager;
})
