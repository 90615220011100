define(function(require, exports, module) {
	const Action = require('./Action.js')
	module.exports = new Action({
	    name:"prevInstrument", type:"button",
	    container:$('#instrument-select-container'),
	    title: "select previous instrument",
	    btn:$('#prevInstrument'),
	    action: function() {
	    	$('#instrument-select').selectedIndex =
		    	Math.max(0,Math.min($('#instrument-select').options.length-1,$('#instrument-select').selectedIndex-1))
	    	$('#instrument-select').onchange();
	    }
	})
})
