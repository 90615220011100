define(function(require, exports, module) {
    "use strict"
    const createElement = require('src/utils/createElement.js')
    const doWhenDOMLoaded = require('src/utils/doWhenDOMLoaded.js')
    const mouseToTouch = require('src/utils/mouseToTouch.js')
    // store list of them so we can make sure 2 are not on the same side at once
    var lefts = [];
    var rights = [];

    var resizeLines = {'left':null, 'right':null}
    Object.keys(resizeLines).forEach(side=>{
        var resize_line;
        // all below creates an invisible div that resizes the sidebar
        resize_line = createElement('div',undefined,{class:'crop-line '+side});
        document.body.appendChild(resize_line)
        mouseToTouch(resize_line)
        resize_line.style.display = 'none';
        var prevX = null;
        resize_line.myWidth = 0;
        var parent = null;
        let min_width = side=='right' ? 5 : 0;
        let limitWidth = (w)=>Math.min(document.body.clientWidth - resizeLines[parent.other_side].myWidth - 20, Math.max(min_width,w));
        resize_line.activate = function(initWidth, parentSideBar) {
            console.log('activate',initWidth, parentSideBar)
            resize_line.style.display = 'block'
            resize_line.myWidth = initWidth;
            resize_line.style[side] = (resize_line.myWidth - (side=='right' ? 5 :1))+"px";
            parent = parentSideBar;
        }
        resize_line.deactivate = function() {
            resize_line.style.display = 'none'
            resize_line.myWidth = 0
        }
        var resize = (e)=>{
            // console.log(e.clientX)
            e.preventDefault()
            var movementX = e.changedTouches[0].clientX - prevX;
            prevX = e.changedTouches[0].clientX;
            resize_line.myWidth += movementX * (side=='right' ? -1 : 1);
            var tempWidth = limitWidth(resize_line.myWidth);
            resize_line.style[side] = (tempWidth - (side=='right' ? 5 :1))+"px";
            parent.resize(tempWidth)
        }
        resize_line.addEventListener('touchstart',(e)=>{
            prevX = e.touches[0].clientX;
            e.preventDefault()
            window.addEventListener('touchmove',resize, {passive: false})
            window.addEventListener('touchend', function mouseup(e){
                e.preventDefault()
                resize_line.myWidth = limitWidth(resize_line.myWidth);
                window.removeEventListener('touchmove', resize)
                window.removeEventListener('touchend', mouseup)
            })
        }, {passive: false})
        resizeLines[side] = resize_line;
    

    })



    var SideBar = function(side, content, id, width, onCreated) {
        this.onCreated = onCreated
        this.width = width || 200
        id = id || ('SideBar'+side)
        // side = 'left'
        if (!(side=='left' || side=='right')) return console.error('SideBar side must be left or right');
        if (side=='left') {lefts.push(this);} else {rights.push(this)}
        this.active = false;
        this.id = id;
        this.side = side;
        this.other_side = this.side=='left' ? 'right' : 'left'
        this.init(content);
    }

    SideBar.prototype.open_or_close = function(){
        if (!this.active) this.open();
        else this.close();
        return this.active
    }
    SideBar.prototype.open = function() {
        if (this.side=='left') lefts.forEach(sidebar=>sidebar.active===true && sidebar.close())
        if (this.side=='right') rights.forEach(sidebar=>sidebar.active===true && sidebar.close())
        this.sideDiv.style.display = ''
        // document.getElementById('app').style.width = 'calc(100%-200px)'
        this.active = true;
        this.resize(this.width)
        this.resize_line = resizeLines[this.side]
        this.resize_line.activate(this.width, this)

    }

    SideBar.prototype.resize = function(newWidth) {
        // console.log('resize', this, newWidth)
        if (newWidth!==undefined) {
            this.width = newWidth;
            this.closeButton.style.display = this.width<30 ? 'none' : '';
        }
        document.getElementById('content').style['margin-'+this.side] =  this.active ? newWidth+'px' : ''
        // console.log(document.getElementById('content').style['margin-'+this.side])
        this.sideDiv.style.width = this.width+'px'
        window.dispatchEvent(new Event('resize'));
    }

    SideBar.prototype.close = function() {
        this.active = false;
        if (this.onClose) this.onClose();
        this.sideDiv.style.display = 'none'
        this.resize()
        this.resize_line && this.resize_line.deactivate()
        this.resize_line = null;
    }
    SideBar.prototype.init = function(content) {
        this.sideDiv = createElement('div',content,
            {class:'sideBar '+this.side,id:this.id,style:`${this.side}: 0px;
                                        border-${this.other_side}: 1px solid #000;display:none;
                                        padding-${this.other_side}: 5px;width:${this.width}px;`})
        this.closeButton = createElement('div','&#10006;',
            {class:"close-button clickable",style:`${this.side}:${this.side=='left' ? 5 : 10}px;` })
        this.sideDiv.appendChild(this.closeButton)
        this.closeButton.onclick = this.close.bind(this)
        doWhenDOMLoaded(()=>{document.body.appendChild(this.sideDiv); this.onCreated && this.onCreated()})

    }
    module.exports = SideBar;
})
