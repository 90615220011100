define(function(require, exports, module) {
// Singleton for warping speed
var TimeShifter = function() {
    var t = performance.now();
    this.currTime = t;
    this.lastTime = t;
    this.warp = 1;
}
TimeShifter.prototype.now = function now(t1) {
    var t = t1===undefined ? performance.now() : t1;
    this.currTime += (t - this.lastTime)*this.warp;
    this.lastTime = t;
    return this.currTime;
}
TimeShifter.prototype.setWarp = function setWarp(w) {
    this.now(); // ensure smooth transition between speeds
    this.warp = w;
}
TimeShifter.prototype.fastForward = function fastForward(t) {
    this.currTime += t;
    this.lastTime = t;
}

var timeShifter = new TimeShifter();
module.exports = timeShifter;
})
