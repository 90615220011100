define(function(require, exports, module) {
const TRACKIE = require('../TRACKIE.js');
const Action = require('./Action.js')

var timeShifter = require('../TimeShifter.js');

module.exports = new Action({
    name:"loop",type:"button",
    title: "Start recording a loop. Press again to end the loop.",
    priority:100,
    color: '#ee3f01', colorHover:'#fb8874',
action:function(value, doubleTap, t) {
    	// console.log("loopAction", value, doubleTap, t)
        loopController.loopButton(t);
    }
})
})
