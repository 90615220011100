define(function(require, exports, module) {
var createSVG = require('src/utils/createSVG.js')
const createStore = require('src/utils/createStore.js')

var MIDI_VELOCICHANGER = {
    active: false,
    slope_bottom:createStore(0),
    slope_top:createStore(127),
    toggle: function() {
        this.active = $('#velocity-curve-box').style.display == 'none';
        $('#velocity-curve-box').style.display = !this.active ? 'none' : '';
        if (this.active) {
            this.set_velocity_map()
            console.log(this.slope_bottom.getState())
            this.pendingNotes = [];
            (Array.from(document.querySelectorAll('.notelines')) || []).forEach(n=>{$('#velocity-curve-svg').removeChild(n)})
            this.interval = setInterval(this.intervalUpdate.bind(this),50)
        } else {
            if (this.interval!==undefined) clearInterval(this.interval)
            this.interval = undefined
        }
    },
    velocity_map: new Uint8Array(128).fill(0),
    set_velocity_map: function() {
        this.velocity_map = this.velocity_map.map((_, idx)=>{
            return Math.max(
                Math.min(
                    (idx*this.slope_top.getState()+(127-idx)*this.slope_bottom.getState())/127,
                    127),
                1)|0}) // shouldn't be less than 1 since 0 = note off
    },
    velocichange: function(vel) {
        return this.velocity_map[vel |0]
    },
    // updating stuff here:
    pendingNotes: [],
    intervalUpdate: function() {
        var n = this.pendingNotes.pop()
        while(n) {
            var on = n[0], note = n[1], vel = n[2]
            if (n[0]) {
                // note on
                var new_vel = this.velocichange(vel);
                console.log(vel, new_vel)
                var line1 = createSVG('line', undefined, {class:'notelines',id:'note'+note, x1:vel, x2:vel,y1:127, y2:127-new_vel})
                // var line2 = createSVG('line', undefined, {class:'notelines',id:'note'+note, x1:vel, x2:127,y1:127-new_vel, y2:127-new_vel})
                $('#velocity-curve-svg').appendChild(line1)
                // $('#velocity-curve-svg').appendChild(line2)
            } else {
                while($('#note'+note)) $('#velocity-curve-svg').removeChild($('#note'+note))
            }
            n = this.pendingNotes.pop()
        }
    }
}
MIDI_VELOCICHANGER.set_velocity_map()
function onSlopeChange() {
    // console.log('bottom',MIDI_VELOCICHANGER.slope_bottom.getState())
    MIDI_VELOCICHANGER.set_velocity_map()
    $('#velocity-line').setAttribute('y1', 127 - MIDI_VELOCICHANGER.slope_bottom.getState())
    $('#velocity-line').setAttribute('y2', 127 - MIDI_VELOCICHANGER.slope_top.getState())
}
$("#velocity-curve-range-left").link(MIDI_VELOCICHANGER.slope_bottom)
$("#velocity-curve-range-right").link(MIDI_VELOCICHANGER.slope_top)
$("#velocity-curve-range-left").onchange();
$("#velocity-curve-range-right").onchange();
MIDI_VELOCICHANGER.slope_bottom.subscribe(onSlopeChange)
MIDI_VELOCICHANGER.slope_top.subscribe(onSlopeChange)
onSlopeChange()
module.exports = MIDI_VELOCICHANGER;
})
