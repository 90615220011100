"use strict"
define(function(require, exports, module) {
	const actionsController = require('src/actionsController.js')
	const actionSerialized = require('src/actionSerialized.js')
	const recorder = undefined;//require('src/recorder.js')
	const createElement = require('src/utils/createElement.js')
	const highlight = require('src/utils/highlight.js')
	const doWhenDOMLoaded = require('src/utils/doWhenDOMLoaded.js')
	class Action {
		constructor(props) {
			this._action = props.action;
			props.actionUp && (this._actionUp = props.actionUp);
			for (var key in props) {
				if (key!='action' && key!='actionUp') this[key] = props[key];
			}

			if (this.name && actionSerialized[this.name]) {
				this.saveableAction = actionSerialized[this.name]
			}

			// Add this.div to DOM, defaulting to #actions if this.group and this.container are undefined
			doWhenDOMLoaded(()=>{
				// Setup trigger listeners, and add DOM elements (main is this.div).
				actionsController.createActionButton(this);
				this.addToDom()
				// this.createTriggerOverlay();
			})
		}
		actionUp(_,__,timeStamp) {
			if (this._actionUp) {
				this._actionUp(...arguments)
				if (this.btn) highlight('white',this.btn);
				if (this.saveableAction)
					recorder && recorder.record(this.saveableAction.setActionValue(0), timeStamp)
			}
		}
		// 1st arg: value
		// 2nd arg: double tap (0, for normal press, 1 for doubleTap ect.)
		action(val, doubleTap, t) {
			if (this.type && this.type.startsWith('slider')) {
				this.sliderSetValue(val)
			}
			if (this._temp_action) {
				// console.log("TEMPD", this,...arguments)
				this._temp_action(...arguments);
			}
			else if (this._action) {
				this._action(...arguments);
				if (doubleTap===undefined || doubleTap===null) doubleTap = 0;
				if (t===undefined || t===null) t = performance.now();
				if (this.saveableAction) {
					// console.log(this.type, this.type.startsWith('slider'), val)
					if (this.type && this.type.startsWith('slider')) {
						recorder && recorder.record(this.saveableAction.setActionValue(val), t)
					}
					else
						recorder && recorder.record(this.saveableAction.setActionValue(doubleTap+1), t)

				}
			}
			if (this.btn) highlight('#a3c4d9', this.btn);
		}
		addToDom() {
			if (this.div==undefined) return;
			var container = $('#actions')
			if (this.group!==undefined) {
			    if ($('#groupedActions'+this.group)==null) {
			        $('#groupedActions').appendChild(createElement('div',undefined,{id:'groupedActions'+this.group}))
			    }
			    container = $('#groupedActions'+this.group)
			}
			// console.log(this.name, this.container, container)
			if (this.container!==undefined) container = this.container

			if (this.priority!==undefined) {
				if (options.advancedControlsList.includes(this.name)) this.priority -= 100;

			    this.div.setAttribute('priority',this.priority)
			    for(var i=0; i<container.children.length; i++) {
			        var other_priority = container.children[i].getAttribute('priority')==null ? -Infinity : parseInt(container.children[i].getAttribute('priority'))
			        // console.log(this.priority, other_priority)
			        if (this.priority>other_priority) {
			            container.insertBefore(this.div,container.children[i])
			            return;
			        }
			    }
			    container.appendChild(this.div);
			}
			else {
				if (!this.container) {0 && console.log(this.name, 'no container')}
				else container.appendChild(this.div);
			}
		}
		changeText(newText) {
			if (this.type && this.type.startsWith('slider')) {
				this.text.textContent = newText
			} else if (this.btn) {
			// if (this.type==='button') {
				this.btn.innerHTML = newText
			}
		}
		set name(name) {
			// console.log('set',name)
			this._name = name;
			this.changeText(name)
		}
		get name() {
			// console.log('get',this._name)
			return this._name
		}
		toggleHidden(newValue) {
			this.hidden = (newValue===undefined ? !this.hidden : !!newValue)
			this.div.style.display = this.hidden  ? 'none' : '';
			if (!this.hidden) this.rerender()
		}
		rerender(){
			// triggers re-do overlay if in mapping mode (since need DOMrect dimentions)
			window.dispatchEvent(new CustomEvent('ActionRerender',{detail:this}))
		}
		sliderSetValue(val) {
			// console.log('SSValue',this.name,val, this.value, this.btn.value, this.max)
			if (val==undefined) {
			    val = parseInt(this.btn.value) // if triggered by mouse get from actual html element
				// console.log(val)
			} else {
				val = val*this.max/127
			}
			this.value = val;
			this.btn.value = val;
			// console.log('sliderSetValue',this.value)
			this.update && this.update()
		}
	}
	module.exports = Action;
})
