define(function(require, exports, module) {
	// left side actions for a single loop
	const Action = require('src/actions/Action.js');
	const undoManager = require('src/undoManager.js');

	module.exports = function generateLoopActions(loop_id,loop_channel,loop_volumeMod,container) {
		// console.log(loop_id,loopController.completedLoops, loopController.activeLoop, loopController.getById(loop_id))
		return [
			// new Action({
	  //           name:"chan:"+loop_channel,
	  //           loopId:loop_id,
		 //        container:container,
	  //           type:"button"
	  //       }),
			new Action({
				name:"delete-"+loop_id,
				loopId:loop_id,
		        container:container,
				type:"button",action:()=>{
				    console.log("delete action",loop_id)
				    undoManager.addToQueue([loop_id])
				    loopController.removeLoop(loop_id);
		        }
			}),
			new Action({
			        name:"mute-"+loop_id,
			        loopId:loop_id,
			        container:container,
			        type:"button",action:()=>{
			            var loop = loopController.getById(loop_id)
			            if (!loop) return console.error('mute button couldnt find its loop',loop_id)
			            loop.muted = !loop.muted;
			        }
				}),
			new Action({
	            name:"chanvolume-"+loop_id,type:"slider",min:0,max:200, startValue:(loop_volumeMod || 0.5) * 100,
	            loopId:loop_id,
		        container:container,
	            action:function(value){
	            	var loop = loopController.getById(loop_id)
	            	// console.log('loopVol', this.btn.value, loop.volumeMod)
	            	// left side actions for a single loop
		            if (!loop) return console.error('mute button couldnt find its loop',loop_id)
	                loop.volumeMod = value/100;
	            },update: function() {
	                var loop = loopController.getById(loop_id);
	                loop && (loop.volumeMod = this.btn.value/100)
	            }, startAction: function() {
	            	var loop = loopController.getById(loop_id)
		            if (!loop) return //console.error('mute button couldnt find its loop',loop_id)
	                this.btn.value = loop.volumeMod * 100;
	                // this.update();
	            },
	            hideText:true
	        })
		]

	}

	// https://stackoverflow.com/questions/8825144/detect-double-tap-on-ipad-or-iphone-screen-using-javascript
	// since double tap is only used here, we'll leave this hack 
	let mylatesttouchstart = -Infinity; 
	let mylatestclick = -Infinity;
	let mylatestelement = null;
	function onTouchDetectDoubleTap(e) {
	    if (e.target && e.target.classList && e.target.classList.contains('loopViewCanvas')) {
	    	let now = performance.now();
			var timesince = now - mylatestclick;
// 	    	console.log('click',timesince, mylatestclick)

			mylatestclick = now;
			if (e.target!==null && mylatestelement !== e.target) {
// 				console.log(mylatestelement,"!==",e.target)
				mylatestelement = e.target;
				return;
				// mylatestclick = -Infinity;
			}
			mylatestelement = e.target;
			
			if((timesince < 600) && (timesince > 0)){
		        e.preventDefault() // shouldn't need to prevent zoom action since zoom is disable via <meta> tag
// 				console.log('touch double tap', timesince)
		        muteLoop(e.target)
		        e.preventDefault();
		        mylatestclick = -Infinity; 
			}
		}
	}
	// document.body.addEventListener('touchstart', onTouchDetectDoubleTap)
	document.body.addEventListener('click', onTouchDetectDoubleTap)

	function muteLoop(target) {
		var loop_id = parseInt(target.getAttribute('loop_id'))
		if (!isNaN(loop_id)) {
		    var mute_action = actionsController.byName("mute-"+loop_id)
		    if (mute_action) mute_action.action();
		    else console.error('no mute action for loop', loop_id);
		}
	}
})
