define(function(require, exports, module) {
const Action = require('src/actions/Action.js')

module.exports = new Action({
    name:"redo",type:"button",
    title: "Opposite of undo button, restores last deleted loop.",
	priority:98.5,
    action:function(value, doubleTap) {
		loopController.redoButton();
    }
})
})
