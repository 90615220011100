define(function(require, exports, module) {
	document.querySelector('#jsWentWrong').style.display = 'none'
	require('src/globals.js')
	require('src/utils/requestMediaAccess_shim.js')
	require('src/Note.js')
	// require('lib/LZString.js')
	require('src/looper.js')
	require('src/dragDropHandler.js')

})
