define(function(require, exports, module) {
var timeShifter = require('../TimeShifter.js');
var options = require('../options.js');
const Action = require('./Action.js')
module.exports = new Action({
    name:"pause",type:"button",
    title: "Pause/Play",
    priority:69,
    action:function() {
        if (timeShifter.warp!=0) {
            timeShifter.oldwarp_ = timeShifter.warp;
            timeShifter.warp = 0;
        } else {
            timeShifter.warp = timeShifter.oldwarp_
        }
        this.update()
    },
    update:function() {
        this.btn.innerHTML = timeShifter.warp!==0 ? 'pause' : 'play'
    },
    startAction: function(){this.update()}
})

;(function(){
    var wasPaused = false;
    document.addEventListener("visibilitychange", function() {
      if (document.visibilityState=="visible",options.pauseWhenHidden, timeShifter.warp, wasPaused) {
          if (options.pauseWhenHidden && timeShifter.warp==0 && wasPaused) {
            module.exports.action()
            wasPaused = false;
          }
      } else if (document.visibilityState=="hidden") {
        if (options.pauseWhenHidden && timeShifter.warp!=0) {
            module.exports.action()
            wasPaused = true;
        }
      }
    });
})()

})
