define(function(require, exports, module) {
var SideBar = require('src/SideBar.js')
var MIDI_INTERCEPTOR = require('src/MIDI_INTERCEPTOR.js')
const createElement = require('src/utils/createElement.js');
const mapping = require('src/mapping.js');
var actionsController = require('src/actionsController.js');
const Action = require('src/actions/Action.js')

module.exports = new Action({
    name:"map",type:"button",
    title: "Edit midi-mappings.",
    priority:98,
    action:function(note) {
        console.log("map action",this.mappableWaiting)
        if (!this.mappableWaiting) {
            this.startWaiting();
        } else {
            this.SIDE_BAR.close()
            this.stopWaiting()
        }
    },
    mappableWaiting:false,
    waitingFor:null, // the action we are waiting for a midi message to remap
    startWaiting: function() {
        this.mappableWaiting = true;
        MIDI_INTERCEPTOR.iWantNextNote = this;

        this.SIDE_BAR.open()
        this.updateSideBar()
        // NEW
        actionsController.actions.forEach(a=>{
            if (a === this || a.notMappable) return console.log('a === this', this)
            this.addOverlay(a);
        })

        console.log("LISTENING")
        window.addEventListener("ActionRerender", this.onActionRerender.bind(this))
        document.addEventListener("keydown", this.onKeyDown, false)
        // console.log('mappableWaiting', console.log(this),this.mappableWaiting, MIDI_INTERCEPTOR.iWantNextNote);
    },
    onActionRerender: function(e){
        // so when hide/unhide actions they will still have overlay
        console.log("ActionHidden");
        if (this.mappableWaiting!==true) return;
        this.addOverlay(e.detail)
    },
    stopWaiting:function stopWaiting() {
        // console.error("stopWaiting")
        MIDI_INTERCEPTOR.iWantNextNote = null;
        this.mappableWaiting = false;
        this.waitingFor = null;
        // this.SIDE_BAR.close()
        actionsController.actions.forEach(a=>{
            if (a.overlay) {
                // console.log("removing", a.overlay, "from",a.overlay.parentElement)
                a.overlay.parentElement.removeChild(a.overlay);
                a.overlay = undefined;
            }
        })
        window.removeEventListener("ActionRerender", this.onActionRerender)
        document.removeEventListener("keydown", this.onKeyDown)
        document.querySelectorAll('.currentlyMapping').forEach(o=>{
            o && o.classList.remove('currentlyMapping');
        });
        document.querySelectorAll('.mappingOverlay').forEach(o=>{
            o && o.classList.remove('mappingOverlay');
        });
    },
    stealNote: function(on, note){
        if (this.waitingFor===null) return false;
        console.log(on, note)
        if (on==false && note==0) return false;
        console.log("STEAL: on=",on,"note=",note, "watinginfor=", this.waitingFor)
        console.log(actionsController.takenNotes[note])
        var action_being_displaced = actionsController.changeTrigger(this.waitingFor, note);

        // need to redo overlays on the two actions
        this.addOverlay(this.waitingFor)
        console.log("redo this overlay", action_being_displaced)
        if (action_being_displaced) {
            this.addOverlay(action_being_displaced)
        }

        MIDI_INTERCEPTOR.iWantNextNote = this;
        this.waitingFor = null;

        document.querySelectorAll('.mappingOverlay').forEach(o=>{
            o && o.classList.remove('currentlyMapping');
        });
        this.updateSideBar()
        // this.stopWaiting();
        return false;
    },
    startAction: function() {
        this.SIDE_BAR = new SideBar('left', `
        <div id="MIDI-mapping-container">
            <h4>Mappings:</h4>
            <table id="MIDI-mapping-table"></table>
            <div style="bottom:10px;width:calc(100% - 15px);position:fixed;">
                <button class="btn" style="width:75px;margin-left:10px" id="mapping-save-button">save mapping</button>
                <button class="btn" style="width:75px;margin-left:10px; display:none;" id="mapping-delete-button">delete</button>
            </div>
        </div>`,'MIDI-mapping-bar');

        this.SIDE_BAR.onClose = this.onCloseSideBar.bind(this);
    },
    onCloseSideBar: function() {
        this.stopWaiting();
    },
    updateSideBar: function() {

        var tableContent = '<tbody>';
        tableContent += "<tr><th>" + "action" + "</th><th>" + "trigger" + "</th></tr>"
        actionsController.actions
            .filter(a=>a.trigger!==undefined)
            .sort((a,b)=>a.trigger-b.trigger)
            .forEach(a=>{
                tableContent += "<tr class='mappingRow' id='mapRow"+a.trigger+"'><td>" + a.name + "</td><td>" + idToName(a.trigger) + "</td></tr>"
            })
        tableContent += '</tbody>'

        // tableContent += '<button class="btn" id="mapping-done-button">done</button>'
        // if (0 && "multi-mapping") {
        //     tableContent += '<select id="mapping-select-button">'
        //     Object.keys(mapping.saved).forEach(mapName=>{
        //         tableContent += `<option>${mapName}</option>`
        //     })
        //     tableContent += '</select>'
        // }
        $('#MIDI-mapping-table').innerHTML = tableContent;
        // $('#mapping-done-button').onclick = function() {console.log(this, "stopWaiting");this.stopWaiting()}.bind(this)
        $('#mapping-save-button').onclick = function() {
            actionsController.actions
                .filter(a=>a.trigger!==undefined)
                .forEach(a=>{
                    mapping.map[a.name] = a.trigger
                })
            mapping.save()
        }
        // console.log(actionsController.actions)
        actionsController.actions
            .filter(a=>a.trigger!==undefined)
            .forEach(a=>{
                // console.log(a.name,a.trigger)
                a.mapRow = $('#mapRow'+a.trigger)
                if(a.mapRow) a.mapRow.onclick = ()=>{
                    console.log(this.waitingFor)
                    if (this.waitingFor!==a)
                        this.selectAction(a)
                    else
                        this.deselectAction()
                }
            })
    },
    deselectAction: function() {
        console.log('deselectAction')
        this.waitingFor = null;

        // make this actions overlay highlighted (and not others)
        document.querySelectorAll('.currentlyMapping').forEach(o=>{
            o && o.classList.remove('currentlyMapping');
        });
        $('#mapping-delete-button').style.display = 'none'
    },

    selectAction: function(a) {
        // when click on action (in sidebar list or on main UI)
        this.waitingFor = a;

        // make this actions overlay highlighted (and not others)
        document.querySelectorAll('.currentlyMapping').forEach(o=>{
            o && o.classList.remove('currentlyMapping');
        });

        a.overlay && a.overlay.classList.add('currentlyMapping');
        a.mapRow && a.mapRow.classList.add('currentlyMapping');
        console.log("onclick",a,a.overlay ? a.overlay : "no overlay")

        // delete button becomes active
        $('#mapping-delete-button').style.display = ''
        $('#mapping-delete-button').onclick = this.onPressMappingDelete.bind(this)
    },
    onPressMappingDelete: function() {
        if (!this.waitingFor) return;
        console.log("delete mapping for", this.waitingFor)
        actionsController.changeTrigger(this.waitingFor, undefined);
        delete mapping.map[this.waitingFor.name]
        $('#mapping-delete-button').style.display = 'none'
        this.addOverlay(this.waitingFor)
        this.waitingFor = null;
        this.updateSideBar()
    },
    addOverlay:function(a) {
        // add or re-render overlay
        if (a.isRealAction===false) return; // fuck dealing with this
        if (a.name==='shift') console.log("SHIFT", a)

        if (a.div) {
            if (a.overlay) {
                // need to remove existing overlay
                a.overlay.parentElement.removeChild(a.overlay);
                a.overlay = undefined;
            }

            var size = a.mainElement.getBoundingClientRect();
            // var overlay = createElement('div',undefined,{class:'mappingOverlay',style:";margin-left:"+(0 % -size.width/2)+"px; margin-top:"+(-size.height)+"px;height:"+size.height+"px;width:"+size.width+"px;"});
            // a.mainElement.style.backgroundColor = 'red'
            // a.mainElement.style.borderColor = 'blue'
            // a.mainElement.style.paddingColor = 'green'
            a.mainElement.classList.add('mappingOverlay')
            a.mainElement.onclick_prev = a.mainElement.onclick
            a.mainElement.onclick = (e) => {e.preventDefault(); e.stopImmediatePropagation(); this.selectAction(a)}

            var newOverlay = createElement('div',undefined,{class:'clickable newOverlay',style:'margin-top:'+(-size.height)+"px;height:"+size.height+"px;width:"+size.width+"px;", action:a.name})
            if (a.btn===undefined || a.btn===null) console.error("button no el",a.name, a)
            if (a.btn.classList.contains('small-btn')) {
                newOverlay.style.marginTop = (-size.height-5)+'px'
                newOverlay.style.marginLeft = a.btn.style.marginLeft;
                // newOverlay.style.marginLeft = 2.5+'px'
                // newOverlay.style.marginRight = 2.5+'px'
                console.log(size)
            }
            newOverlay.style.position = a.mainElement.style.position;
            a.midiNumElement.appendChild(newOverlay)

            a.overlay = newOverlay;

            a.overlay.onclick = (e) => {e.preventDefault(); e.stopImmediatePropagation(); this.selectAction(a)}

            if (a.trigger!==undefined) {
                var trig_text = idToName(a.trigger);
                
                var trig = createElement('div',trig_text,{class:'midiMapNum'})



                if (a.midiNumElement) {

                    a.overlay.appendChild(trig)
                }
                a.overlay.onclick = () => {this.selectAction(a)}
            }
            if (a.type.startsWith('slider')) {
                console.log(a)
            }
        }
    },

    onKeyDown: function(e){
        // console.log("keydown",e.keyCode, this.mappableWaiting)
        if (e.keyCode==8) {
            e.preventDefault() // prevent native browser event
            e.stopPropagation() // prevent event passing to other keyboard event
            if (e.repeat!==true) {
                return false;
            }
        }
    }
})
function idToName(id) {
    if (id<0) {
        // id+=1;
        var chan = (-(id)>>7)
        console.log("chan, id",chan, id)
        id += chan*128
        console.log(id)
        return "CC"+((-id)&127)+":"+chan
    }

    else return id
}   
})
