define(function(require, exports, module) {
    const highlight = require('src/utils/highlight.js')
    const doWhenDOMLoaded = require('src/utils/doWhenDOMLoaded.js')
    doWhenDOMLoaded(function() {
        $('#save-output-name').onclick = saveOutputName
        function saveOutputName() {
            // changed so in&output both saved with one button
            var midi_in_name = $('#midi-in-select').selectedOptions[0].innerHTML
            localStorage.setItem('save-input-name', midi_in_name);
            var midi_out_name = $('#midi-out-select').selectedOptions[0].innerHTML
            localStorage.setItem('save-output-name', midi_out_name);

            highlight('#fb8874',$('#midi-in-select'))
            highlight('#fb8874',$('#midi-out-select'))
        }
    })
})
