"use strict";
define(function(require, exports, module) {
    var to_export = {
     // "CC0":Note.create(176,0,0),
     // "CC1":Note.create(176,1,0),
     // "CC2":Note.create(176,2,0),
     // "CC4":Note.create(176,4,0),
     // "CC5":Note.create(176,5,0),
     // "CC7":Note.create(176,7,0),
     // "CC8":Note.create(176,8,0),
     // "CC10":Note.create(176,10,0),
     // "CC12":Note.create(176,12,0),
     // "CC13":Note.create(176,13,0),
     // "CC73":Note.create(176,73,0),
     // "CC84":Note.create(176,84,0),
     // "CC91":Note.create(176,91,0),
     // "CC93":Note.create(176,93,0),
     // "CC72":Note.create(176,72,0),
     "Vol0":Note.createAction(116,1),
     "Vol1":Note.createAction(117,1),
     "Vol2":Note.createAction(118,1),
     "Vol3":Note.createAction(119,1),
     "Vol4":Note.createAction(120,1),
     "Vol5":Note.createAction(121,1),
     "Vol6":Note.createAction(122,1),
     "delayedLoop":Note.createAction(2),
     "loop":Note.createAction(1),
     "prevInstrument":Note.createAction(3),
     "nextInstrument":Note.createAction(4),
     "octaveDown":Note.createAction(5),
     "octaveUp":Note.createAction(6),
     "pause":Note.createAction(7),
     "lowest note":null,
     "setup":null,
     "speed":Note.createAction(10,1),
     "sync":Note.createAction(11),
     "velocity":null,
     "pitch":Note.createAction(13),
     "undo":Note.createAction(14),
     "volume":Note.createAction(15,1),
     "installToHomeScreen":null,
     "redo":Note.createAction(17),
     "refresh":null,
     "track0":Note.createAction(100),
     "track1":Note.createAction(101),
     "track2":Note.createAction(102),
     "track3":Note.createAction(103),
     "track4":Note.createAction(104),
     "track5":Note.createAction(105),
     "track6":Note.createAction(106),
     "...":null,
     "console":null,
     "CCinfo":null,
     "debug":null,
     "options":null,
     "channels":null,
     "map":null,
     "nextSection":Note.createAction(20),
     "prevSection":Note.createAction(19),
     "shift":Note.createAction(18)
    }
    for(var k in to_export) {
     var v = to_export[k];
     if (v===undefined || v===null) continue;
     to_export[v] = k;
    }
    module.exports = to_export
})
