define(function(require, exports, module) {
	const Action = require('./Action.js')
	const outputState = require('src/outputState.js')

	module.exports = new Action({
	    name:'nextInstrument', type:"button",
	    container:$('#instrument-select-container'),
	    title: "Select next instrument",
	    btn:$('#nextInstrument'),
	    action: function() {
	    	outputState.shiftCurrentInstrument(1);
	    }
	})
})
