define(function(require, exports, module) {
var options = require('src/options.js')
var outputState = require('src/outputState.js')
var createStore = require('src/utils/createStore.js')

function do_nothing() {console.error("MIDI_OUT not setup yet",arguments.values)}

var MIDI_OUT = function() {
    this.noteOn = do_nothing;
    this.noteOff = do_nothing;
    this.send = do_nothing;
    this.changeInstrument = do_nothing;
    this.status = createStore("");
    this.name = createStore("");
    this.close = do_nothing;
    this.bank_change_LSB = null;
    this.bank_change_MSB = null;

}

// internal
// open a WebMIDI port
MIDI_OUT.prototype.openMidiOut = function openMidiOut(midi_output) {
    console.log("openMidiOut", midi_output.name)
    this.noteOn = function(channel, note, velocity) {
        // console.log("noteOn",note, velocity, channel)
        if (note<0 || note>127) return console.error("note out of range",note);
        if (channel===undefined) channel = 0;
        midi_output.send([144 + (channel & 0x0F), note, velocity]);
        document.dispatchEvent(new CustomEvent('drawNote',{detail:[note,channel,true]}))
    }
    this.noteOff = function(channel, note, velocity) {
        // console.log("noteOff",note, channel)
        if (note<0 || note>127) return;
        if (channel===undefined) channel = 0;
        midi_output.send([128 + (channel & 0x0F), note, 0]);

        document.dispatchEvent(new CustomEvent('drawNote',{detail:[note,channel,false]}))
    }

    // this.send = midi_output.send.bind(midi_output);
    this.send = function(){
        0 && console.log("send",...arguments);
        if (Array.isArray(arguments[0])) {
            let type = arguments[0][0] & 0xF0;
            if (type===144) return this.noteOn(arguments[0][0] & 0x0F, arguments[0][1], arguments[0][2])
            if (type===128) return this.noteOff(arguments[0][0] & 0x0F, arguments[0][1], arguments[0][2])
        }
        try {
            midi_output.send.bind(midi_output)(...arguments);
        } catch(error) {
            console.log("error sending:",...arguments)
            throw(error)
        }
    };
    midi_output.send([0xB0, 0x7B, 0x00]); // reset all note states
    // midi_output.send([0xB0, 0x7A, 0x00]); // local control off?
    for(var i=0; i<=15; i++) {midi_output.send([0xB0+i,0x7A,0x00])}
    this.changeInstrument = function(channel, instrument) {
        if (instrument !== 254)
            midi_output.send([0xC0 +(channel & 0x0F),instrument & 0xFF, 0xFF])
    }
    this.close = function() {
        console.log("closing ",midi_output);
        // must wrap with Promise resolve since Jazz-Midi doesn't return a Promise as per spec
        Promise.resolve(midi_output.close()).then(()=>{
            console.log("MIDI_OUTPUT closed this port:",midi_output)
        })
    }
}

// public
MIDI_OUT.prototype.openOutputByName = function openOutput(output_name) {
    var outputs = MIDI_OUTPUTS.values();
    var output_id = null;
    for (var output = outputs.next(); output && !output.done; output = outputs.next()) {
        console.log(output.value.name, output_name)
        if (output.value.name===output_name) {
            output_id = output.value.id;
            break;
        }
    }
    if (output_id!==null) this.openOutput(output_id)
    else console.error('no output found with name:',output_name)
}

MIDI_OUT.prototype.openOutput = function openOutput(output_id) {
    if (this.send!==do_nothing) {
        for(var i=0; i<16; i++) this.send([0xA0 + i, 0x7B, 0x00]) // all notes off
    }
    // console.log(this.close)
    if (this.close!==do_nothing) this.close();
    // this.close = function() {};
    // console.log('CONNECTION')
    this.status.setState('connecting');


    // OPEN SELECTED OPTION
    var outputs = window.MIDI_OUTPUTS.values();
    // console.log("output number",outputs, outputs.length)
    var chosen_output = null;
    // loop over all available outputs and disable any output
    for (var output = outputs.next(); output && !output.done; output = outputs.next()) {
        // console.log("output id", output.value.id)
        if (output.value.id==output_id) {
            chosen_output = output.value;
        } else {
            if (options.disableOtherOutputs) {
                output.value.open().then(midi_output=>{
                    // console.log("midi_output opened for closing",midi_output)
                    midi_output.send([0xB0, 0x7A, 0x00]) // Local control off
                    midi_output.send([0xB0, 0x7B, 0x00]) // all notes off
                    midi_output.close();
                })
            }
        }
    };

    if (chosen_output === null) console.error("output",output_id, "doesn't exist")
    else {
        chosen_output.open().then((midi_output)=>{

            if (midi_output.connection == "open"){
                this.status.setState('connected')
                this.openMidiOut(midi_output)

                // this.setupMidiOutChannels(midi_output)
                // instrument stores should be linked to send MIDI-change messsages
                outputState.channelInstruments.forEach(chan=>chan.publish())

                this.name.setState(midi_output.name, true)
            } else {
                console.error('opened MIDI connection is not open')
                this.status.setState('error')
            }
        })
    }

}


var OUT = new MIDI_OUT();
module.exports = OUT;
})
