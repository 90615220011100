define(function(require, exports, module) {
    const iterToArray = require('src/utils/iterToArray.js');
    const createElement = require('src/utils/createElement.js')
    const doWhenDOMLoaded = require('src/utils/doWhenDOMLoaded.js')
    const createStore = require('src/utils/createStore.js')

    var MIDI_IN = {
        onmidimessage:function() {},
        status: createStore(""),
        name: createStore("")
        // onmidicode:function() {}
    }
    var current_input = null;
    MIDI_IN.openInput = function openInput(option) {
        console.log("MIDI_IN.openInput", option)
        if (!option) {console.log("no midi inputs"); return}
        // console.log("openInput",option, option.innerHTML)


        var input_id = option;

        MIDI_IN.status.setState('connecting')

        var chosen_input = null;

        // loop over all available inputs and listen for any MIDI input
        chosen_input = iterToArray(MIDI_INPUTS.values()).find(input=>input.value.id==input_id)
        // console.log('chosen_input:',chosen_input)
        if (!chosen_input) return;
        chosen_input = chosen_input.value
        // console.log("chosen_input",chosen_input)

        if (chosen_input === null || chosen_input === undefined) console.error("input",input_id, "doesn't exist")
        else {
            if (current_input && options.closeOldInputs) current_input.close().then((res)=>{console.log("old input closed:",res)})
            current_input = chosen_input
            // console.log('opening...', chosen_input)
            chosen_input
                .open()
                .then((midi_input)=>{
                    // console.log("opened....",midi_input)
                    // console.log("input opened, response:",midi_input)
                    if (midi_input.connection == "open"){
                        midi_input.onmidimessage = MIDI_IN.onmidimessage.bind(midi_input);
                        // midi_input.onmidicode = MIDI_IN.onmidicode.bind(midi_input);
                        MIDI_IN.status.setState('connected')
                        MIDI_IN.name.setState(midi_input.name, true)
                        // console.log("input connected:",res.onmidimessage)
                    }
                })
        }
    }

    // doWhenDOMLoaded(function setupMidiInSelector() {
    //     $('#midi-in-select').onchange = function() {openInput(this.getValue(),$('#midi-in-select'))}
    // })
    module.exports = MIDI_IN;
})
