define(function(require, exports, module) {
const TRACKIE = require('src/TRACKIE.js');
// const KEYCHANGER = require('src/KEYCHANGER.js');
const Action = require('src/actions/Action.js')
const actionsController = require('src/actionsController.js');
const ModAction = require('src/actions/ModAction.js')
const sectionActions = require('src/actions/sectionActions.js')

// ok so this is all to generate new actions for when the shift button is held down.. verbose AF but works
var shift1 = {
    octaveUp:{name:'shiftUp',action:function(){TRACKIE.octaveUpButton()}},
    octaveDown:{name:'shiftDown',action:function(){TRACKIE.octaveDownButton()}},
    loop:{name:'delayedLoop',action:function(){
            if (!TRACKIE.loopButton(...arguments)) {
                actionsController.byName('delayedLoop').action()
            }
        }
    },
    undo:{name:'redo',action:function(){
            if (!TRACKIE.undoButton(...arguments)) {
                actionsController.byName('redo').action()
            }
        }
    }
}



if (0) {
    var shift1 = {
        redo:{name:'octaveUp',action:function(){loopController.changeOctave(+12)}},//TRACKIE.octaveUpButton()}},
        undo:{name:'octaveDown',action:function(){loopController.changeOctave(-12)}},//TRACKIE.octaveDownButton()}},
        loop:{name:'doubleLoop',action:function(){
                var isLooping = loopController.activeLoop!==null;

                // if no loops and we haven't started looping:
                if (!isLooping) {
                    loopController.loopButton();
                    return
                }
                // if was already looping do a double loop
                loopController.loopButton();
                loopController.loopButton();
            }
        },
        delayedLoop:{name:'doubleDelayedLoop',action:function(){
                var isLooping = loopController.activeLoop!==null;

                // if no loops and we haven't started looping:
                if (!isLooping) {
                    loopController.loopButton(undefined, true) // <- delayedLoop action
                    return
                }
                // if was already looping do a double loop
                loopController.loopButton();
                loopController.loopButton(undefined, true) // <- delayedLoop action
            }
        }
    }
}
shift1 =  new ModAction(shift1)
var outputState = require('src/outputState.js')


var trackMuteActions = {}
const messageBar = require('src/messageBar.js');

outputState.trackChannels.forEach((_,track_idx)=>{
    // let track_idx = track_idx;

    trackMuteActions["track"+(track_idx+1)] = {
        action:function(){
            // if any track not muted then we should mute all, else unmute
            var shouldMute = loopController.completedLoops.some(l=>l.track==track_idx && l.muted!==true && l.hidden!==true);
            var tracksToModify = loopController.completedLoops.filter(l=>l.track==track_idx && l.hidden!==true)
            if (tracksToModify.length>0) {
                tracksToModify.forEach(l=>l.muted=shouldMute)
                messageBar.setMessage(`track-${track_idx} ${shouldMute ? 'muted' : 'un-muted'}`, {track:track_idx})
            } else {
                messageBar.setMessage(`track-${track_idx} empty`, {track:track_idx})
            }
        }
        // , actionUp:function(){
        //     outputState.trackHeldDown[track].setState(false)
        // }
    }
})
trackMuteActions =  new ModAction(trackMuteActions)

var trackSelectActions = {}

outputState.trackChannels.forEach((_,track_idx)=>{
    trackSelectActions["track"+(track_idx+1)] = {
        action:function(){
            outputState.trackHeldDown[track_idx].setState(true)
        }, actionUp:function(){
            outputState.trackHeldDown[track_idx].setState(false)
        }
    }
})
trackSelectActions =  new ModAction(trackSelectActions)

    // octaveUp:{name:'instUp',action:function(){actionsController.byName('nextInstrument').action();}},
    // octaveDown:{name:'instDown',action:function(){actionsController.byName('prevInstrument').action();}},

var trackieControlActions = new ModAction({
    redo:{name:'selectUp', action:function() {TRACKIE.octaveUpButton();}},
    undo:{name:'selectDown', action:function() {TRACKIE.octaveDownButton();}},
    loop:{name:'loop',action:function(){
            // will return false if no action happened (because no channels held down)
            TRACKIE.loopButton(...arguments);
            // var shiftaction = TRACKIE.loopButton();
            // if (!shiftaction) {
            //     var isLooping = loopController.activeLoop!==null;
            //     // if was already looping do a double loop
            //     this._action();
            //     if (isLooping) {
            //         this._action();
            //     }
            // }
        }
    },
    delayedLoop:{name:undefined,action:function(){
            // if no new action let's just do a regular undo
            TRACKIE.undoButton(...arguments)
            // if (!TRACKIE.undoButton()) {
            //     this._action()
            // }
        }
    }
})



var trackToggleActions = new ModAction({});
outputState.trackChannels.forEach((_,track_idx)=>{
    trackToggleActions.props['track'+(track_idx+1)] = {
        name:'toggle'+(track_idx+1),
        action: function() {
            // console.log('toggleaction', this, track_idx)
            this.active = this.active===true ? false : true;
            // actionsController.byName('trackToggle'+track_idx).action()
            if (this.active) {
                this.mutedTracks = loopController.completedLoops.filter(l=>l.track==track_idx && l.muted!==true && l.hidden!==true);
                this.mutedTracks.forEach(l=>l.muted=true)
            } else {
                if (this.mutedTracks && this.mutedTracks.length>0) {
                    this.mutedTracks.forEach(l=>l.muted=false)
                }
            }
        }
    }
})
trackToggleActions.onDeactivate = function() {Object.values(trackToggleActions.props).forEach(tt=>tt.active = false)}


let pernamentlyShifted = false;
module.exports = new Action({
    name:"shift", type:"button",
    title:"shifts other buttons to do different things, double tap for bonus",
    priority: 50,
    modActions:{},
    action: function(value, doubleTap) {
        console.log("shift", doubleTap)
        if (doubleTap===0) {
            // TRACKIE.activate();
            trackSelectActions.activate()
            TRACKIE.activate();
            shift1.activate()
        } else if (doubleTap>=1) {
            if (options.muteTracksWhileLooping) trackMuteActions.activate()
            // sectionActions.activate()
            trackieControlActions.activate()
            trackSelectActions.activate()
        } else if (doubleTap>=2) {
            pernamentlyShifted = true;
        //     future
        //     KEYCHANGER.activate()
        //     console.log(trackToggleActions)
        }

    }, actionUp: function() {
        trackMuteActions.deactivate()
        trackSelectActions.deactivate()
        TRACKIE.deactivate();
        // KEYCHANGER.deactivate()
        shift1.deactivate();
        trackieControlActions.deactivate();
        sectionActions.deactivate();
    }
})

})
