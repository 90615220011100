
"use strict";
define(function(require){

    // require('src/actions/sectionActions.js')
    require('src/actions/CCactions.js')
    require('src/actions/channelActions.js')

    // require('src/actions/CCinfo.js')
    // require('src/actions/console.js')
    require('src/actions/debugSideBar.js')
    require('src/actions/delayedLoop.js')
    require('src/actions/loop.js')
    require('src/actions/prevInstrument.js')
    require('src/actions/nextInstrument.js')
    require('src/actions/octaveDown.js')
    require('src/actions/octaveUp.js')
    require('src/actions/optionSideBar.js')
    require('src/actions/pause.js')
    require('src/actions/setLowestNote.js')
    require('src/actions/setup.js')
    require('src/actions/help.js')
    require('src/actions/speed.js')
    require('src/actions/shift.js')
    require('src/actions/sync.js')
    require('src/actions/undo.js')
    // require('src/actions/velocity.js')
    require('src/actions/volume.js')
    require('src/actions/pitch.js')
    // require('src/actions/channelSideBar.js')
    require('src/actions/installToHomeScreen.js')
    // require('src/actions/donateButton.js')
    require('src/actions/redo.js')
    require('src/actions/refreshButton.js')
    // require('src/actions/reset.js')
    // require('src/actions/record.js')
    // require('src/actions/record_clear.js')
    require('src/actions/map.js')
    require('src/actions/dotdotdot.js')
    // require('src/actions/error.js') // for testing
    require('src/actions/trackActions.js')
    require('src/actions/fixLength.js')
    // require('src/actions/echo.js') // buggy - doesn't fire?
    // unfinished
    // require('src/actions/strum.js')
    // require('src/actions/sliderspeed.js')
})
