define(function(require, exports, module) {
var y_top = 0;
var all_loopViews = [];
const cols = require('src/utils/cols.js')
const createElement = require('src/utils/createElement.js')
const generateLoopActions = require('src/actions/generateLoopActions.js')
const timeShifter = require('src/TimeShifter.js')
const options = require('src/options.js')
const doWhenDOMLoaded = require('src/utils/doWhenDOMLoaded.js')

var canvas_width = 200;
var height = 90;
var LoopView = function(loop) {
    this.id = loop.id;
    this.channel = loop.channel;
    this.y_top = y_top; y_top+= 1;
    this.loop = loop; // TODO: eliminate for MVC!
    this.recordingFade = 1;
    this.createLoopView();
    this.visible = isElementInViewport(this.ctx.canvas)
    all_loopViews.push(this);
}

function createCanvas(id){
    var canvas = createElement('canvas',undefined, {class:'loopViewCanvas',id:'loopViewCanvas'+id,height:height,loop_id:id})
    document.getElementById('sideDiv').appendChild(canvas)
    canvas.width = canvas_width;
    // window.addEventListener('viewport-resize', function(e){canvas.width = canvas_width;}, true);
    var ctx = canvas.getContext('2d');
    ctx.font = "10px Arial";
    return ctx;
}

// thx: https://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport/7557433#7557433
function isElementInViewport(el) {
    var rect = el.getBoundingClientRect();
    return (
        rect.top + rect.height >= 0 &&
        rect.bottom - rect.height <= (window.innerHeight || document.documentElement.clientHeight)
    );
}

const mute_color = '#909090'
const delete_color = '#ff2828'
function initializeCanvas() {
    canvas_width = document.getElementById('content').clientWidth-120;
    window.addEventListener('viewport-resize', function(e){canvas_width = document.getElementById('content').clientWidth-120;document.querySelectorAll('.loopViewCanvas').forEach(c=>c.width = canvas_width)}, true);

    function noteDraw(ctx,note, start_pos,end_pos,y,yScale, xScale, y_start) {
        // if (note[1]>1) return;
        var posX = start_pos*xScale
        var posX2 = end_pos*xScale
        ctx.fillRect(posX,y-(note-y_start)*yScale,posX2 - posX,1)
        // d && console.error(posX,posX2 - posX,posX2)
    }

    // var ts_last = 0;
    function canvasDraw() {
        if (options.disableDraw==true) {
            requestAnimationFrame(canvasDraw)
            return
        }
        var t = window.performance.now();
        var ts = timeShifter.now(t)
        // console.log(ts-ts_last, "\t", t)
        // ts_last = ts
        // // TODO_DONE: ts goes back in TIME??
        var max_len = 0;
        var visible = new Array(all_loopViews.length).fill(false);

        // var max_len = all_loopViews.every(l=>l.loop.

        
        all_loopViews.forEach((loopView, idx)=>{
            var loop = loopView.loop
            loop_length = loop.length()
            if (!loop.finished) {
                var syncedEnd = loopController.getSyncTime(ts, loop)-loop.startTime;
                // if (syncedEnd !== undefined && !isNaN(syncedEnd)) loop_length = (syncedEnd-loop.startTime)
                // console.log(loop.id, loop_length, syncedEnd)
                if (syncedEnd !== undefined && !isNaN(syncedEnd)) {
                    max_len = Math.max(max_len, syncedEnd)
                } else {
                    max_len = Math.max(max_len, loop_length)
                }
            } else {

                max_len = Math.max(max_len,loop_length)
            }
            // console.log('syncedEnd', syncedEnd, loop_length)
            visible[idx] = isElementInViewport(loopView.ctx.canvas)
        })
        var loopdraw_index = 0;
        var loopView = null;
        // initialiized here for perf
        var loop, loop_length, xScale, yScale, yBorder, y_start, y, x, ctx;
        function drawNext() {
            // console.log("drawNext")
            while (true) {
                if (loopdraw_index>all_loopViews.length-1) {
                    requestAnimationFrame(canvasDraw)
                    return;
                }
                loopView = all_loopViews[loopdraw_index];

                if (!visible[loopdraw_index]) {
                    loopdraw_index += 1;
                } else {
                    break;
                }
            }
            ctx = loopView.ctx;
            ctx.fillStyle = '#333'
            ctx.fillRect(0,0,ctx.canvas.width,ctx.canvas.height)

            loop = loopView.loop

            if (loop.waitForNote) {
                ctx.fillStyle = cols.channel[loop.track % cols.channel.length]
                ctx.globalAlpha = (0.6 + .4 * Math.sin(t /100))
                ctx.fillText("WAITING FOR NOTE",10,10);
                loopdraw_index += 1;
                // console.log(loopdraw_index)
                drawNext();
                return;
            }


            xScale = canvas_width/max_len;
            yBorder = 0.1; // fraction of y for boundary
            yScale = (1-yBorder)*height/Math.max(1,loop.notemax-loop.notemin);
            y_start = loop.notemin;
            y = (1-yBorder*0.5)*height;

            loop_length = loop.length();

            ctx.globalAlpha = 0.3
            ctx.fillStyle = 'white'
            if (!loop.finished) {
                var loop_sync_length = loopController.getSyncTime(ts, loop)-loop.startTime
                if (loop_sync_length > max_len) {
                    // if it's running ahead it should be synced time
                    loop_length = loop_sync_length;
                } else {
                    // if shorter than current longest loop
                }
                // console.log(loop_sync_length*xScale,y,1,-height)
                // ctx.fillRect(loop_sync_length*xScale,y,1,-height)
                if (0 && loop_length > loop_sync_length) {
                    ctx.fillRect(loop_length*xScale,1,canvas_width,height)

                    ctx.globalAlpha = 0.1
                    ctx.fillRect(loop_sync_length*xScale,1,(loop_length-loop_sync_length)*xScale,height)
                } else {
                    ctx.fillRect(loop_sync_length*xScale,1,canvas_width,height)
                }
            } else {
                if (loop_length<max_len) {
                    ctx.fillRect(loop_length*xScale,1,canvas_width,height)
                }
            }


            ctx.globalAlpha = 1
            ctx.fillStyle = cols.channel[loop.track % cols.channel.length]

            if (loop.pendingchange!==0) {
                ctx.globalAlpha = 0.5 + (loop.pendingchange*0.4) + 0.1 * Math.sin(t /100)
                if (loop.pendingchange==2) {
                    ctx.fillStyle = delete_color;
                } else {
                    ctx.fillStyle = mute_color;
                }
                ctx.fillRect(0,1,canvas_width-1,height-2)
                ctx.globalAlpha = 1
            }
            else if (loop.muted) {
                // console.log(0,y+1,canvas_width-1,height-1)
                ctx.fillStyle = mute_color;
                ctx.fillRect(0,1,canvas_width-1,height-2)
            }

            // ctx.rect(10,y+1,canvas_width-1,height-1)
            if (loop.finished)
                x = loop.currPos(ts)*xScale;
            else
                x = loop.currPos(ts)*xScale -1;
            if (loopView.recordingFade>0) {
                ctx.globalAlpha = (0.6 + .4 * Math.sin(t /100)) * loopView.recordingFade;
                ctx.fillText("RECORDING",10,10);
                if (loop.finished) loopView.recordingFade = (loopView.recordingFade * 0.99) - 0.06
            }
            ctx.globalAlpha = 1
            ctx.fillStyle = cols.channel[loop.track % cols.channel.length]
            ctx.fillRect(x,y,1,-height) // current position tracker line
            // note[0] time relative to start of loop
            // note[1] on/off=true/false , 2=non_note
            // note[2] note number e.g. 48 == C4
            // note[3] prev_note id OR velocity if noteOn
            loop.notes.forEach(n=>{
                var time=n[0], note=n[1],prev_id=n[2];
                // if (note[1]!==2) { // TODO: could draw non-notes
                if (note.isNoteOn()) { // TODO: could draw non-notes
                    if (prev_id !== undefined) {// if we know noteOff position use that
                        var note_end_x = loop.notes[prev_id][0];
                        if (note_end_x>loop_length) {
                            // if fully over border then move to start
                            if (note[0]>loop_length) {
                                noteDraw(ctx,prev_id,time-loop_length,note_end_x-loop_length, y, yScale, xScale, y_start)
                            } else {
                                // notes crossing loop border need to be drawn ending and starting again
                                // end line:
                                // d && console.log(note[2],note[0],loop_length, note_end_x)
                                // d && console.log(d, note)
                                noteDraw(ctx,note.data1(),time,loop_length, y, yScale, xScale, y_start)
                                // start line:
                                noteDraw(ctx,note.data1(),0,note_end_x-loop_length, y, yScale, xScale, y_start)
                                // if (d>0) {d -= 1};
                            }
                        } else {
                            // normal case most notes are in middle
                            noteDraw(ctx,note.data1(),time,note_end_x, y, yScale, xScale, y_start)
                        }
                    } else {
                        if (note.isNoteOnOrOff() && loop.finished)
                            noteDraw(ctx,note.data1(),time,loop_length, y, yScale, xScale, y_start)
                    }
                }
            })
            if (!loop.finished) {
                for (var key in loop.notes_on) {
                    if (loop.notes_on[key]!==undefined) {
                        let note = loop.notes[loop.notes_on[key]]
                        var posX = note[0]*xScale
                        var posY = height-note[1].data1();
                        // ctx.fillRect(posX,y+posY,10000000,1)
                        // console.log(note,[note[0]+1000])
                        if (note[1].isNoteOn() || note[1].isNoteOff()) 
                            noteDraw(ctx,note[1].data1(),note[0],loop_length, y, yScale, xScale, y_start)
                    }
                }
            }

            loopdraw_index += 1;
            drawNext();
        }
        drawNext()
    }
    requestAnimationFrame(canvasDraw)

    // function FRAME() {console.log('FRAME');requestAnimationFrame(FRAME)}
    // requestAnimationFrame(FRAME)

    LoopView.prototype.createLoopViewHTML = function createLoopViewHTML() {
        // canvas.height += height;
        var div = document.createElement("div");
        div.classList.add('trackControls')
        div.id  = 'loopView'+this.id
        this.div = div;
        // div.classList.add(cols.classNames[this.loop.channel])
        // div.style.backgroundColor = cols.channel[this.loop.channel % cols.channel.length]
        // div.className = 'channel-'+(this.loop.channel % cols.channel.length)+' shade-4 bg'
        // div.style.borderColor = cols.border[this.loop.channel % cols.border.length]
        cols.styleElement(div,this.loop.track,4,'bg');
        cols.styleElement(div,this.loop.track,6,'border');

        document.getElementById('sideDiv').appendChild(div)

        // console.log(this,this.loop)
        // var div_top = document.createElement("div");
        // div_top.textContent = "Loop chan:"+this.loop.channel
        // div_top.className = "btn"
        // div_top.style = "border:solid 1px white; font-size: 13px;height:14px;"
        // this.div.appendChild(div_top)
      
        // return div;
    }

    LoopView.prototype.removeLoopViewHTML = function removeLoopViewHTML() {
        document.getElementById('loopView'+this.id).remove()
        document.getElementById('loopViewCanvas'+this.id).remove()
    }


}

LoopView.prototype.createLoopView = function createLoopView(){
    this.ctx = createCanvas(this.id);
    this.createLoopViewHTML()
    var _this = this;
    var newActions = generateLoopActions(_this.loop.id,_this.loop.channel,_this.loop.volumeMod, this.div)
    this.actions = newActions;
}
LoopView.prototype.delete = function() {
    console.log('deleting LoopView', this)
    all_loopViews = all_loopViews.filter(i => i!==this);
    this.removeLoopViewHTML();
    this.actions.forEach((a)=>{
        if (a.btn) {
            a.btn.remove();
            a.btn = null; // dereference?
            actionsController.removeAction(a)
        }
    })
    y_top -= 1;

}
LoopView.prototype.updateChannel = function() {
    // var loopView// = this.actions.find(a => a.name)
    // document.querySelector('#loopView'+this.id);
    if (this.div) {
        // this.div.style.backgroundColor = cols.channel[this.loop.channel % cols.channel.length]
        cols.styleElement(this.div,this.track.channel,4,'bg');
        cols.styleElement(this.div,this.track.channel,6,'border');
        // this.div.style.border = "2px solid "//+cols.border[this.loop.channel % cols.channel.length]
        if (this.div.children[0] && this.div.children[0].innerHTML.startsWith('chan:')) {
            console.log("chan: ",this.div.children[0].innerHTML)
            this.div.children[0].innerHTML = "chan:"+this.loop.channel;
        } else {
            console.log("no chan: LoopView")
        }
    }
}

doWhenDOMLoaded(initializeCanvas)
module.exports = LoopView;
})
