define(function(require, exports, module) {
    const createStore = require('src/utils/createStore.js')
    var options = require('src/options.js');
    const GMnames = require('src/GMnames.js')


    // list of GM instruments + Default which will send no program change messages
    var instrumentChoices =  []
    instrumentChoices.push([-1,'Default instrument']);
    Object.keys(GMnames.byId).forEach(id=>{
        // console.log(id, GMnames.byId[id].instrument)
        instrumentChoices.push([parseInt(id),id + ' - ' +GMnames.byId[id].instrument])
    })


    const s = {
        instrumentChoices: instrumentChoices,
        channelInstruments: new Array(16).fill(-1).map((value,idx)=>createStore(value)),
        trackChannels: (new Array(options.numTracks).fill(0)).map((value,idx)=>createStore(idx)), // set in 'saveTrackList.js'
        currentTrack: createStore(0),
        currentChannel: createStore(0), // read only
        trackHeldDown:options.channelsUsed.map((c,idx)=>createStore(false)),
        anyTrackHeldDown:createStore(false)
    }
    s.trackHeldDown.forEach(t=>t.subscribe(()=>{
        s.anyTrackHeldDown.setState(s.trackHeldDown.some(t=>t.getState()===true))
        // console.error(s.trackHeldDown.map(x=>x.getState()))
    }),true)
    // GET VALUES FROM ORIGINAL OPTIONS
    // options.channelsUsed.forEach((chan,idx)=>{
        // s.channelInstruments[chan].setState( options.channelInstruments[idx] )
        // console.log(chan,idx,options.channelInstruments[idx])
    // })

    // function to keep track of which is the current selected channel
    function updateCurrentChannel(){
        s.currentChannel.setState(s.trackChannels[s.currentTrack.getState()].getState())
    }

    s.trackChannels.forEach(tc=>tc.subscribe(updateCurrentChannel))
    s.currentTrack.subscribe(updateCurrentChannel, true)



    s.trackInstrumentStore = function(track) {
        return s.channelInstruments[s.trackChannels[track].getState()]
    }
    s.getCurrentChannelStore = function() {
        return s.trackChannels[s.currentTrack.getState()]
    }
    s.getCurrentChannel = function() {
        return s.trackChannels[s.currentTrack.getState()].getState()
    }
    s.getCurrentInstrumentStore = function() {
        var currChan = s.currentChannel.getState();
        return s.channelInstruments[currChan]
    }
    s.shiftCurrentInstrument = function(amount) {
        var currInstrument = s.getCurrentInstrumentStore();
        var targetInstrument = currInstrument.getState()+amount;

        s.setCurrentInstrument(targetInstrument)
    }
    s.setCurrentInstrument = function(value) {
        var currInstrument = s.getCurrentInstrumentStore();

        if (s.instrumentChoices.map(i=>i[0]).includes(value)) {
            currInstrument.setState(value)
        }
    }

    module.exports = s;
})
