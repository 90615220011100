define(function(require, exports, module) {
var timeShifter = require('src/TimeShifter.js');

var queue = [];
function addToQueue(loop_ids){
	if (!Array.isArray(loop_ids)) loop_ids = [loop_ids];
	loop_ids.length && console.log("ADDING TO QUEUE",loop_ids);
	loop_ids.forEach(loop_id=>{
		// console.log("ADDING TO QUEUE",loop_id)
		var loop = loopController.getById(loop_id)
		if (!loop) {console.error('loop not found!'); return;}
		queue.push(loop)
	})
}
function popFromQueue(){
	if (queue.length===0) return null;
	var loop = queue.pop();
	return loop;
}
function loopButtonPressed() {
	// clear all loops
	// queue.forEach(loop=>loopController.removeLoop(loop.id))
	queue = [];
}

module.exports = {addToQueue:addToQueue, popFromQueue:popFromQueue,loopButtonPressed:loopButtonPressed}

})
