define(function(require, exports, module) {
;(function() {
    // done: simply conver mousedown/up into touchstart/end
    // make sure to cancel event and replicate changedtouches
    const mod = require('src/mod.js')
    const mouseToTouch = require('src/utils/mouseToTouch.js')
    const cols = require('src/utils/cols.js')
    const white_keys = require('src/keys.js').white_keys;
    const black_keys = require('src/keys.js').black_keys;
    const doWhenDOMLoaded = require('src/utils/doWhenDOMLoaded.js')
    var kcanvas, ctx, elementAboveCanvas, elementContainingCanvas;
    var w = 15;
    var h = 70;
    var start_x = 2, y=2;
    var n_keys = 34;
    var dpr = window.devicePixelRatio || 1;
    var drawQueue = [];

    var black_width = w/2-2;
    var black_height = h*0.65-2;

    doWhenDOMLoaded(initDrawKeys);
    function initButtons() {
        var createElement = require('src/utils/createElement.js')
        var settings_on = true;
        $('#keyboard-settings').onclick = () => {
            settings_on = !settings_on;
            [heightSlider, widthSlider].map(s=>s.div.style.display = settings_on ? '':'none')
            // start_x += 260 * (settings_on ? 1 : -1);
            drawKeys(n_keys)
        }
        // kcanvas.parentElement.appendChild(createElement('button','sticky on', {class:'btn',style:'position:absolute;margin-left:5px;margin-top:70px;'}))
        const Action = require('src/actions/Action.js')
        // let octaveButtons = ['up','down'].map(dir=>new Action({
        //             name:"key-height",type:"slider",
        //             title:"Change width of each piano key",
        //             min:0,max:200, startValue:100,
        //             container:$('#keyboardContainer'),
        //             action:function(){
        //                 h = this.value|0;
        //                 black_height = h*0.65-2
        //                 kcanvas.height = Math.max(50, h)
        //                 drawKeys(n_keys)
        //             },update:function() {
        //                 this.text.textContent = "key-height:"+(this.value).toFixed(2);
        //             }
        //         })
        //     )
        let heightSlider = new Action({
            name:"key-height",type:"slider",
            title:"Change width of each piano key",
            min:0,max:200, startValue:100,
            container:$('#keyboardContainer'),
            action:function(){
                h = this.value|0;
                black_height = h*0.65-2
                onResize()
                drawKeys(n_keys)
            },update:function() {
                this.text.textContent = "key-height: "+(this.value).toFixed(0);
            }
        })
        heightSlider.div.style = 'bottom:35px; left:5px;position:absolute;'

        let widthSlider = new Action({
            name:"key-width",type:"slider",
            title:"Change width of each piano key",
            min:0,max:200, startValue:100,
            container:$('#keyboardContainer'),
            action:function(){
                w = this.value|0;
                black_width = w/2-2;
                onResize()
            },update:function() {
                this.text.textContent = "key-width: "+(this.value).toFixed(0);
            }
        })
        widthSlider.div.style = 'bottom:5px; left:5px;position:absolute;'

        $('#keyboard-settings').onclick()
        
    }

    function initDrawKeys() {
        kcanvas = document.getElementById('keyboardCanvas');
        ctx = kcanvas.getContext('2d')
        elementAboveCanvas = document.getElementById('sideDiv');
        elementContainingCanvas = document.getElementById('keyboardContainer');

        initButtons();
        mouseToTouch(kcanvas);

        window.addEventListener('viewport-resize', onResize)
        kcanvas.addEventListener('touchstart',onTouchStart, {passive:false})
        kcanvas.addEventListener('touchend',releaseNote)
        document.addEventListener('touchmove',onTouchMove, {passive:true})//onCanvasTouchMove)

        onResize()
    }
    var ongoingTouches = [];
    // will have {startPos, note}
    function releaseNote(evt) {
        var isTouchingCanvas = false;
        var touches = evt.changedTouches;
        for (var i = 0; i < touches.length; i++) {
            var touch = touches[i];
            if (touch.identifier in ongoingTouches) {
                isTouchingCanvas = true;
                var note = ongoingTouches[touch.identifier].note;
                if (note>=0 && note<=127) sendMidiMessage([128, note, 0])
                delete ongoingTouches[touch.identifier]
            }
        }
        if (isTouchingCanvas) {
            if (!evt.cancelable===false) evt.preventDefault();
            updateTouchPitchBend();
            return false;
        }
    }
    function onTouchMove(evt) {
        var isTouchingCanvas = false;
        var touches = evt.changedTouches;
        for (var i = 0; i < touches.length; i++) {
            var touch = touches[i];
            if (touch.identifier in ongoingTouches) {
                isTouchingCanvas = true;
                ongoingTouches[touch.identifier].currPos = [touch.clientX, touch.clientY];
            }
        }
        if (isTouchingCanvas) {
            updateTouchPitchBend();
            // console.log('ongoingTouches',ongoingTouches);
        }
    }
    function updateTouchPitchBend() {
        var avgX = 0;
        var maxY = -1000;
        var numTouches = Object.keys(ongoingTouches).length;
        // console.log("numTouches", numTouches)
        if (numTouches===0) {
            maxY = 0;
            avgX = 0;
        } else {
            // console.log('itering',ongoingTouches)
            // ongoingTouches.forEach(t=>console.log(t))
            ongoingTouches.forEach((touch,id)=>{

                var startPos = touch.startPos;

                var x = touch.currPos[0] - startPos[0];
                var y = touch.currPos[1] - startPos[1];
                avgX += x;
                maxY = Math.max(y, maxY)
                // console.log("touch", x,y,avgX,maxY)
            })
            avgX /= numTouches;
        }
        if (Math.abs(avgX)>10) {
            var up = avgX>0;
            avgX = Math.min(127, Math.max(0, Math.abs(avgX)-10));
            var pitchAmt = 8192 + ((up ? 1 : -1) *avgX*8191/127)
            sendMidiMessage([0xE0, pitchAmt & 0x7F, (pitchAmt>>7) & 0x7F])
        }
        if (maxY>=0) {
            var programValue = 11
            var modAmt = Math.min(127,Math.max(0,127-maxY))
        } else {
            programValue = 1;
            var modAmt = Math.min(127,Math.max(0,Math.abs(maxY)))
        }
        sendMidiMessage([176, programValue, modAmt])
    }
    function onTouchStart(evt) {
        var target = evt.target;
        if (!evt.cancelable===false) evt.preventDefault()
        var dim = target.getBoundingClientRect();

         var touches = evt.changedTouches;
         for (var i = 0; i < touches.length; i++) {
            var touch = touches[i];

            var x = touch.clientX - dim.left;
            var y = touch.clientY - dim.top;
            var note = xyToNote(x,y)
            ongoingTouches[touch.identifier] = {
                startPos: [touch.clientX, touch.clientY],
                note: note,
                currPos: [touch.clientX, touch.clientY]
            }
            // RESET PITCH
            var pitchAmt = 8000;
            sendMidiMessage([0xE0, pitchAmt & 0x7F, (pitchAmt>>7) & 0x7F])
            // RESET OTHER
            sendMidiMessage([176, 1, 0])
            sendMidiMessage([176, 11, 127])
            // NOTE ON
            if (note>=0 && note<=127) sendMidiMessage([144, note, 100])

        }
    }
    function xyToNote(x,y) {
        x -= start_x;
        var could_be_black = y < black_height;
        var white_number = Math.floor(x/w);
        var could_be_left_black = could_be_black && (x-white_number*w)<w*0.35;
        var could_be_right_black = could_be_black && (x-white_number*w)>w*(1-0.35);
        var octave = Math.floor(white_number/7)
        white_number = white_number - octave*7;
        black_keys.forEach(k=>{
            // console.log(k, white_number)
            if (k<=white_number) white_number++;
        })
        var note = octave*12 + white_number;
        if (could_be_right_black && black_keys.indexOf(mod(note+1,12))!==-1) note++;
        if (could_be_left_black && black_keys.indexOf(mod(note-1,12))!==-1) note--;

        note = note+12*1;
        return note;
    }
    function onResize() {
        var documentWidth =  document.getElementById('content').clientWidth;
        dpr = window.devicePixelRatio || 1;

        console.log('resize', documentWidth, window.devicePixelRatio)
        n_keys = ((documentWidth-4)/w)|0
        n_keys = Math.min(68,n_keys) // MIDI notes can't be higher than 128;

        // update width and height for high-DPI screens
        var keyboard_width =  n_keys * w+4;
        kcanvas.width = keyboard_width * dpr;
        kcanvas.height = (Math.max(1, h)+4) * dpr

        // scale canvas actual size to occupy same size on page
        kcanvas.style.width = keyboard_width + 'px';
        kcanvas.style.height = (Math.max(1, h)+4) + 'px';
        elementAboveCanvas.style.marginBottom = (Math.max(1, h)+4) + 'px';
        elementAboveCanvas.style.width =  kcanvas.style.width;
        ctx.scale(dpr, dpr)
        // kcanvas.style.marginLeft = (((documentWidth-keyboard_width)/2)|0) + "px"
        // console.log("extra space",documentWidth,keyboard_width,documentWidth-keyboard_width,x)
        drawKeys(n_keys)
        drawNewKeys();
    }
    function drawKeys(n_keys) {

        ctx.fillStyle = "#FFF"
        ctx.fillRect(0,0,kcanvas.width, kcanvas.height)
        ctx.fillStyle = "#333"
        for (var i=0;i<n_keys; i++) {
            ctx.beginPath()
            ctx.moveTo(start_x+i*w, y)
            ctx.lineTo(start_x+i*w+w, y );
            ctx.lineTo(start_x+i*w+w, y+h );
            ctx.lineTo(start_x+i*w, y+h );
            if (i==0) ctx.lineTo(start_x+i*w, y)
            ctx.stroke();
            var n = i % 7;
            if (i!==n_keys-1 && (n==0 || n == 1 || n == 3 || n == 4 || n == 5 )) {
                ctx.fillRect(start_x+i*w+w*0.75, y,w/2,h*0.65)
            }
            
            //  i here is white key num; 
            if (i%7==0) {
                octaveText(Math.floor(i/7))
            }
        }
        
    }

    const black_sides = [[0,1],[1,1],[1,0],[0,1],[1,1],[1,1],[1,0]]; // [0,1] = black key on right side of this white key
    function fillKey(i, col, off) {
        i -= 12;

        var border = 0+!off;
        var n = mod(i+1200,12);
        // console.log(i, chan, "n=",n,"black=",black_keys.indexOf(n), col)
        var is_black = black_keys.includes(n);
        var octave = Math.floor(i/12);
        if (is_black) { // BLACK KEY

            i = 7*octave + (n<4 ? (n+1)/2 : n/2+1) - 1
            ctx.fillStyle = !off ?  "#333" : col //"#33F"
            ctx.fillRect(start_x+i*w+w*0.75-border+1, y+1-border,black_width+border,black_height+border*2)

        } else { // WHITE KEY
            var white_index = white_keys.indexOf(n)
            var sides = black_sides[white_index];
            if (!sides) console.log(i, col, off,"sides:",sides, "white_index",white_index)
            white_index += 7*octave;
            ctx.fillStyle = !off ? "#FFF" : col //"#333"
            ctx.fillRect(start_x+white_index*w+2-border, y+h*0.65+2-border,w-4+border*2,h*0.35-4+border*2)

            // ctx.fillStyle = !off ? "#FFF" : col// "#F00"
            ctx.fillRect(start_x+white_index*w+2+w*0.25*sides[0]-border, y+2-border,w-4-w*0.25*(sides[0]+sides[1])+border*2,h*0.65+border*2)

            if (n==0) {
                octaveText(octave)
            }
        }
    }
    function octaveText(octave) {
        ctx.fillStyle = '#333'
        ctx.font = "9px Arial";
        ctx.fillText("C"+octave, start_x+octave*w*7+2, y+h*0.9)
    }
    function keyText(i,txt) {
        // white_index += 7*octave;
        ctx.fillStyle = '#333'
        ctx.font = "9px Arial";
        ctx.fillText(txt, start_x+i*w+2-1, y+h*0.9) 
    }
    function blackKeyText(n, txt) {
        ctx.fillStyle = '#EEE'
        ctx.font = "9px Arial";
        ctx.fillText(txt, start_x+n*w+2, y+h*0.5)
    }
    // TODO make this ignore repeats on same note
    document.addEventListener('drawNote',e=>{
        var note = e.detail[0],
            channel = e.detail[1],
            on = e.detail[2];
        drawQueue.push([note,cols.keys[mod(channel,cols.keys.length)] //channel
            , on]);
    })

    function drawNewKeys() {
        drawQueue.forEach(args => {fillKey.apply(this, args)})
        drawQueue = [];
        requestAnimationFrame(drawNewKeys);
    }

    function sendMidiMessage(midi) {
        // TODO make sure doesn't update with the same values
        document.dispatchEvent(new CustomEvent('noteClicked',{detail:Note.createFromArray(midi).setThru(true)}));
    }

})()
})
