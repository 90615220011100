define(function(require, exports, module) {
const TRACKIE = require('../TRACKIE.js');
const Action = require('./Action.js')
module.exports = new Action({
    name:"octaveUp",type:"button",
    title: "Increase the octave of the current channel.",
    priority:91,
    action:function() {
        // if (TRACKIE.active) {TRACKIE.octaveUpButton()}
        console.log("octave+",loopController.changeOctave(+12))
    },
})
})
