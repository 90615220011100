define(function(require, exports, module) {
	module.exports  = {
		id: 'tiny-synth',
		name:'tiny-synth',
		type:'output',
		_open: function() {
			return new Promise(function(res,err) {
				// console.log('initilaizing',this)
				this.synth = new WebAudioTinySynth();
				var timerid=setInterval(function(){
				  if(this.synth.isReady){
				    clearInterval(timerid);
				    // console.log("Initialized");
				    res();
				  }
				}.bind(this),200);
			}.bind(this))
		},
		_send: function() {
			if (this.synth && this.synth.isReady) {
				// console.log(this.synth, Array.from(arguments));
				// this.synth.send([144,60,100]);
				this.synth.send(...arguments);
				// console.log('this.synth.send(',...[...arguments],')');
			} else {
				console.log(this.synth,"not ready")
			}
		}
	}
})