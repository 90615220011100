define(function(require, exports, module) {
	const Action = require('./Action.js')
	let installPromptEvent;

	window.addEventListener('beforeinstallprompt', (event) => {
	  // Prevent Chrome <= 67 from automatically showing the prompt
	  event.preventDefault();
	  // Stash the event so it can be triggered later.
	  installPromptEvent = event;
	  // Update the install UI to notify the user app can be installed
	  document.querySelector('#installButtonContainer').style.display = '';
	});

	module.exports = new Action({
	    name:'installToHomeScreen', type:"button",
	    title: "Add app to home screen",
	    btn:$('#installButton'),
	    isRealAction:false,
	    action: function() {
	    	console.log('checking storage')
	    	navigator.webkitTemporaryStorage.queryUsageAndQuota (
	    	    function(usedBytes, grantedBytes) {
	    	        console.log('we are using ', usedBytes, ' of ', grantedBytes, 'bytes');
	    	    },
	    	    function(e) { console.log('Error', e);  }
	    	);

	    	// if (navigator.serviceWorker && navigator.serviceWorker.controller) {
	    	// 	navigator.serviceWorker.controller.postMessage("download-soundfont-please");
	    	// }

		  	if (installPromptEvent) installPromptEvent.prompt();
	    }
	})
});
