define(function(require, exports, module) {
// MIDI LIST

// MIDI CC Number	MIDI CC
// Purpose	MIDI CC
// Description
module.exports = {
0:  	"Bank Select	Allows user to switch bank for patch selection. Program change used with Bank Select. MIDI can access 16,384 patches per MIDI channel.",
1:  	"Modulation	Generally this CC controls a vibrato effect (pitch, loudness, brighness). What is modulated is based on the patch.",
2:  	"Breath Controller	Often times associated with aftertouch messages. It was originally intended for use with a breath MIDI controller in which blowing harder produced higher MIDI control values. It can be used for modulation as well.",
3:  	"Undefined	 ",
4:  	"Foot Controller	Often used with aftertouch messages. It can send a continuous stream of values based on how the pedal is used.",
5:  	"Portamento Time	Controls portamento rate to slide between 2 notes played subsequently.",
6:  	"Data Entry Most Significant Bit(MSB)	Controls Value for NRPN or RPN parameters.",
7:  	"Volume	Control the volume of the channel",
8:  	"Balance	Controls the left and right balance, generally for stereo patches. 0 = hard left, 64 = center, 127 = hard right",
9:  	"Undefined	 ",
10:  	"Pan	Controls the left and right balance, generally for mono patches. 0 = hard left, 64 = center, 127 = hard right",
11:  	"Expression	Expression is a percentage of volume (CC7).",
12:  	"Effect Controller 1	Usually used to control a parameter of an effect within the synth/workstation.",
13:  	"Effect Controller 2	Usually used to control a parameter of an effect within the synth/workstation.",
14:  	"Undefined",
15:  	"Undefined",
16:  	"General Purpose",
17:  	"General Purpose",
18:  	"General Purpose",
19:  	"General Purpose",
20:  	"Undefined",
21:  	"Undefined",
22:  	"Undefined",
23:  	"Undefined",
24:  	"Undefined",
25:  	"Undefined",
26:  	"Undefined",
27:  	"Undefined",
28:  	"Undefined",
29:  	"Undefined",
30:  	"Undefined",
31:  	"Undefined",
32:  	"Controller 0-31 Least Significant Bit (LSB)",
33:  	"Controller 0-31 Least Significant Bit (LSB)",
34:  	"Controller 0-31 Least Significant Bit (LSB)",
35:  	"Controller 0-31 Least Significant Bit (LSB)",
36:  	"Controller 0-31 Least Significant Bit (LSB)",
37:  	"Controller 0-31 Least Significant Bit (LSB)",
38:  	"Controller 0-31 Least Significant Bit (LSB)",
39:  	"Controller 0-31 Least Significant Bit (LSB)",
40:  	"Controller 0-31 Least Significant Bit (LSB)",
41:  	"Controller 0-31 Least Significant Bit (LSB)",
42:  	"Controller 0-31 Least Significant Bit (LSB)",
43:  	"Controller 0-31 Least Significant Bit (LSB)",
44:  	"Controller 0-31 Least Significant Bit (LSB)",
45:  	"Controller 0-31 Least Significant Bit (LSB)",
46:  	"Controller 0-31 Least Significant Bit (LSB)",
47:  	"Controller 0-31 Least Significant Bit (LSB)",
48:  	"Controller 0-31 Least Significant Bit (LSB)",
49:  	"Controller 0-31 Least Significant Bit (LSB)",
50:  	"Controller 0-31 Least Significant Bit (LSB)",
51:  	"Controller 0-31 Least Significant Bit (LSB)",
52:  	"Controller 0-31 Least Significant Bit (LSB)",
53:  	"Controller 0-31 Least Significant Bit (LSB)",
54:  	"Controller 0-31 Least Significant Bit (LSB)",
55:  	"Controller 0-31 Least Significant Bit (LSB)",
56:  	"Controller 0-31 Least Significant Bit (LSB)",
57:  	"Controller 0-31 Least Significant Bit (LSB)",
58:  	"Controller 0-31 Least Significant Bit (LSB)",
59:  	"Controller 0-31 Least Significant Bit (LSB)",
60:  	"Controller 0-31 Least Significant Bit (LSB)",
61:  	"Controller 0-31 Least Significant Bit (LSB)",
62:  	"Controller 0-31 Least Significant Bit (LSB)",
63:  	"Controller 0-31 Least Significant Bit (LSB)",
64:  	"Damper Pedal / Sustain Pedal	On/Off switch that controls sustain. (See also Sostenuto CC 66) 0 to 63 = Off, 64 to 127 = On",
65:  	"Portamento On/Off Switch	On/Off switch 0 to 63 = Off, 64 to 127 = On",
66:  	"Sostenuto On/Off Switch	On/Off switch – Like the Sustain controller (CC 64), However it only holds notes that were “On” when the pedal was pressed. People use it to “hold” chords” and play melodies over the held chord. 0 to 63 = Off, 64 to 127 = On",
67:  	"Soft Pedal On/Off Switch	On/Off switch - Lowers the volume of notes played. 0 to 63 = Off, 64 to 127 = On",
68:  	"Legato FootSwitch	On/Off switch. - Turns Legato effect between 2 subsequent notes On or Off. 0 to 63 = Off, 64 to 127 = On",
69:  	"Hold 2	Another way to “hold notes” (see 64 and 66). However notes fade out according to their release parameter rather than when the pedal is released.",
70:  	"Sound Controller 1	Usually controls the way a sound is produced. Default = Sound Variation.",
71:  	"Sound Controller 2	Allows shaping the Voltage Controlled Filter (VCF). Default = Resonance - also(Timbre or Harmonics)",
72:  	"Sound Controller 3	Controls release time of the Voltage controlled Amplifier (VCA). Default = Release Time.",
73:  	"Sound Controller 4	Controls the “Attack’ of a sound. The attack is the amount of time it takes forthe sound to reach maximum amplitude.",
74:  	"Sound Controller 5	Controls VCFs cutoff frequency of the filter.",
75:  	"Sound Controller 6	Generic – Some manufacturers may use to further shave their sounds.",
76:  	"Sound Controller 7	Generic – Some manufacturers may use to further shave their sounds.",
77:  	"Sound Controller 8	Generic – Some manufacturers may use to further shave their sounds.",
78:  	"Sound Controller 9	Generic – Some manufacturers may use to further shave their sounds.",
79:  	"Sound Controller 10	Generic – Some manufacturers may use to further shave their sounds.",
80:  	"General Purpose Controller	Generic On/Off switch. 0 to 63 = off, 64 to 127 = on",
81:  	"General Purpose Controller	Generic On/Off switch. 0 to 63 = off, 64 to 127 = on",
82:  	"General Purpose Controller	Generic On/Off switch. 0 to 63 = off, 64 to 127 = on",
83:  	"General Purpose Controller	Generic On/Off switch. 0 to 63 = off, 64 to 127 = on",
84:  	"Portamento CC Control	Controls the amount of Portamento.",
85:  	"Undefined",
86:  	"Undefined",
87:  	"Undefined",
88:  	"Undefined",
89:  	"Undefined",
90:  	"Undefined",
91:  	"Effect 1 Depth	Usually controls reverb send amount",
92:  	"Effect 2 Depth	Usually controls tremolo amount",
93:  	"Effect 3 Depth	Usually controls chorus amount",
94:  	"Effect 4 Depth	Usually controls detune amount",
95:  	"Effect 5 Depth	Usually controls phaser amount",
96:  	"(+1) Data Increment	Usually used to increment data for RPN and NRPN messages.",
97:  	"(-1) Data Decrement	Usually used to decrement data for RPN and NRPN messages.",
98:  	"Non-Registered Parameter Number LSB (NRPN)	For controllers 6, 38, 96, and 97, it selects the NRPN parameter.",
99:  	"Non-Registered Parameter Number MSB (NRPN)	For controllers 6, 38, 96, and 97, it selects the NRPN parameter.",
100:  	"Registered Parameter Number LSB (RPN)	For controllers 6, 38, 96, and 97, it selects the RPN parameter.",
101:  	"Registered Parameter Number MSB (RPN)	For controllers 6, 38, 96, and 97, it selects the RPN parameter.",
102:  	"Undefined",
103:  	"Undefined",
104:  	"Undefined",
105:  	"Undefined",
106:  	"Undefined",
107:  	"Undefined",
108:  	"Undefined",
109:  	"Undefined",
110:  	"Undefined",
111:  	"Undefined",
112:  	"Undefined",
113:  	"Undefined",
114:  	"Undefined",
115:  	"Undefined",
116:  	"Undefined",
117:  	"Undefined",
118:  	"Undefined",
119:  	"Undefined",
120:  	"All Sound Off	Mutes all sounding notes. It does so regardless of release time or sustain. (See 123)",
121:  	"Reset All Controllers	It will reset all controllers to their default.",
122:  	"Local On/Off Switch	Turns internal connection of a MIDI keyboard/workstation, etc. On or Off. If you use a computer, you will most likely want local control off to avoid notes being played twice. Once locally and twice whent the note is sent back from the computer to your keyboard.",
123:  	"All Notes Off	Mutes all sounding notes. Release time will still be maintained, and notes held by sustain will not turn off until sustain pedal is depressed.",
124:  	"Omni Mode Off	Sets to “Omni Off” mode.",
125:  	"Omni Mode On	Sets to “Omni On” mode.",
126:  	"Mono Mode	Sets device mode to Monophonic.",
127:  	"Poly Mode	Sets device mode to Polyphonic."
}


var ccShortNames = {
0:"Bank Select",
1:"Modulation Wheel or Lever",
2:"Breath Controller",
3:"3:Undefined",
4:"Foot Controller",
5:"Portamento Time",
6:"Data Entry MSB",
7:"Channel Volume (formerly Main Volume)",
8:"Balance",
9:"9:Undefined",
10:"Pan",
11:"Expression Controller",
12:"Effect Control 1",
13:"Effect Control 2",
14:"14:Undefined",
15:"15:Undefined",
16:"Controller 1",
17:"Controller 2",
18:"Controller 3",
19:"Controller 4",
20:"20:Undefined",
21:"21:Undefined",
22:"22:Undefined",
23:"23:Undefined",
24:"24:Undefined",
25:"25:Undefined",
26:"26:Undefined",
27:"27:Undefined",
28:"28:Undefined",
29:"29:Undefined",
30:"30:Undefined",
31:"31:Undefined",
32:"Bank Select",
33:"Modulation Wheel or Lever",
34:"Breath Controller",
35:"35:Undefined",
36:"Foot Controller",
37:"Portamento Time",
38:"Data Entry",
39:"Channel Volume, formerly Main Volume",
40:"Balance",
41:"41:Undefined",
42:"Pan",
43:"Expression Controller",
44:"Effect control 1",
45:"Effect control 2",
46:"46:Undefined",
47:"47:Undefined",
48:"General Purpose Controller 1",
49:"General Purpose Controller 2",
50:"General Purpose Controller 3",
51:"General Purpose Controller 4",
52:"52:Undefined",
53:"53:Undefined",
54:"54:Undefined",
55:"55:Undefined",
56:"56:Undefined",
57:"57:Undefined",
58:"58:Undefined",
59:"59:Undefined",
60:"60:Undefined",
61:"61:Undefined",
62:"62:Undefined",
63:"63:Undefined",
64:"Damper Pedal on/off (Sustain)",
65:"Portamento On/Off",
66:"Sostenuto On/Off",
67:"Soft Pedal On/Off",
68:"Legato Footswitch",
69:"Hold 2",
70:"Sound Controller 1 (Sound Variation)",
71:"Sound Controller 2 (Timbre/Harmonic Intens.)",
72:"Sound Controller 3 (Release Time)",
73:"Sound Controller 4 (Attack Time)",
74:"Sound Controller 5 (Brightness)",
75:"Sound Controller 6 (Decay Time - see MMA RP-021)",
76:"Sound Controller 7 (Vibrato Rate - see MMA RP-021)",
77:"Sound Controller 8 (Vibrato Depth - see MMA RP-021)",
78:"Sound Controller 9 (Vibrato Delay - see MMA RP-021)",
79:"Sound Controller 10 (undefined - see MMA RP-021)",
80:"Controller 5",
81:"Controller 6",
82:"Controller 7",
83:"Controller 8",
84:"Portamento Control",
85:"85:Undefined",
86:"86:Undefined",
87:"87:Undefined",
88:"High Resolution Velocity Prefix",
89:"89:Undefined",
90:"90:Undefined",
91:"Effects 1 Depth ",
92:"Effects 2 Depth (formerly Tremolo Depth)",
93:"Effects 3 Depth ",
94:"Effects 4 Depth (formerly Celeste [Detune] Depth)",
95:"Effects 5 Depth (formerly Phaser Depth)",
96:"Data Increment (Data Entry +1) (see MMA RP-018)",
97:"Data Decrement (Data Entry -1) (see MMA RP-018)",
98:"NRPN - LSB",
99:"NRPN - MSB",
100:"RPN - LSB*",
101:"RPN - MSB*",
102:"102:Undefined",
103:"103:Undefined",
104:"104:Undefined",
105:"105:Undefined",
106:"106:Undefined",
107:"107:Undefined",
108:"108:Undefined",
109:"109:Undefined",
110:"110:Undefined",
111:"111:Undefined",
112:"112:Undefined",
113:"113:Undefined",
114:"114:Undefined",
115:"115:Undefined",
116:"116:Undefined",
117:"117:Undefined",
118:"118:Undefined",
119:"119:Undefined"
}


})
