"use strict"
define(function(require, exports, module) {

	// pass in a key=actionName value=modified function Object to give actions a new temporary use
	// actionUp.. we'll deal with you later
	// [{actionName: temporary_action},]
	var actionsController = require('src/actionsController.js')
	class ModAction {
		constructor(props) {
			this.props = props;
		}
		activate() {
			for(var key in this.props) {
				var action = actionsController.byName(key)
				if (!action) console.log('ERROR: modaction:', key)
				action._temp_action = this.props[key].action.bind(this.props[key])
				action._temp_name = this.props[key].name
				if (action._temp_name!==undefined) action.changeText(action._temp_name)
				this.props[key]._action = action._action.bind(action)
			}
		}
		deactivate() {
			for(var key in this.props) {
				var action = actionsController.byName(key)
				if (!action) console.log('ERROR: modaction:', key)
				action._temp_action = undefined;
				action._temp_name = undefined
				action.changeText(action.name)
			}
			if (this.onDeactivate) this.onDeactivate()
		}
	}
	module.exports = ModAction;
})
